.popup {
    position: relative;
    width: 472px;
    &--small{
        width: 420px;
    }
    &--extra-small{
        width: 270px;
    }
    border-radius: 3px;
    z-index: 999;
    box-shadow: 0px 4px 12px rgba(51, 64, 89, 0.2);
    background: #ffffff;
    &-title {
        padding-top: 0px;
        padding-left: 38px;
        padding-right: 24px;
        color: $base-dark;
        @extend .heading-h3;
    }
    &-small-title-bottom {
        color: $base-dark;
        @extend .text-base-min;
    }
    &-content {
        padding: 18px 24px;
        border-bottom: 1px solid #EFF0F2;
        .block-price {
            color: $base-dark;
            &_border {
                border-right: 1px solid #EFF0F2;
            }
            .price {
                display: block;
                @extend .heading-h4;
                .sub-price{
                    color: $grey-main;
                }
            }
            .label {
                display: block;
                @extend .text-base-micro;
            }
            &--gray{
                color: $grey-main;
            }
        }
        .block-popup {
            &_border {
                border-right: 1px solid #EFF0F2;
            }
            .text {
                &-block {
                    display: block;
                }
                @extend .text-base-micro;
                &_grey {
                    color: $grey-main;
                }
                &_prime {
                    color: $prime-color;
                }
                &_dark {
                    color: $base-dark;
                }
            }
            .price {
                color: $base-dark;
                @extend .text-base-min;
            }
        }
        .block-info {
            .title {
                color: $base-dark;
                @extend .heading-h3;
                &--grey{
                    color: $grey-main-light;
                }

            }
        }
    }
    &-footer {
        @extend .text-base-micro;
        p {
            margin-bottom: 5px;
        }
        p:last-child {
            margin-bottom: 0px;
        }
    }
    &-l-border{
        border-left: 3px solid;
    }
}
