.filters {
    input:not(.calendar-input__text){
        box-shadow: inset 0px 2px 1px $grey-super-light;
    }
    .block {
        padding-top: rem-local(40);
        padding-bottom: rem-local(40);
        border-top: 1px solid #DAE4EE;

        &_end {
            padding: 55px 0;
        }

        &-info {
            margin-top: 38px;

            &_no-top {
                margin-top: 0;
            }

            &__title {
                color: $base-dark;
                margin-bottom: 10px;
            }

            .cat-text {
                color: $grey-main;
            }
        }
        &:first-child{
            border-top: none !important;
            padding-top: 0;
        }

        .btn {
            &-primary, &-default {
                @extend .text-base-button;
                padding: 12px 24px;
            }
            &-clear {
                @extend .text-base-button;
                text-align: right !important;
                color: $prime-color;
                background: transparent;
                outline: none;
                cursor: pointer;
                .btn-icon {
                    position: inherit !important;
                    margin-right: 0.6rem;
                }
            }
        }
    }

    .checkbox-filter {
        margin-right: 42px;
    }

    .checkbox-list, .radio-list{
        margin-bottom: 1rem;
        &:last-child{
            margin-bottom: 1px;
        }
        .params-text{
            display: block;
        }
    }

    .only{
        margin-bottom: 2rem;
    }

    .cats {
        position: relative;
        cursor: pointer;

        &_small {
            margin-top: 10px;
            /*line-height: 10px;*/
            .errorMessageValidation{
                padding-bottom: 1rem;
            }
        }

        &:before {
            content: '—';
            display: inline-block;
            position: absolute;
            top: -40px;
            right: 50.5%;
            width: 5px;
            height: 5px;
        }
    }

    .cat {
        display: inline-block;

        &-list {
            margin-right: 38px;

            &_space-bottom {
                margin-bottom: 16px;
            }
        }

        &-text {
            @extend .text-base-min;
            color: $prime-color;

            &_small {
                @extend .text-base-micro;
            }
            &_separator{
                margin-right: 16px;
            }
            &_slider{
                color: $prime-color !important;
                //left: 1.7rem;
                position: relative;
            }
        }
    }

    .filter-title {
        @extend .heading-h3;
        color: $base-dark;
        &_margin-top {
            margin-top: 24px;
        }
        &--small{
            @extend .text-base-min;
            color: $base-dark;
            font-weight: bold;
            margin-bottom: rem-local(16);
        }
        &--heading{
            @extend .heading-big-text;
            color: $base-dark;
            margin-bottom: rem-local(24);
        }
    }

    .title-gray{
        .filter-title {
            &--small{
                color: $grey-main;
                font-weight: normal;
                margin-bottom: rem-local(4);
            }
        }
    }

    .expand-module {
        img{
            transform: rotate(270deg);
        }
        &:hover {
            cursor: pointer;
        }
        &-expanded {
            margin-bottom: rem-local(32);
            img {
                transform: rotate(0deg);
            }
        }
    }

    .okp-block {
        padding-right: 40px;
        padding-left: 40px;

        border-right: 1px solid #DAE4EE;

        &:first-child{
            padding-left: 0;
        }
        &:last-child {
            border-right: 0;
        }

        .okp-title {
            margin-right: 10px;
            display: inline-block;
        }
    }

    .loc-block {
        position: relative;
        &__title {
            color: $base-dark;
        }

        &__separator {
            position: absolute;
            top: 0;
            left: -32px;
            height: 24px;
            width: 1px;
            background-color: #DAE4EE;
        }
        margin-left: 80px;
        margin-right: 120px;
        &:first-child {
            margin-left: 0px;
            margin-right: 0px;
        }
    }

    .more-params__title {
        margin-bottom: 22px;
    }
    .listOrg{
        padding: 0;
        ul {
            margin: 0;
        }
    }
    .list{
        padding: 0;
        margin: 0;
        ul {
            margin: 0;
        }
    }
    .hint{
        color: inherit !important;
    }
    .errorMessageValidation{
        margin: 0 !important;
        padding-top: 0 !important;
        @extend .text-base-min;
    }

    .title-text-min{
        @extend .text-base-min;
        font-size: 14px;
        color: $base-dark;
    }

    .title-text-micro{
        @extend .text-cap-micro;
        color: $grey-main-light;
    }
    .placement_container{
        padding-top: 1em;
        padding-bottom: 1em;
        border-top: 1px solid #E4E7F2;
    }
    .mr-b{
        margin-bottom: rem-local(32);
    }
    .mr-t{
        margin-top: rem-local(32);
    }
}
