.search-results {
    &__title {
        @extend .heading-h1;
        @extend .align-self-baseline;
        color: $base-dark;
        margin-right: 40px;
        line-height: 48px;

    }
    &__total {
        @extend .heading-h3;
        @extend .align-self-baseline;
        color: $grey-main-light;

    }
    .sort-by-block {
        padding-top: 35px;
        border-bottom: 1px solid $border-color;
        padding-bottom: 17px;

        .sort-by-list {
            .dropdown__text_selected {
                svg {
                    @extend .mr-2;
                }
            }
        }
    }
    .downLoad-search {
        margin-left: 1.75rem;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .rss-search{
        margin-left: 1.6rem;
    }
    .printForm-search{
        margin-left: 1.75rem;
    }
    .paginator-block {
        padding: 48px 0 39px 0;
    }
    .show-by {
        @extend .text-base-min;
        .select-wrap {
            width: 80px;
        }
        &__title {
            margin-right: 10px;
        }
    }

    .search-registry-entry-block {
        word-wrap: break-word;
        margin-top: 32px;
        border: 1px solid $border-color;
        border-radius: 3px;
        cursor: default;
        .cardWrapper &{
            margin-top: 0;
            background: $white;
        }
        &:hover{
            .href-block, .show-block-hover{
                display: block !important;
                @extend .custom-fadein;
                .cardWrapper &{
                    display: block !important;
                    animation: none !important;
                }
            }
            .data-block, .hidden-block-hover{
                display: none !important;
                .cardWrapper:not(.href-block-available) &{
                    display: block !important;
                }
            }
        }
        .registry-entry {
            &__header {
                padding-left: 24px;
                &-top {
                    @extend .text-base-micro;
                    color: $grey-main-light;
                    padding-top: 24px;
                    &__fz {
                        padding-right: 5px;
                    }
                    &__title {

                    }
                    &__icon {
                        padding-right: 24px;
                        a {
                            margin-right: 16px;
                            display: block;
                            float: left;
                        }
                        img {
                            cursor: pointer;
                        }
                        &-card{
                            padding-right: 32px;
                        }
                    }
                }
                &-mid {
                    @extend .heading-h3;
                    color: $base-dark;
                    align-items: flex-end;
                    white-space: nowrap;
                    &__h4 {
                        @extend .heading-h4;
                    }
                    &__number {
                        color: $prime-color;
                        padding-right: rem-local(28);
                        @extend .pt-2;
                        cursor: pointer;

                        a{
                            text-decoration: none;
                        }
                    }
                    &__caption {
                        padding-right: 1.302vw;
                    }
                    &__title {
                        @extend .text-base-min;
                        font-weight: bold;
                        padding-right: 1.302vw;
                    }
                    &__normal {
                        @extend .text-base-min;
                        padding-right: 1.302vw;
                    }
                    .error{
                        @extend .text-base-micro;
                    }
                    &__icon {
                        padding-right: 17px;
                        img {
                            margin-right: 8px;
                            cursor: pointer;
                        }
                    }
                }
                .cursorPointerAuto{
                    cursor: auto !important;
                }
            }

            &__body {
                padding: 0 16px 17px 24px;
                &-block {
                    padding-top: 16px;
                }
                &-title {
                    @extend .text-base-micro;
                    color: $grey-main-light;
                    padding-bottom: 3px;
                    word-break: break-word;
                    span{
                        display: inline-block;
                    }
                }
                &-value {
                    @extend .text-base-min;
                    color: $base-dark;
                    &--error {
                        color: $grey-main-light;
                    }
                    &--text {
                        color: $grey-main-light;
                    }
                }
                &-href {
                    @extend .text-base-min;
                    a:hover {
                        cursor: pointer;
                        text-decoration: none;
                        color: $prime-color-dark;
                    }
                }
                &-caption {
                    @extend .text-cap-base;
                    font-weight: 500;
                    color: $prime-color;
                }
            }

            &__lots {
                border-top: 1px solid $border-color;
                .lot-title, .lot-info {
                    padding-left: 16px;
                    padding-right: 16px;
                }
            }

            &__right{
                &-block {
                    padding: 24px 24px 16px 25px;
                }
                &-title {
                    @extend .text-base-bold;
                    color: $base-dark;
                }
                &-value {
                    @extend .text-base-micro;
                    padding-top: 8px;
                    color: $base-dark;
                }
            }

            &__left-col {
                flex: 0 0 69.3%;
                max-width: 69.3%;
            }

            &__right-col {
                flex: 0 0 30.7%;
                max-width: 30.7%;
            }
        }

        .lots-wrap {
            &-content {
                padding-right: 5px;
                padding-left: 24px;
                &__title {
                    padding: 11px 16px 11px 0px;
                    cursor: pointer;
                    @extend .text-base-min;
                    color: $prime-color;
                }
                &__total {
                    background: $grey-main-light;
                    color: #fff;
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    display: inline-block;
                    text-align: center;
                    @extend .text-base-nano;
                    vertical-align: middle;
                    margin-left: 4px;
                }
                &__body {
                    padding-right: 16px;
                    padding-bottom: 24px;
                    width: inherit;
                    &__title{
                        @extend .text-base-micro;
                        color: $grey-main-light;
                        padding-bottom: 3px;
                        padding-top: 16px;
                    }
                    &__href{
                        @extend .text-base-micro;
                        color: $prime-color;
                        padding-bottom: 3px;
                    }
                    &__header {
                        @extend .text-base-min;
                        color: $grey-main-light;
                        div {
                            padding-right: 8px;
                        }
                        .status{
                            color: $base-dark;
                            font-weight: bold;
                        }
                        .error{
                            margin-left: 2rem;
                            @extend .text-base-micro;
                        }
                    }
                    &__val {
                        @extend .text-base-min;
                        color: $base-dark;
                        .text__overflow{
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            font-weight: normal;
                        }
                    }
                    &--item{
                        &:first-child{
                            .lots-wrap-content__body__title{
                                padding-top: 0 !important;
                            }
                        }
                        &_sub {
                            margin-left: 20px;
                            &_first{
                                padding-top: 24px;
                                margin-left: 0 !important;
                                .lots-wrap-content__body__title{
                                    padding-top: 0 !important;
                                }
                                .lots-wrap-content__body__header {
                                    margin-right: 8px;
                                    .status{
                                        margin-left: 16px;
                                    }
                                }
                            }
                            .lots-wrap-content__body__title {
                                padding-top: 8px;
                            }
                        }
                    }
                }
            }
            .registry-entry__right-block {
                padding-top: 0;
            }
            .arrow-lots{
                -webkit-transition: .2s ease-out;
                -moz-transition: .2s ease-out;
                -o-transition: .2s ease-out;
                //transition: 0.3s ease-out;
            }
        }

        .price-block {
            color: $base-dark;
            &__title {
                color: $grey-main-light;
                @extend .text-base-micro;
                display: flex;
            }
            &__value {
                color: $base-dark;
                @extend .heading-h3;
                &--small{
                    @extend .text-base-min;
                }
            }
            &__no-value {
                @extend .heading-h3;
                color: $grey-main-light;
            }
        }

        .text-block{
            color: $base-dark;
            &__title{
                @extend .text-base-min;
                font-weight: bold;
            }
        }

        .data-block {
            color: $base-dark;
            @extend .custom-fadein;
            &__title {
                @extend .text-base-micro;
                color: $grey-main-light;
                padding-top: 16px;
            }
            &__value {
                @extend .text-base-min;
            }
        }
        .href-block {
            @extend .text-base-min;
            .href{
                padding: 0.25rem 0;
                &:hover a{
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }

        .hidden-block-hover {
            color: $base-dark;
            @extend .custom-fadein;
        }

        .separator{
            padding-bottom: 16px;
            &-border-bottom{
                padding-bottom: 17px;
                border-bottom: 1px solid $grey-border;
            }
        }
    }
}
@media (max-width: 1160px) {
    .registry-entry__right-block {
        padding-left: 0.85vw !important;
        padding-right: 0.6vw !important;
    }

}

@media (max-width: 1160px) {
    .lots-wrap .registry-entry__right-block {
        padding-left: 0.2vw !important;
        padding-right: 0.5vw !important;
    }

}
.quick-search-param{
    .border-bottom-quick{
        border-bottom: 1px solid #dee2e6;
        padding-bottom: 24px;
        margin-bottom: 21px;
    }
}
#ktruRubricatorSelectedSection{
    @extend .text-base-micro;
    color: $grey-main-light;
}

#btn-floating {
    position: absolute;
    width: 109px;
    opacity: 0;
    @include trans();
    &.btn-floating{
        &--quick{
            &::after{
                background: img-url('icons/icon_arrow_floating.svg') no-repeat !important;
                width: 6px;
                height: 12px;
                content: "";
                @extend .align-middle-absolute;
            }
        }
        &--extended{
            .btn-floating__arrow{
                background: img-url('icons/icon_arrow_floating.svg') no-repeat !important;
                width: 12px;
                height: 12px;
                transform: rotate(90deg);
                margin: 0 auto;
            }
        }
    }
    .btn {
        line-height: 40px;
    }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    #btn-floating {
        &.btn-floating{
            &--quick{
                &::after{
                    right: -6px !important;
                }
            }
        }
    }
}
.calendar-container ~ #btn-floating{
    opacity: 0 !important;
}
.popupModalOverlay ~ #btn-floating{
    opacity: 0 !important;
}
