.text_small {
    @extend .text-base-min;
}

.text-break {
    word-break: break-word !important;
    overflow-wrap: break-word !important;
    word-wrap: break-word !important;
}

.hyphenate {
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
}

.w-space-nowrap{
    white-space: nowrap;
}

.w-space-inherit{
    white-space: inherit !important;
}

.l-height-normal{
    line-height: normal !important;
}
