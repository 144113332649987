//Для вкладки вложения
.card {
    &-attachments {
        &-container {
            padding-bottom: 40px;
        }

        &_link {
            @extend .text-base-min;
            color: $prime-color;
        }

        &_padding-0-15px-15px-5px {
            padding: 0 15px 25px 5px;
        }

        &_files {
            @extend .text-base-min;
            color: $grey-main-light;
        }

        &__block {
            .title {
                @extend .heading-h2;
                padding: 40px 0 32px 0;
                color: $base-dark;
            }

            .attachment {
                padding: 32px 0;

                &-group{
                    @extend .pb-4;
                }

                &__text {
                    @extend .text-base-min;
                    color: $grey-main-light;
                }

                &__value {
                    @extend .text-base-mid;
                    color: $base-dark;
                }

                &.no-valid {
                    .edition__value {
                        color: $grey-main-light;
                    }
                    .attachment__value {
                        color: $grey-main-light;
                    }
                }

                &.no_valid {
                    .edition__value {
                        color: $grey-main-light;
                    }
                    .attachment__value {
                        color: $grey-main-light;
                    }
                }

                &.no_valid_always_visible {
                    .edition__value {
                        color: $grey-main-light;
                    }
                    .attachment__value {
                        color: $grey-main-light;
                    }
                }

                &-img {
                    padding-left: 12px;
                }
            }
        }

        .custom-padding-attachment{
            padding-left: 32px!important;
        }

        &-switch {
            padding-top: 38px;
            padding-bottom: 38px;

            .slider-text {
                @extend .text-base-min;
                color: $base-dark;
            }
        }
    }
}

//Для вкладки общая информация
.card {
    &-common {
        &-static {
            @extend .b-bottom, .pt-4, .pb-4;
        }

        .common-text {
            padding-bottom: rem-local(40);

            &__caption {
                @extend .heading-h2;
                padding-top: rem-local(40) !important;
                padding-bottom: rem-local(32) !important;

                &_sub{
                    @extend .heading-h3;
                    padding-bottom: rem-local(32);
                    padding-top: rem-local(32);
                }
            }

            &__caption-block {
                @extend .heading-h3, .pt-4, .pb-2;
            }

            &__title {
                @extend .text-base-min;
                padding-bottom: rem-local(4);
                color: $grey-main-light;

                &--no-padding {
                    padding: 0 !important;
                }

                &__margin-left {
                    margin-left: 20px;
                    @extend .text-base-min, .pb-1;
                    color: $grey-main-light;
                }
            }

            &__value {
                @extend .heading-h5, .pb-4;
                color: $base-dark;

                &_no-padding {
                    padding: 0 !important;
                }

                &--gray{
                    color: $grey-main-light!important;
                }
            }

            &__href {
                @extend .text-base-min, .pt-2;
                color: $prime-color;
            }

            .custom-padding{
                padding-bottom: 32px;
            }

            &__table-title {
                @extend .text-cap-micro;
                color: $grey-main-light;
            }

            &__title-inn {
                color: #909ebb;
                font-size: 16px;
                line-height: 20px;
            }
        }

        .toggle-block_body {
            padding-top: 1.5rem;
        }

        .toggle-block--open {
            .toggle-block_body {
                display: block !important;
            }

            .toggle-block_head img {
                transform: rotate(90deg);
                -webkit-transition: .2s ease-out;
                -moz-transition: .2s ease-out;
                -o-transition: .2s ease-out;
            }
        }

        &-list {
            list-style: none;

            li::before {
                content: "•";
                color: $grey-main-light;
                display: inline-block;
                width: 1em;
                margin-left: -1em
            }
        }
    }
}
