.modal {
    &-content {
        &-purchase223 {
            .scrollOnModalByY {
                height: 25rem;
                max-height: 25rem;
            }
            .paginator {
                margin: 15px 0 !important;
                .paging {
                    text-align: left;
                    padding-bottom: 0px;
                    margin: 0;
                    li {
                        margin-left: 5px;
                        margin-right: 5px;
                        display: inline;
                        font-size: 12px;
                        padding: 3px;
                        text-align: center;
                        a {
                            color: $grey-main-light;
                        }
                    }
                    .allRecords {
                        float: right;
                        margin: 10px 0 0;
                        color: $base-dark;
                    }
                    .currentPage{
                        background: $grey-super-light;
                        color: $prime-color;
                        border-radius: 50%;
                        display: inline-block;
                        width: 40px;
                        height: 40px;
                        padding-top: 11px;
                    }
                    .rightArrow {
                        a {
                            background: 0 0;
                            padding-right: 15px;
                            img {
                                vertical-align: middle;
                            }
                        }
                    }
                    .leftArrow {
                        padding-left: 0;
                        a {
                            background: 0 0;
                            padding-right: 15px;
                            img {
                                vertical-align: middle;
                            }
                        }
                    }
                }
            }
        }
    }
}
