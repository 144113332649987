a {
    color: $prime-color;
    text-decoration: none;
    &:hover{
        text-decoration: underline;
        color: $prime-color-dark;
    }
}

a.header-link_icon_left:hover{
    text-decoration: none;
}
