.popUp, .old-search-form {
.manySelectWrap {
    padding: 15px;
    border-radius: 5px;
    background: #eff0f1;
    margin-bottom: 20px;
}
.manySelectPopUp {
    position: relative;
    padding-right: 15px;
}
.manySelect .collapsed, .manySelectPopUp .collapsed {
    background-color: #fff;
    border: 2px solid #cecece;
    border-radius: 4px;
    box-sizing: border-box;
    color: #5b5b5b;
    cursor: pointer;
    overflow: hidden;
    padding: 0 0 0 10px;
    position: relative;
}
.manySelect .collapsed .msExpandButton, .manySelectPopUp .collapsed .msExpandButton {
    background: #fff url(../images/newDesign/sort_arrow.png) no-repeat 10px -117px;
    cursor: pointer;
    display: inline-block;
    height: 26px;
    position: absolute;
    right: 0;
    top: 0;
    width: 30px;
}
.manySelectWrap .collapsed span {
    line-height: 2.4em;
}
.manySelect .collapsed .msPlaceholder, .manySelectPopUp .collapsed .msPlaceholder {
    color: #9c9d9e;
}
.manySelectPopUp #quickSearchStar {
    font-size: 14px;
    color: #F9794C;
    font-weight: bold;
    position: absolute;
    right: 0;
    top: 0;
    cursor: default;
}
.manySelect .selectChose .expanded, .manySelectPopUp .selectChose .expanded {
    background-color: #fff;
    border: 2px solid #cecece;
    border-top: none 0;
    border-radius: 0 0 5px 5px;
    color: #666a6f;
    margin-bottom: 5px;
    max-height: 115px;
    overflow-x: hidden;
    overflow-y: auto;
}
.manySelect .selectChose .expanded ul, .manySelectPopUp .selectChose .expanded ul {
    padding: 0;
}
.manySelect .selectChose .expanded li, .manySelectPopUp .selectChose .expanded li {
    border-bottom: 1px solid #eff0f1;
    padding: 5px 0 5px 10px;
}
.manySelect .selectChose .expanded input, .manySelectPopUp .selectChose .expanded input {
    margin-right: 5px;
    margin-top: 2px;
    vertical-align: top;
}
.manySelect .selectChose .supplierDetectWaysExpanded input {
    pointer-events: none;
}
.manySelectWrap .modeInsert input {
    width: 90%;
}
.colorValue {
    color: #b4b4b4 !important;
}
.manySelectWrap .modeInsert .addRuleBtn {
    background: url(../images/newDesign/select_add_icon.png) no-repeat;
    width: 16px;
    height: 16px;
    display: block;
    float: right;
    margin-right: 7px;
    margin-top: 8px;
    cursor: pointer;
}
h2 {
    font-size: 22px;
    margin-bottom: 20px;
}
.popupSubHeader {
    color: #5f6a74;
    font-size: 18px;
    text-align: center;
    text-transform: uppercase;
}
.propertiesList {
    overflow: auto;
    max-height: 300px;
}
.propertiesList form {
    background: #eff0f1;
    border-radius: 5px 0 0 5px;
    padding: 15px;
    border-right: 3px solid #fff;
}
.propertiesList .checkTd {
    padding-right: 5px;
    vertical-align: top;
}
.propertiesList td {
    padding: 4px 0;
}
.okdpChoose {
    margin-top: 20px;
    text-align: center;
}
.okdpChoose .btn input {
    font-size: 14px;
    padding: 10px 15px;
}
.btn input[type="reset"], .btn input.resetButton {
    background-color: #a0a6ab;
}
.btn input[type="button"], .btn input[type="reset"] {
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    padding: 9px;
    border-radius: 5px;
}
.btn input[type="button"] {
    background-color: #f9794c;
}
.draftStagesExpanded input {
    position: absolute;
}

.draftStagesExpanded label {
    padding-left: 21px;
    position: relative;
}

li.addLine label {
    width: 445px;
    word-wrap: break-word;
}
}
