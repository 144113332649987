.extendedVariant {
    padding: 5px 15px;
}

.paginator.greyBox {
    background-color: #fff;
    padding: 5px 5px;
}

.paginator .rssBox {
    background: url(../images/newDesign/icon-rss.png) no-repeat 0px 0px !important;
    color: #f9794c;
    font-size: 16px;
    font-weight: bold;
    float: right;
    margin-left: 5px;
    width: 28px !important;
    height: 28px !important;
    text-indent: -99999em !important;
}

.paginator.extendedVariant > div > .paginator {
    float: none;
    width: 97%;
}

.paginator.extendedVariant .paginator {
    display: contents;
    float: right;
    vertical-align: middle;
    padding: 0;
    margin: auto;
    text-align: center;
    width: 63%;
}

.paginator.extendedVariant.width-57 .paginator{
    width: 57%!important;
}

.paginator.extendedVariant .allRecords, .paginator.extendedVariant .paging, .paginator.extendedVariant .noRecords {
    padding-right: 10px;
    padding-left: 10px;
}

.allRecords {
    color: #909EBB;
    float: right;
    font-size: 12px;
    margin: 0;
    margin-top: 10px;
}

.allRecords strong {
    color: #41484e;
    font-weight: 500;
}

.clear {
    clear: both;
    font-size: 0;
    height: 0;
    line-height: 0;
    margin: 0;
    overflow: hidden;
    padding: 0 !important;
}

.downLoad {
    background: url(../images/newDesign/load_ico.png) no-repeat 0px 0px;
    cursor: pointer;
    display: inline-block;
    float: right;
    height: 28px;
    margin-left: 15px;
    width: 28px;
}

.contractCommonPrintForm {
    background: url(../images/newDesign/printer.png);
    width: 26px;
    height: 26px;
    display: inline-block;
    float: right;
    margin-top: 6px;
    cursor: pointer;
}

.paginator.extendedVariant label {
    vertical-align: middle;
}

.paginator label {
    display: inline;
}

.floatLeft {
    float: left;
}

.filterBlock > input, textarea, select, button {
    cursor: pointer;
    font-size: 12px;
}

.filterBlock > input[type="checkbox"] {
    vertical-align: middle;
}

.paginator.extendedVariant label.customCheckbox > label {
    padding-top: 0px;
}

.paginator .customCheckbox > label {
    width: auto;
}

.paginator ul li {
    display: inline;
}

.allRecordsModal {
    color: #909EBB;
    float: none;
    font-size: 12px;
    margin-left: 1rem;
}

.extendedVariant, .margBtm50 .paginator {
    font-size: 14px;
    background: white;
    border: 1px solid #dae4ee;
    text-align: center;
    margin: 0;
    margin-bottom: 30px !important;
}

.paginator .rssBox, .downLoad, .customCheckbox {
    margin-top: 5px;
}

.paginator p.allRecords {
    margin: 0;
    margin-top: 10px;
}

.margBtm50 .paginator ul.pages {
    margin-left: 220px;
}

.extendedVariant .pages {
    background-color: white;
}
