.content {
    &-search-site-map {
        padding-bottom: 0px !important;
        //background: $grey-super-light;
        background: #eff0f2;
        .sub-search-menu {
            height: 81px;
            padding-top: 15px;
            &__item {
                @extend .text-cap-base;
                //@extend .noselect;
                color: $base-dark;
                padding-bottom: 0.7rem;
                margin-right: 2rem;
                cursor: pointer;
                border-bottom: 2px solid transparent;
                &-active {
                    color: $prime-color;
                    border-bottom: 2px solid $prime-color;
                }
            }
        }
    }

    &-site-map {
        .custom-tree {
            @extend .text-base-mid;
            &#left-column-site-map {
                padding-right: 0.5rem;
            }
            &#right-column-site-map {
                padding-right: 2rem;
            }
            &__item {
                &-title {
                    display: inline-block;
                    min-height: 30px;
                    padding-top: 0.3rem;
                    vertical-align: baseline;
                    color: $base-dark;
                    &.dismiss-item {
                        color: #dae4ee;
                    }
                    &:hover {
                        cursor: pointer;
                        color: $prime-color;
                    }
                }
                &-img {
                    display: inline-block;
                    margin-right: 1rem;
                    width: 56px;
                    height: 56px;
                    border-radius: 50%;
                }
            }
            > ul {
                margin: 30px 0 16px .5em;
                ul {
                    margin: 0
                }
            }
            img ~ ul {
                margin: 0 0 0 1.7rem;
            }
            > ul > li > a{
                @extend .heading-h3;
            }
            ul > li:not(:only-child),
            > li li {
                position: relative;
                padding: 2px 0 0 45px;
                li {
                    padding-left: 30px;
                }
            }
            > ul > li > ul > li {
                border-left: 1px solid $border-color;
                &:last-child {
                    border-left: none;
                    &:before {
                        border-left: 1px solid $border-color;
                    }
                }
                &:not(:only-child) {
                    &:before {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 36px;
                        height: 20px;
                        border-bottom: 1px solid $border-color;
                    }
                }
                > ul {
                    li {
                        padding-top: 0 !important;
                    }
                    .custom-tree__item-title {
                        min-height: 22px;
                        padding-top: 0;
                        @extend .text-base-min;
                    }
                }
            }
        }
        &__search-text {
            background: #F2E084;
        }
    }
}
