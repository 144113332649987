.content-card {
    background-color: #EFF0F2;
    .page-top {
        &-info {
            margin-bottom: 42px;
        }
        .breadcrumb {
            display: inline-block;
            color: $prime-color;
            margin-right: 18px;
            @extend .text-base-micro;
            &__link {
                vertical-align: middle;
                margin-left: 7px;
            }
        }
        .time {
            display: inline-block;            
            &_current {
                display: inline-block; 
                margin-left: 8px;               
                .text {
                    padding-left: 10px;
                    border-left: 1px solid #DAE4EE;
                }
            }
        }
    }
    .tabs-list {
        margin-top: 55px;
    }
    
    .card-main, .card-side {
        background-color: #ffffff;
        border-radius: 3px;
        &__header {
            height: 97px;
            padding: 25px;
            border-bottom: 1px solid #EFF0F2;
            .price {
                @extend .heading-h2;
                &__label {
                    @extend .text-base-mid;
                }
            }
        }
        &__info {
            &:first-child {
                margin-bottom: 15px;
            }
        }
        &__content {
            height: 207px;
            padding: 25px;
            &-block {
                height: 100%;
                &_right {
                    padding-right: 28px;
                }
                &_left {
                    padding-left: 21px;
                    padding-right: 28px;
                }
            }
        }
    }

    .border_left {
        border-left: 1px solid #EFF0F2;
    }
    .card {
        &__title {
            @extend .heading-h2;
        }
        &__number {
            position: relative;
            color: $base-dark;
            @extend .text-base-mid;
            .icon {
                position: absolute;
                top: -2px;
                left: -10px;
            }
            &_small {
                display: inline-block;
                @extend .text-base-micro;
                color: $grey-main-light;
            }
        }
    }
    .tab {
        // background-color: #ffffff;
        padding: 35px 0;
    }
    
}
