.modal {
    &-content {
        &-lk {
            width: 544px;
            .lkError{
                color: $base-error;
                margin: 20px 0 0 0;
            }
            .lkChoose{
                .regBtn{
                    .btn input{
                        width: 136px;
                    }
                }
            }
        }
    }
    &-text {
        &-block {
            .lk input[type=text] {
                width: 100%;
                height: 40px;
                padding: 7px;
                border: 1px solid #DAE4EE;
                box-sizing: border-box;
                box-shadow: inset 0px 2px 1px #EFF0F2;
                border-radius: 3px;
            }
            .lkCode{
                max-width: 168px;
                max-height: 40px;
                background: url(/epz/static/img/icons/lk_code.svg) no-repeat;
            }
        }
    }
    .lkChoose{
        padding: 24px;
        font-size: 12px;
    }
}

