.filterParametrs .collapceBox {
    background-position: 10px 8px;
    float: right;
    padding: 5px 0 5px 32px;
    position: static;
}
.filterParametrs .expandBox {
    background-position: 10px -77px;
}
.collapceBox {
    background: url(../images/newDesign/expand_btn_sprite.png) no-repeat 10px 10px;
    color: #a4a4a4;
    cursor: pointer;
    font-size: 13px;
    margin: 0 auto;
    padding: 7px 0 7px 35px;
}
.expandBox {
    background: url(../images/newDesign/expand_btn_sprite.png) no-repeat 10px -75px;
}
.collapce, .expand {
    border-bottom: 1px dashed #a4a4a4;
}
.filterParametrs li {
    border-bottom: 1px solid #0065DD;
    color: #0065DD;
    cursor: pointer;
    display: inline;
    margin-right: 51px;
}
.filterParametrs li.currentPar {
    border-bottom: none 0;
    color: #334059;
    cursor: default;
    font-size: 18px;
}
.pseudoInputBox {
    padding: 12px 20px;
}
.tuningQuickSearchBlock {
    position: relative;
}

.margLeft255{
    margin-left: 255px!important;
}

.pseudoInput {
    background: #fff url(../images/newDesign/blue_arrow.png) no-repeat 99% 15px;
    border-radius: 3px;
    cursor: pointer;
    font-size: 13px;
    min-height: 18px;
    overflow: hidden;
    padding: 10px 30px 10px 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.dishonestSupplier{
    > li{
        padding-bottom:20px!important;
    }

    label{
        margin-right: 15px;
    }
}

.greyText {
    color: #909EBB !important;
}
.tuningQuickSearchBox {
    background-color: #fff;
    display: none;
    position: absolute;
    width: 100%;
    z-index: 100;
}
.tuningQuickSearchBox .tuningQuickSearchList {
    border-radius: 0 0 3px 3px;
    border-top: none 0;
    max-height: 227px;
    overflow-y: auto;
    margin: 0;
}
.tuningQuickSearchBox .tuningQuickSearchList li {
    color: #666a6f;
    line-height: 16px;
    padding: 6px 10px 6px 10px;
}
.listParametrsChoice {
    display: none;
}
.listParametrsSearch li {
    color: #6F7C98;
    padding: 5px 0 0;
}
.listParametrsSearch label {
    padding-right: 7px;
}
.resultItem {
    background-color: #fff;
    border-radius: 4px;
    color: #41484e;
    display: inline-block;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 4px 8px;
}
.resultItem span {
    background: url(../images/newDesign/close_ico.png) no-repeat 0 0;
    cursor: pointer;
    margin-left: 10px;
    padding: 0 0 1px 15px;
    vertical-align: middle;
}
.listParametrsChoice li, .saveExtendedSearch li {
    padding-bottom: 5px;
}
.listParametrsChoice .lowList {
    display: inline;
    vertical-align: top;
}
.listParametrsChoice.reestrQuaranty > li > label:first-child {
    line-height: 16px;
    vertical-align: middle;
}
.listParametrsChoice.reestrQuaranty > li > label, .listParametrsChoice.reestrQuaranty > li .currencyLabel > label {
    width: 240px;
}
.listParametrsChoice > li > label {
    display: inline-block;
    width: 180px;
}
.sortBy > ul > li.sortItem {
    line-height: 26px;
}
.lowList li, .sortBy > ul > li {
    display: inline;
}
.greyBox {
    background-color: #eff0f1;
    border-radius: 5px;
    padding: 13px 17px;
}
.height30 {
    height: 30px;
    line-height: 26px;
}
.blueBtn {
    background-color: #2098df;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    padding: 7px 15px;
    font-size: 14px;
    line-height: 15px;
}
.tuningQuickSearchBox {
    background-color: #fff;
    display: none;
    position: absolute;
    width: 100%;
    z-index: 100;
}
.darkGreyBox.rightBtnBox {
    padding: 9px 15px 6px;
    overflow: auto;
}
.filterBlock .darkGreyBox {
    border-radius: 0px 0px 5px 5px;
    margin-top: -3px;
}
.clearAll {
    background: url(../images/newDesign/close_blue_ico.png) no-repeat;
    color: #018ccd;
    cursor: pointer;
    padding-left: 25px;
}
.clearAll em {
    border-bottom: 1px dashed #018ccd;
    font-style: normal;
}
.searchBtn {
    background: url(../images/newDesign/white_zoom.png) no-repeat 10px 10px;
    padding-left: 40px !important;
}
.bigOrangeBtn, .bigGreyBtn {
    background-color: #0065dd;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    padding: 11px 15px;
    text-transform: uppercase;
}
.darkGreyBox.rightBtnBox .saveIcon {
    line-height: 43px;
}
.darkGreyBox.rightBtnBox {
    padding: 9px 15px 6px;
    overflow: auto;
}
.filterBlock .darkGreyBox {
    border-radius: 0px 0px 5px 5px;
    margin-top: 1px;
}
.darkGreyBox {
    background-color: #eff0f1;
    border-radius: 5px;
    padding: 13px 15px;
}
.saveIcon, .filterBlock .saveIcon {
    background: url(../images/newDesign/save_ico.png) no-repeat 0px 0px;
    color: #0065dd;
    cursor: pointer;
    padding: 0 0 1px 25px;
    text-decoration: none;
}
.datepicker_ru {
    background: url(../images/newDesign/calendar_ico.png) no-repeat 94% 50%;
}
.zoom {
    background: url(../images/newDesign/main_zoom.png) no-repeat scroll 0 0;
    display: inline-block;
    height: 20px;
    margin-left: 5px;
    width: 20px;
}
a.zoom {
    height: 30px;
    width: 30px;
    vertical-align: middle;
}
.customCheckbox .info{
    background: url(../images/newDesign/header_icons_sprite.png) no-repeat 0 -45px;
    cursor: pointer;
    display: inline-block;
    height: 16px;
    margin-left: 3px;
    vertical-align: top;
    width: 16px;
}
.customCheckbox > .help {
    background: url(../images/newDesign/header_icons_sprite.png) no-repeat -137px -45px;
    margin-left: 0;
    cursor: pointer;
    display: inline-block;
    height: 16px;
    margin-left: 3px;
    vertical-align: top;
    width: 16px;
}
.darkGreyBox .floatRight {
    display: none;
}
.darkGreyBox.rightBtnBox .floatRight {
    display: block;
}
.pseudoSelect {
    background: #fff url(../images/newDesign/sort_arrow.png) no-repeat 96% -118px;
    border: 2px solid #d3d7db;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
    min-height: 18px;
    overflow: hidden;
    padding: 0 30px 0 0;
    text-indent: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.saveExtendedSearch .vAlignTop, .listParametrsChoice .vAlignTop {
    padding-top: 7px;
}
.vAlignMiddle {
    vertical-align: middle !important;
}
.saveExtendedSearch .lowList {
    display: inline;
    vertical-align: top;
}
input[type="checkbox"] {
    vertical-align: middle;
}
.bigOrangeBtn, .bigGreyBtn {
    background-color: #0065dd;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    padding: 11px 15px;
    text-transform: uppercase;
}
.bigOrangeBtn.searchBtn {
    background-color: #0065dd;
}

.darkGreyBox.saveExtendedSearch .floatRight {
    display: block;
}
.addBtn {
    background: #2098df url(../images/newDesign/add_ico.png) no-repeat 0 0;
    padding-left: 25px;
}
.saveExtendedSearch .ikzLabels {
    font-size: 80%;
    margin-left: 240px;
}

.ikzYearLabel {
    margin-right: 1.3em;
    width: 40px !important;
    margin-left: 0.4em;
}
.ikzLabelIKZ {
    vertical-align: top !important;
}
.kbkTextarea, .ikzTextarea {
    overflow: hidden;
    padding: 0 5px;
}
.ikzYear {
    width: 40px !important;
    margin-right: 1em !important;
}
.ikzIKZ {
    margin-right: 1.3em;
    width: 156px !important;
}
.ikzIKZLabel {
    margin-right: 1.6em;
    width: 156px !important;
}
.ikzNumPZLabel {
    margin-right: 2em;
    width: 101px !important;
}
.ikzNumPGZLabel {
    margin-right: 1.7em;
    width: 131px !important;
}
.ikzNumOkpd2Label {
    margin-right: 1.6em;
    width: 56px !important;
}
.ikzNumKVRLabel {
    width: 61px !important;
}
.ikzNumPZ {
    margin-right: 1.5em;
    width: 101px !important;
}
.ikzNumPGZ {
    margin-right: 1.3em;
    width: 131px !important;
}
.ikzNumOkpd2 {
    margin-right: 1.3em;
    width: 56px !important;
}
.restrictionsToPurchase44Tag{
    display: none;
}
span.selectBox {
    display: inline-block;
    position: relative;
    top: 0;
}
span.selectBox select {
    height: 28px;
    position: relative;
    z-index: 10;
}
.select {
    //background-color: #fff;
    //border: 2px solid #cecece;
    //border-radius: 4px;
    //color: #5b5b5b;
    //cursor: pointer;
    //display: inline-block;
    //height: 26px;
    //left: 0;
    //line-height: 25px;
    //overflow: hidden;
    //padding: 0 25px 0 10px;
    //position: absolute;
    //top: 0;
    //white-space: nowrap;
}
.select .selectRight {
    background: url(../images/newDesign/sort_arrow.png) no-repeat 0 -129px;
    display: inline-block;
    height: 6px;
    position: absolute;
    right: 7px;
    top: 10px;
    width: 10px;
}
.width160, .width160 select {
    width: 160px !important;
}
.defenceContractNumber > ul > li:nth-child(1) {
    font-size: 80%;
    margin-bottom: 10px;
}
.defenceContractNumber > ul > li:nth-child(1) > label:nth-child(1), .defenceContractNumber > ul > li:nth-child(2) > label:nth-child(1) {
    width: 240px;
}
.defenceContractNumber > ul > li:nth-child(1) label, .defenceContractNumber > ul > li:nth-child(2) label {
    display: inline-block;
    vertical-align: middle;
}
.defenceContractNumber > ul > li:nth-child(1) label {
    padding-top: 7px;
}
.defenceContractNumber > ul > li:nth-child(1) > label:nth-child(2) {
    width: 325px;
}
.listParametrsChoice li, .saveExtendedSearch li {
    padding-bottom: 5px;
}
input.height30 {
    height: 30px;
    line-height: 24px;
}
.defenceContractNumber > ul > li:nth-child(2) > input:nth-child(4) {
    width: 311px;
}

.filterBlock .noticeBoxExpand {
    background-color: #dbe1e6;
    border: none 0;
    border-radius: 0;
    margin-bottom: 1px;
    margin-top: 0;
    padding: 6px 20px;
}
.noticeBoxExpand {
    background-color: #eff0f1;
    border: 2px solid #d3d7db;
    border-radius: 5px;
    margin-top: 20px;
    padding: 10px 20px;
}
.filterBlock .noticeBoxExpand h3 {
    margin-bottom: 4px;
    margin-top: 4px;
    font-size: 12px;
    font-weight: bold;
    padding: 0 0 0 20px;
}
.noticeBoxExpand h3 {
    background: url(../images/newDesign/expand_arrow.png) no-repeat 0 50%;
    cursor: pointer;
    font-size: 16px;
    text-transform: uppercase;
    padding: 0 0 0 30px;
}
.filterBlock .noticeBoxExpand .collapceArrow {
    background: url(../images/newDesign/collapse_arrow_alt.png) no-repeat 0 50%;
    color: #d0562b;
}
.saveExtendedSearch .lowList label, .saveExtendedSearch .styleLabelCheck, .styleLabelCheck {
    padding-right: 15px;
    width: auto;
}
.customCheckbox > label {
    width: auto;
    display: block;
}
.saveExtendedSearch input[type="text"] {
    vertical-align: middle;
}
.margRight10, input.margRight10 {
    margin-right: 10px;
}

.margLeft0, .toggleGrey span.margLeft0{
    margin-left: 0;
}


.padRight5, table.padRight5 td {
    padding-right: 5px !important;
}
.manySelect .collapsed .msExpandButton.active, .manySelectPopUp .collapsed .msExpandButton.active {
    background-position: 10px -183px;
}
.parametrs h3{
    font-size: 15px;
}
.padRight15Usual {
    padding-right: 15px !important;
}
.width55Usual {
    width: 55px !important;
}
.saveExtendedSearch .manySelect {
    display: inline-block;
    vertical-align: middle;
}
.manySelect .collapsed .choseColor, .manySelectPopUp .collapsed .choseColor {
    color: #5b5b5b !important;
}

.manySelect .customCheckbox{
    min-width: 210px;
}

.modal-content-chosenPurchaseMethod {
    width: 900px;
    height: auto;
    max-height: 70%;
    overflow-y: auto;
}
.filterBlock .editIcon {
    background: url(../images/newDesign/edit_ico.png) no-repeat scroll 0 -31px;
    padding: 0 0 0 25px;
    text-decoration: none;
    cursor: pointer;
    color: #018ccd;
}
.darkGreyBox.saveExtendedSearch .editIcon {
    line-height: 28px;
}
span.resultItem {
    font-size: 12px;
}
.searchBlockAllPoor {
    width: 1120px !important;
}

#interimResultsTagDataContainer .customCheckbox>label {
    display: inline;
}

#infoTypeSetTagDataContainer .customCheckbox label {
    display: inline;
}
.okdpChoose .btn {
    display: inline;
}

#selectedSubjectsTagDataContainer label {
    width: 100%;
}

li#dizkInfo .expanded label {
    width: 100%;
}

.kbkChapter {
    @extend .kbkArticle;
}
label.kbkChapterLabel{
 width: 150px;
}
.kbkArticle{
    margin-right: 15px;
}

.kbkWidth {
    width: 133px;
}

#kbk ul li span:nth-child(n+2):not(:last-child) {
    margin-right: 3%;
}


label.width90percent {
    width: 90%;
}

.marginTop-5px{
    margin-top: 5px !important;
}
#dishonestSupplierPlaceOfSearchTag{
    display: inline-flex;
}
#dishonestSupplierPlaceOfSearchTag .customCheckbox{
    margin-top: 0px;
    padding-left: 17px;
}
ul.reestrQuaranty #dishonestSupplierPlaceOfSearchTag .customCheckbox{
    padding-left: 0;
}
.listParametrsChoice.reestrQuaranty>li#dishonestSupplierPlaceOfSearchTag>ul>label:first-child{
    width: 240px;
}
.dishonestSupplier #dishonestSupplierPlaceOfSearchTag label{
    margin-right: 0;
}
.tuningQuickSearchTitle, .editingBtn, .delBtn {
    cursor: pointer;
}

#inspectionReasonSelect, #typeProvenanceInfoSelect {
    background-position-x: 98%;
}

.lowList li.customCheckbox.vAlignTop.purchasePlansTypesLi:first-child > label{
    width: 195px;
}
.saveExtendedSearch #classifiersTagDataContainer {
    max-width: 630px;
}
