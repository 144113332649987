body {
    background-color: #E5E5E5;
}
.padd {
    //padding-right: 18px;
}

p, h1, h2, h3, h4, h5, h6 {
    margin: 0;
}

.table-container {
    width: 100%;
    margin: 0 auto;
    background-color: #ffffff;
    padding-bottom: 40px;
}

.head-text {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    color: #334059;
    padding: 40px 0 32px;
}

.normal-text {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #334059;
}

.table-head-text {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    color: #909EBB;
}

.base-blue-text {
    color: #0065DD;
}

.error-text {
    color: #E9564A;
}

.table {
    border-collapse: collapse;
    width: 100%;
    border-bottom: 1px solid #e4e7f2;

    &-lot {
        display: none;
    }
    &__header {
        border-top: 1px solid #E4E7F2;
        border-bottom: 1px solid #E4E7F2;
        text-align: left;

        &-item {
            padding: 16px 0;
            vertical-align: baseline;

            &:last-of-type {
                padding-right: 0;
                & .padd {
                    padding-right: 0;
                }
            }
        }
    }

    &__row {
        &-item {

            padding: 16px 8px 16px 16px;
            border-bottom: 1px solid #E4E7F2;
            vertical-align: baseline;

           & a:not(.table-icon):hover {
                text-decoration: underline;
            }

        }
    }

    &-icon {
        margin-right: 28px;
        cursor: pointer;
        &:last-of-type {
            margin-right: 0;
        }
    }

    &_layout_auto {
        table-layout: auto !important;
    }
}

.long-td {
    max-width: 270px;

}

.bid-td {
    max-width: 240px;
}

.large-td {
    max-width: 225px;

}

.wide-td {
    max-width: 210px;

}

.middle-td {
    max-width: 176px;

}

.standard-td {
    max-width: 170px;

}

.medium-td {
    max-width: 150px;
}

.small-td {
    max-width: 138px;
}

.short-td {
    max-width: 130px;

}

.tiny-td {
    max-width: 115px;

}

.shallow-td {
    max-width: 105px;

}

.thin-td {
    max-width: 92px;

}

.chevron-td {
    max-width: 44px;
    width: 44px;
    text-align: center;
    vertical-align: baseline;
}

.paginator-block {
    padding: 24px 0 40px;

    &__pt28 {
        padding: 28px 0 0 0 !important;
    }
    &__pt24 {
        padding: 24px 0 0 0 !important;
    }

    &__ellipsis {
        width: 40px;
        text-align: center;
    }

    &__title {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 20px;
        text-align: right;
        color: #334059;
        margin-right: 8px;
    }

    &__select {
        width: 88px;
    }
}


.customer-planning-contract {
    margin-bottom: 24px;
    &:last-of-type {
        margin-bottom: 0;
    }
}

.show-all-customers {
    display: inline-block;
    margin: 8px 0 2px;
    cursor: pointer;
}

.status-text {
    margin-bottom: 4px;
    &:last-of-type {
        margin-bottom: 0;
    }
}

.lot {

    &:not(:first-of-type) {
        padding-top: 20px;
        border-bottom: 1px solid #E4E7F2;
        border-top: 1px solid #E4E7F2;

}


    &__header {
        display: flex;
        align-items: baseline;
    }

    &__title {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        color: #334059;
        padding: 0 0 32px;
        margin-left: 20px;
    }

    &_open {
        & > .lot__header > .lot__chevron {
            transform: rotate(90deg);
        }

        & >  .table-lot {
            display: table;
        }
        & > .block-lot {
            display: block;
        }
    }
}

.hidden {
    display: none;
}

.file_icon {
    margin-right: 10px;
}

.block-lot {
    display: none;
}
