#qaListForm .wrapper {
    height: 100%;
    margin: 0 auto;
    max-width: 910px;
    min-width: 910px;
    min-height: 100%;
    padding: 0 0px 0px 40px;
}

#qaListForm .outerWrapper {
    margin: 0 auto;
    max-width: 1000px;
    min-width: 1000px;
    width: 100%;
}

#qaListForm .mainPage {
    position: relative;
}

#qaListForm table {
    font-style: normal;
    font-size: 12px;
}

#qaListForm .padTop25 {
    padding-top: 25px !important;
}

#qaListForm .leftCol {
    float: left;
    width: 230px;
}

#qaListForm .rightCol {
    margin-left: 270px;
}

#qaListForm h3 {
    color: #5b5b5b;
    font-size: 18px;
    font-weight: normal;
}

#qaListForm h3 {
    color: #5b5b5b;
    font-size: 18px;
    font-weight: normal;
}

#qaListForm .rightCol h3 {
    padding: 15px 0;
}

#qaListForm .margBtm40 {
    padding-bottom: 40px !important;
}

#qaListForm .padBtm10 {
    padding-bottom: 10px !important;
}

#qaListForm .margBtm0 {
    margin-bottom: 0px !important;
}

#qaListForm .clear {
    clear: both;
    font-size: 0px;
    height: 0px;
    line-height: 0;
    margin: 0;
    overflow: hidden;
    padding: 0 !important;
}

#qaListForm .margTop30 {
    margin-top: 30px;
}

#qaListForm .scrollUp {
    background: url("../images/newDesign/page_up.png") no-repeat 0 3px;
    color: $colorMainGray;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    padding-left: 30px;
    text-transform: uppercase;
}

#qaListForm .margBtm50 {
    margin-bottom: 50px;
}

#qaListForm .greyBox {
    background-color: #eff0f1;
    border-radius: 5px;
    padding: 13px 15px;
}

#qaListForm .allRecords {
    color: $colorMainGray;
    float: right;
}

#qaListForm ol, #qaListForm ul {
    list-style-type: none;
}

#qaListForm .paging {
    text-align: center;
}

#qaListForm .paging li {
    display: inline;
    font-size: 12px;
    padding: 3px;
}

#qaListForm .paging .currentPage {
    background-color: $mainBackground;
    border-radius: 3px;
    color: #d0562b;
    font-weight: bold;
    margin: 0 5px;
    padding: 5px 11px;
}

#qaListForm a, #qaListForm a:focus, #qaListForm img, #qaListForm button {
    border: none;
    outline: none;
}

#qaListForm a {
    color: #018ccd;
    text-decoration: none;
}

#qaListForm .paging li a {
    color: #5f6a74;
}

#qaListForm .paging .leftArrow a, #qaListForm .paging .rightArrow a {
    background: url("../images/newDesign/expand_btn_sprite.png") no-repeat 0 -244px;
    padding-right: 15px;
}

#qaListForm .paging .rightArrow a {
    background-position: 0 -164px;
}

#qaListForm .allRecords strong {
    color: #41484e;
}

#qaListForm .questionLink {
    cursor: pointer;
}

#qaListForm .answereBox {
    position: relative;
}

#qaListForm .answereBlock .answereBox {
    top: 35px;
}

#qaListForm .commentWrapper :last-child.answereBox {
    margin-bottom: 0px;
}

#qaListForm .askBox .commentWrapper .answereBox {
    margin-top: -65px;
}

#qaListForm .answereBox .titleComment {
    margin-top: 40px;
}

#qaListForm .answereBlock .answereBox .titleComment {
    padding: 6px 10px 6px 12px;
}

#qaListForm .margLeft10 {
    margin-left: 10px;
}

#qaListForm .fzNews {
    background: url("../images/newDesign/sprite_vert.png") no-repeat 0 -131px;
    padding-left: 20px;
}

#qaListForm .floatRight {
    float: right;
}

#qaListForm .questionLink ul, #qaListForm .questionLink ol, #qaListForm .answereBox ul, #qaListForm .answereBox ol {
    padding: 8px 12px 12px 28px;
}

#qaListForm .questionLink ol, #qaListForm .answereBox ol {
    list-style-type: decimal;
}

#qaListForm .greenText, #qaListForm .tenderTd .greenText {
    color: #2a944f !important;
}

#qaListForm .blueText {
    color: #018ccd;
    cursor: pointer;
}

#qaListForm .padBtm15 {
    padding-bottom: 15px !important;
}

#qaListForm .leftColAskList li, #qaListForm .leftColAskList a {
    color: #5f6a74;
}

#qaListForm .leftColAskList > li {
    background: url("../images/newDesign/expand_arrow.png") no-repeat 7px 10px;
    cursor: pointer;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    background-size: 10px;
}

#qaListForm .leftColAskList > li.collapceArrow {
    background: url(../images/newDesign/sort_arrow.png) no-repeat 2% -184px;
    color: $prime-color;
}

#qaListForm .leftColAskList > li.selectItemIsLoadOnThisPage {
    border-left: 2px solid $prime-color;
    padding-left: 20px;
}

#qaListForm .leftColAskList > li.disableCollapse {
    background: none;
}

.rubric > .subrubric {
    margin-left: 10px;
    margin-top: 5px;
}

#qaListForm .collapceArrow .subrubric {
    color: $prime-color;
}

#qaListForm .collapceArrow .subrubric a {
    color: #5f6a74;;
}

.qasearchBlock {
    border: 1px solid $grey-extra-light;
    border-radius: 3px;
    width: 100%;
    font-size: 13px;
    line-height: 20px;
    font-style: normal;
    font-weight: 400;
}

#qa-search-btn {
    //vertical-align: middle;
    //display: flex;
    min-height: 38px;
    padding-top: 7px;
    /* float: right; */
    margin-left: auto;
    margin-right: 10px;
    cursor: pointer;
}

.aksBoxH3 {
    color: $mainColorText;
    font-size: 26px;
    line-height: 32px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 15px;
}

.rubricText {
    font-size: 17px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: $mainColorText;
}

.questionText {
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 28px;
    color: $prime-color;
}

.rubricTextTip {
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 28px;
    color: $grey-main;
}

.questionAnswer {
    ul li {
        list-style: disc!important;
        list-style-type: disc!important;;
    }
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: $grey-main;
}

.fileText {
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 15px;
    color: $grey-main;
}

.rubricTextSearch {
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 20px;
    color: $prime-color;
}

.itemSelected {
    border-left: 2px solid $prime-color;
    padding-left: 20px;
}

.laws {
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    color: $colorMainGray;
}

.colorBlack {
    color: $mainColorText;
}

.colorBlue {
    color: $prime-color;
}

.top-10 {
    top: 10px;
}

li > label {
    width: auto;
}

.margTop44 {
    margin-top: 44px;
}

.margTop8 {
    margin-top: 8px;
}

.marginTop_32 {
    margin-top: 32px;
}

.questions-and-answers {
    .questionLink {
        p {
            color: $mainColorText;
            font-size: 19px !important;
            line-height: 24px;
            font-style: normal;
            font-weight: 500;
            margin: 0;
            font-family: 'Roboto' !important;

            span {
                color: $mainColorText;
                font-size: 19px !important;
                line-height: 24px;
                font-style: normal;
                font-weight: 500;
                margin: 0;
                font-family: 'Roboto' !important;
            }
        }
    }

    .noticeTabBoxWrapper {
        span {
            color: $mainColorText;
            font-size: 17px !important;
            line-height: 20px;
            font-style: normal;
            font-weight: 400;
        }
    }

    .breakAll {
        margin-top: 35px;
    }

    td.fileSize span {
        font-size: 14px !important;
        margin-left: 5px;
    }

    .fzNews {
        margin-top: 2px;
    }

    .expand {
        border-bottom: none;
    }

    .titleComment {
        margin-top: 15px;
    }

    .noticeTabBoxWrapper.commentWrapper {
        border-bottom: 1px solid $grey-border;
    }

    .totalRecord {
        float: right;
        margin-top: 13px;
        margin-right: 5px;
        font-size: 14px;
    }

    .paginator {
        border: 1px solid $grey-extra-light;
        border-radius: 5px;
        margin: 25px auto;
        text-align: center;
        min-height: 50px;

        .pages {
            margin: 5px;
            display: inline-block;
        }
    }

    .serchNoResult {
        display: inline-block;
        margin-top: 13px;
        font-size: 14px;
        font-family: 'Roboto';
    }

    .highlightColor {
        color: rgba(248, 212, 0, 0.3) !important;
    }
}
