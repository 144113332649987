.relative-block {
    position: relative;
}

.top-3rem{
    top: 3rem;
}

.align-middle-absolute{
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
}
.right-05{
    right: 0.5rem;
}

//Для div / section
.col-1-5 {
    flex: 0 0 12.3%;
    max-width: 12.3%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

@for $i from 1 through 10 {
    .right-#{$i}{
        right: #{$i}rem !important;
    }
}
