$prime-color:#0065DD;
$prime-color-dark:#014DA8;
$prime-color-light: #62ADE9;
$prime-color-super-light: #93BAF6;
$mainColorText: #334059;
$colorMainGray: #909EBB;

$dim-gray: #707070;
$grey-main: #6F7C98;
$grey-main-light: #909EBB;
$grey-main-extra-light: #DDE0E5;
$grey-extra-light: #DAE4EE;
$grey-super-light: #eff0f2;
$grey-text: #a4a4a4;

$grey-border: #e4e7f2;

$base-dark: #334059;
$base-disabled: #C4CDD6;
$base-hover-item-list: #F0F3FF;
$base-error: #E8523D;

$borderActive: #1F8ADF;

$transparent: transparent;

$mainBackground: #fff;

$success: #3E9C12;
