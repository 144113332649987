.cardLayout {
@import "cardMainInfo";
@import "protocol-result";
@import "ikzInputs";
@import "filter";

.iconWithText {
    padding-right: 5px;
    vertical-align: middle;
}

.registryTitle {
    margin-bottom: 15px;
}

.lot {
    padding: 0!important;
    border: none!important;
    margin-bottom: 24px!important;

    .no-mb {
        margin-bottom: 0!important;
    }

    &__title {
        padding: 0!important;
    }
}

.docName {
    margin-bottom: 16px;
}

.controlOrgOfDocs {
    margin-top: 32px!important;
    padding: 0!important;
    border: none!important;
}

.notice-documents:first-of-type, .first-row-active-documents.closedInactiveDocuments {
    padding-top: 8px!important;
    margin-top: 0!important;
    border-top: none!important;
}

.notice-documents:not(:first-of-type) {
    border-top: 1px solid $grey-border;
    padding-top: 32px;
    padding-bottom: 32px;
}

.openInactiveElements:first-of-type {
    padding-top: 15px;
}

.openInactiveElements:not(:first-of-type) {
    margin-top: 32px;
    border-top: 1px solid $grey-border;
    padding-top: 32px;
}

.visualLimitTable {
    max-height: 500px;
    overflow-y: auto;
}

.rowSpaceBetween, .colSpaceBetween {
    display: flex;
    justify-content: space-between;

    &_flexEnd {
        align-items: flex-end;
    }
}

.blockIcon {
    white-space: nowrap;
}

.flexEnd {
    justify-content: flex-end;
}

.relatedLink {
    padding-top: 10px;
}

.btnBtn {
    cursor: pointer;
}

.explanatory-text {
    font-weight: bold;
    color: $grey-main;
}

.openedArrow {
    transform: rotate(90deg);
}

.colSpaceBetween {
    flex-direction: column;
}

.distancedText {
    margin-right: 1rem;
}

.subscribeRss {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 12px;

    &__icon {
        margin-left: 16px;
        position: relative;
        top: 1px;
    }

    &__link {
        font-size: 12px;
        color: $base-dark;
    }
}

.printFormBlue {
    font-size: 13px;
    margin-top: 25px;
    &__icon {
        margin-right: 10px;
        position: relative;
        top: 3px;
    }
}

.borderRight {
    border-right: 1px solid $grey-border;
}

.cardHeaderBlock {
    background-color: $grey-super-light;
}

.mainStyleTitle {
    color: $base-dark;
    margin-bottom: 32px;
    font-weight: 400;
}

.cost {
    font-size: 19px;
    color: #334059;
    font-weight: 500;
}

.protocol {
    font-size: 19px;
    font-weight: 500;
    color: #334059;
}

.marginT24 {
    margin-top: 24px!important;
}

.mainStyleSection {
    margin-top: 24px;
}

.graySeparator {
    border: none;
    color: $grey-border;
    background-color: $grey-border;
    height: 1px;
    margin: 0;
}

.clipText {
    display: inline!important;
    white-space: nowrap!important;
    overflow: hidden!important;
    text-overflow: ellipsis!important;
}

.inactiveElement {
    color: $grey-main-light!important;

    .edition__value{
        color: $grey-main-light!important;
    }
}

    .padLeft25 {
        padding-left: 25px !important;
    }

    .padRight25 {
        padding-right: 25px !important;
    }

    .padBottom25 {
        padding-bottom: 25px !important;
    }

.blockFilesTabDocs {
    padding-left: 33px;
}

.section {
    &__title {
        font-size: 13px;
        color: $grey-main-light;
        display: block;
        margin-bottom: 4px;
        line-height: 20px;

        &_line {
            white-space: nowrap;
        }
    }

    &__notInformation{
        color: $grey-main-light;
        @extend .text-notInformation;
    }

    &__attrib {
        font-size: 13px;
        color: $grey-main-light;
        margin-bottom: 5px;
    }

    &__value {
        font-size: 14px;
        color: $base-dark;
    }

    &__info, &__title {
        display: block;
        max-width: 745px;
    }

    &__infoBig {
        display: block;
        font-size: 24px;
    }

    &__sub-title {
        display: block;
        max-width: 745px;
        font-size: 18px;
        margin-bottom: 10px;
    }

    &__info .phoneNumbers {
        display: block;
    }

    &__info {
        font-size: 16px;
        line-height: 20px;
    }

    &__important-note {
        font-size: 13px;
        font-weight: bold;
        color: $grey-main-light;
        margin-bottom: 5px;
    }
}

.lots{
    &-block{
        padding: 38px 0 0 0;

        &-title{
            padding-bottom: 40px;
        }
    }

    &-content{
        display: none;
    }
}

.blockInfo {
    padding: 40px 0;

    &__mb24 {
        margin-bottom: 24px;
    }

    &__title {
        @extend .heading-h2;
        @extend .mainStyleTitle;
        &-print {
            display: flex;
            flex-direction: column;
        }

        &_sub{
            @extend .heading-h3;
        }
    }


    &__section {
        @extend .mainStyleSection;
    }

    &__table {
        margin-top: 24px;
    }
    &__box {
        margin-bottom: 32px;
    }

    .graySeparator.mtb-3{
        @extend .mt-3;
        @extend .mb-3;
    }
}

.rel-links a:not(:last-child) {
    margin-bottom: 8px;
    display: block;
}

.fragment {
    padding-top: 32px;
    padding-bottom: 24px;

    &__title-h3 {
        @extend .heading-h3;
    }

    &__title {
        @extend .heading-h3;
        @extend .mainStyleTitle;
    }

    &__section {
        @extend .mainStyleSection;
    }
}

.separator {
    border-bottom: 1px solid $grey-border;
}

.tableBlock {
    width: 100%;
    border-bottom: 1px solid #E4E7F2;

    &__row {
        border-top: 1px solid #E4E7F2;

        &_disabled {
            color: $grey-main-light;
        }
    }
    &_line{
        display: none;
        border-top: none;
    }
    &__col {
        padding: 16px 8px 16px 16px;
        max-width: 391px;
        vertical-align: baseline;

        &_header {
            color: #909EBB;
            font-weight: normal;
            text-transform: uppercase;
            font-size: 10px;
            letter-spacing: 0.1em;
        }

        &_center {
            text-align: center;
        }

        &_right {
            text-align: right;
            vertical-align: baseline;
        }

        &_first {
            padding-left: 16px;
        }

        &_last, &:last-child {
            padding-right: 16px;
        }

        &_noPaddingBottom {
            padding-bottom: 2px!important;
        }

        &_noPaddingTop {
            padding-top: 2px!important;
        }

        &_noPaddingRight {
            padding-right: 0;
        }

        &_noPaddingLeft {
            padding-left: 0;
        }
        &_left{
            text-align: left;
        }
    }

    &__body, &__foot {
        font-size: 14px!important;
    }

    &__resultTitle {
        font-size: 13px;
        color: #909EBB;
    }

    &__scroll {
        max-height: 320px;
        overflow-x: auto;
    }

    &__scroll70px {
        max-height: 70px;
        overflow-x: auto;
    }

    &__resultValue {
        font-size: 19px;
        font-weight: 500;
    }
}


.collapse {
    &__title {
        display: -webkit-box;
        cursor: pointer;
        display: flex;
        align-items: baseline;

        &_icon {
            display: block;
            margin-right: 20px;
        }

        &_text {
            font-size: 26px;
            font-weight: 400;
            display: block;
        }

        &_opened > .icon {
            transform: rotate(90deg);
        }

        &_lots {
            font-size: 18px;
        }
    }

    &__content {
        margin-top: 40px;
    }
}

.tabsNav {
    padding-top: 40px;

    &__item {
        color:  $base-dark;
        font-size: 12px;
        padding-bottom: .7rem;
        margin-right: 2rem;
        cursor: pointer;
        border-bottom: 2px solid $transparent;
        display: block;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 0.05em;
        line-height: 16px;
        text-align: center;
        &:last-child{
            margin-right: 0;
        }

        &_active, &:hover {
            color: $prime-color;
            border-bottom: 2px solid $borderActive;
        }
    }
    &-media-mr-auto{
        .tabsNav__item{
            @media (max-width: 1140px) {
                margin-right: auto;
            }
        }
    }

    .ui-tabs-active {
        .tabsNav__item {
            color: $prime-color;
            border-bottom: 2px solid $borderActive;
        }
    }
}

.tabEmptyNav {
    height: 35px;
    @extend .tabsNav;
}

.customer-report{
    .tabsNav{
        &__item {
            margin-right: 1.9rem;
        }
    }
}

.table {
    border-collapse: collapse;
    width: 100%;

    &__cell{
        color: #334059;
        font-size: 14px;
        line-height: 20px;
        text-align: left;
        border-top: 1px solid #E4E7F2;
        border-bottom: 1px solid #E4E7F2;

        &-header{
            padding: 16px 8px 16px 16px;
            line-height: 16px;
            font-size: 10px;
            font-weight: normal;
            text-transform: uppercase;
            letter-spacing: 0.1em;
            color: #909EBB;
        }

        &-header:first-child {
            width: 18%;
        }

        &-body{
            padding: 16px 8px 16px 16px;
        }
    }

    &__link{
        height: 40px;
        top: 68px;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #0065DD;
    }
}

.tabBoxWrapper {
    padding: 40px 0;
}
.tabBoxWrapper__mb24 {
    margin-bottom: 24px;
}

.btn{
    &_click{
        cursor: pointer;
    }
}

.tableBlockLine{
    display: none;
    border-top: none;
}

.btn-open{
    transform: rotate(90deg);
}

.revisionToggleWrapper {
    display: block;
    float: none;
}

.f-check{
    background: #0065DD;
    border-radius: 2px;
}

.figma-t{
    padding: 15px 0;
    margin-left: 10px;
}

.clear-1 {
    margin: 10px 0;
    border-bottom: 1px solid #E4E7F2;
    border-top: 1px solid #E4E7F2;

}
.clear-2 {
    border-bottom: 1px solid #E4E7F2;
    padding: 20px 0;
}
.element-box{
    margin: 15px 0;
}
.b-r {
    border-right: 1px solid #E4E7F2;
}

#revisionViewToggle,
#attachmentViewToggle a {
    font-size: 14px;
    line-height: 16px;
    font-weight: normal;
    display: inline-block;
    text-decoration: none;
    border-bottom: none;

}

.img-col{
    width: auto;
    margin: 5px 5px;
    float: left;
}

.el-tablo .centered {
    margin: 15px 0;
}

.label-label{
    position: relative;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: $base-dark;
    text-align: left;
    margin-bottom: 10px;
}
.value{
    width: auto;
    position: relative;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    color: #909EBB;
}

.count{
    position: relative;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #334059;
}
.count span{
    position: relative;
    font-size: 14px;
    line-height: 20px;
    color: #909EBB;
    padding-left: 10px;
}
.centered-header{
    margin-top: 40px;
    //padding: 10px 0;
    h2 {
    font-style: normal;
    font-weight: normal;
    text-transform: unset;
    font-size: 26px;
    line-height: 32px;
    color: #334059;
        margin: 15px 0;
}}

.card {
    &-edition {
        &-container{
            padding: 0 0 28px 0;
        }
        &__block {
            @extend .b-top;

            .title {
                @extend .heading-h2;
                padding: 40px 0 32px 0;
                color: $base-dark;
            }

            .edition {
                padding: 0 0 32px 0;
                &__text {
                    @extend .text-base-min;
                    color: $grey-main-light;
                }

                &__value {
                    @extend .text-base-mid;
                    color: $base-dark;
                }

                &.no_valid{
                    .edition__value {
                        color: $grey-main-light;
                    }
                }
            }
        }
    }
    &-switch{
        padding: 38px 0;
        .slider-text{
            @extend .text-base-min;
            color: $base-dark;
        }
    }
}

.bigger{
    float: right;
}

.inactive-order-info .label-label,
.inactive-order-info .count{
    color: $grey-main-light;
}

.cardWrapper {
    .switch {
        display: inline-block;
        cursor: pointer;
    }

    .switch .inactive-redaction-toggle {
        opacity: 0;
        width: 0;
        height: 0;
        display: none;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
        margin-right: 16px;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 10px;
        width: 10px;
        left: 3px;
        bottom: 3px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

    .inactive-redaction-toggle:checked + .slider {
        background-color: $prime-color;
    }

    .inactive-redaction-toggle:focus + .slider {
        box-shadow: 0 0 1px $prime-color;
    }

    .inactive-redaction-toggle:checked + .slider:before {
        -webkit-transform: translateX(16px);
        -ms-transform: translateX(16px);
        transform: translateX(16px);
    }

    .slider.round {
        border-radius: 34px;
        width: 32px;
        height: 16px;
        position: relative;
        display: inline-block;
        vertical-align: middle;
    }

    .slider.round:before {
        border-radius: 50%;
    }
}

.grayBorderBottom {
    border-bottom: 1px solid #EFF0F2;
}

.icon {
    display: inline-block;

    &-big {
        width: 40px;
        height: 40px;
    }

    &-cryptoSign {
        background: url(/epz/static/img/icons/icon_key_blue.svg);
    }
}

.signsListPopup {
    &-head {
        font-size: 13px;
        color: #909ebb;
    }
    &-body {
        margin-left: 90px;
        margin-top: -41px;
        padding-top: 0;
    }
    .greyBox {
        background: transparent;
    }
    .greyText {
        font-size: 13px;
        color: #334059!important;
    }
    .modal-text-block {
        padding-bottom: 0!important;
    }
    .modal-icon {
        display: block!important;
    }
    .elSignName {
        display: flex;
        padding: 0;
        border: none;
    }
    h3 {
        font-size: 17px;
        text-transform: capitalize;
        color: #334059;
        font-weight: 400;
    }
    .ok {
        background: none!important;
    }
}

.icon {
    display: inline-block;

    &-big {
        width: 40px;
        height: 40px;
    }

    &-cryptoSign {
        background: url(/epz/static/img/icons/icon_key_blue.svg);
    }
}

.signsListPopup {
    &-head {
        font-size: 13px;
        color: #909ebb;
    }
    &-body {
        margin-left: 90px;
        margin-top: -41px;
        padding-top: 0;
    }
    .greyBox {
        background: transparent;
    }
    .greyText {
        font-size: 13px;
        color: #334059!important;
    }
    .modal-text-block {
        padding-bottom: 0!important;
    }
    .modal-icon {
        display: block!important;
    }
    .elSignName {
        display: flex;
        padding: 0;
        border: none;
    }
    h3 {
        font-size: 17px;
        text-transform: capitalize;
        color: #334059;
        font-weight: 400;
    }
    .ok {
        background: none!important;
    }
}

.icon {
    display: inline-block;

    &-big {
        width: 40px;
        height: 40px;
    }
    &-small {
        width: 20px;
        height: 20px;
    }

    &-cryptoSign {
        background: url(/epz/static/img/icons/icon_key_blue.svg);
    }
    &-cryptoSign_small {
        background: url(/epz/static/img/icons/icon_key.svg);
    }
    &-cryptoSignDot_small {
        background: url(/epz/static/img/icons/icon_crypto_dot.svg);
    }
    &-printForm_blue {
        background: url(/epz/static/img/icons/icon_print.svg);
    }
    &-printForm {
        background: url(/epz/static/img/icons/icon_print_small_blue.svg);
    }
}

.excelExportIcon {
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url("/epz/static/img/icons/excelExport.svg");
}

.signsListPopup {
    &-head {
        font-size: 13px;
        color: #909ebb;
    }
    &-body {
        margin-left: 88px;
        margin-top: -31px;
        padding-top: 0!important;
    }
    .greyBox {
        background-color: transparent !important;
    }
    .greyText {
        font-size: 13px;
        color: #334059!important;
    }
    .elSignName {
        display: flex;
        padding: 0!important;
        border: none;
    }
    h3 {
        font-size: 17px;
        text-transform: capitalize!important;
        color: #334059;
        font-weight: 400!important;
    }
    .ok {
        background: none!important;
    }

    .modal-icon {
        display: block!important;
        width: 56px;
        height: 56px;
        background: url(/epz/static/img/icons/icon_sign.svg) no-repeat;
        position: relative;
    }
    .modal-icon-check {
        display: block;
        width: 24px;
        height: 24px;
        background: url(/epz/static/img/icons/icon_check.svg) no-repeat;
        position: absolute;
        right: -7px;
    }
}

.padding-bot-0 {
    padding-bottom: 0;
}

.left-separator {
    padding-left: 16px;
    border-left: 1px solid #DAE4EE;
}

.for-before::before {
    content: '—';
    display: inline-block;
    position: absolute;
    top: 8px;
    left: -21px;
}

.pdnTop0 {
    padding-top: 0 !important;
}

.cardLayout{
    .override-btn-search {
        background: none;
        padding-left: 15px !important;
        line-height: 1.25;
    }
}

.reset-form {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
}

.notActual{
    color: #909ebb;
}

div.headVal, div.textVal, div.extPartVal {
    padding: 10px 0;
}
.user-avtoriz-btn {
    background: #0065DD!important;
    border-radius: 3px;
    border: 1px solid #0065DD;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #FFFFFF!important;
    min-width: 144px;
    height: 40px;
    margin-left: 15px;
}


.darkGreyText {
    color: #334059!important;
}
.italic{
    font-style: italic;
}

.inactiveToggle {
    padding: 40px 0 30px 0;
}

.icon {
    display: inline-block;
    justify-content: center;

    &-rss {
        background: url(/epz/static/img/icons/icon_rss.svg);
        width: 40px;
        height: 40px;
    }

    &-arrow-left {
        background: url(/epz/static/img/icons/arrow_left.svg);
        width: 18px;
        height: 18px;
    }

    &-arrow-small{
        background: url(/epz/static/img/icons/icon_arrow_region.svg);
        width: 4px;
        height: 6px;
        transform: rotate(-90deg);
    }
}

.chevronRight {
    display: inline-block;
    background: url(/epz/static/img/icons/chevron.svg);
    cursor: pointer;
    width: 18px;
    min-width: 18px;
    height: 18px;
    min-height: 18px;
    vertical-align: text-bottom;
}

.chevronDow {
    @extend .chevronRight;
    transform: rotate(90deg);
}

.chevronUp {
    @extend .chevronRight;
    transform: rotate(-90deg);
}

.chevronStage {
    margin-bottom: 7px;
}

.icon-note-contract{
    margin-left: 8px;
    vertical-align: top;
    &:hover{
        cursor: pointer;
    }
}
}

.protocol_border {
    padding: 15px;
    border-bottom: 1px solid #e4e7f2;
}

.row, .clear-2 {
    .protocol_border:nth-last-child(-n+3){
        border: none!important;
    }
}

.bg-none {
    background: none !important;
}
.pageSelect .select {
    padding: 9px 12px;
    width: 100%;
    border: 1px solid #dae4ee;
    border-radius: 3px;
    cursor: pointer;
    outline: 0;
}
.select + .pageSelect__list {
    width: 100%;
    top: 40px;
}

.header-grey-light {
    color: $grey-main-light;
}

.width-100px{
    width: 100px;
}

#comments {
    .not-actual-answer * { color: $grey-main-light }
}

.count .timeZoneName {
    color: $mainColorText !important;
    padding: 0 !important;
}

.no-valid .timeZoneName {
    color: $colorMainGray !important;
    padding: 0 !important;
}

.inactive-order-info .timeZoneName {
    color: $colorMainGray !important;
    padding: 0 !important;
}

.file-number {
    margin: 0 0 0 62px;
    padding: 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    color: #909EBB;
}

.numbered-section {
    margin-top: 30px;
}

.numbered-section__number {
    width: 25px;
    float: left;
    color: $grey-main-light;
    font-size: 14px;
}

.numbered-section__text {
    max-width: 695px;
    margin-left: 35px;
    color: $base-dark;
    font-size: 14px;
}

.user-Registration-btn {
    width: 190px;
    min-width: 136px;
    height: 40px;
    line-height: 16px;
    font-size: 12px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #fff;
    border: 1px solid #0065DD;
    box-sizing: border-box;
    border-radius: 3px;
    background-color: #0065DD;
    cursor: pointer;
}

.lineHeight24{
    line-height:24px !important;
}

.margLeft3{
    margin-left: 3px;
}

.margRight40 {
    margin-right: 40px;
    font-size: 16px;
    height: 20px;
}

.margTop24 {
    margin-top: 24px;
}

.margBottom34 {
    margin-bottom: 34px;
}

.margBottomN56 {
    margin-bottom: -56px;
}

.padLeft30 {
    padding-left: 30px;
}

.padBottom16{
    padding-bottom: 16px;
}

.padLeft37 {
    padding-left: 37px;
}

.padTop0{
    padding-top: 0px;
}

.borderTop{
    border-top: 1px solid #dae4ee;
}

.padBottom32{
    padding-bottom: 32px;
}

.margRightCustom4 {
    margin-right: 4px;
    color: #909EBB;
    font-size: 16px;
    height: 20px;
}

.padTop24 {
    padding-top: 24px;
}

.padTop40 {
    padding-top: 40px;
}

.padBottom20 {
    padding-bottom: 20px!important;
}

.margRightCustomFirst4 {
    margin-left: 15px;
    margin-right: 4px;
    color: #909EBB;
    font-size: 16px;
    height: 20px;
}

.tableArrowPadding {
    padding-left: 15px;
    padding-right: 22px;
}

.customBlock{
    padding-top: 2.5rem;
    padding-bottom: 22px;
    border-top: 0;
}

.no-info-card {
    @extend .text-base-min;
    color: $base-dark;
    font-style: italic !important;
}

.attachment-223{
    padding-bottom: 44px !important;
    padding-top: 0 !important;
    border-top: 0 !important;
}

.customAttachBlock223{
    padding-top: 40px;
    padding-bottom: 36px;
    border-top: 0;
}
