.checkbox-search, .checkbox-custom {
    position: absolute; // take it out of document flow
    opacity: 0; // hide it

    & + label {
        position: relative;
        cursor: pointer;
        // padding: 0;
        padding-left: 26px;
        @extend .text-base-min;
    }

    // Box.
    & + label:before {
        content: '';
        margin-right: 10px;
        display: block;
        vertical-align: text-top;
        width: 16px;
        height: 16px;
        background: #fff;
        border-radius: 2px;
        border: 1px solid $grey-main-extra-light;
        position: absolute;
        top: 2px;
        left: 0px;
    }

    // Box hover
    &:hover + label:before {
        // background: $prime-color;
    }

    // Box focus
    &:focus + label:before {
        // box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }

    // Box checked
    &:checked + label:before {
        background: $prime-color;
        border: none;
    }

    // Disabled state label.
    &:disabled + label {
        color: #b8b8b8;
        cursor: auto;
    }

    // Disabled box.
    &:disabled + label:before {
        box-shadow: none;
        background: #ddd;
    }

    // Checkmark. Could be replaced with an image
    &:checked + label:after {
        content: '';
        position: absolute;
        left: 3px;
        top: 9px;
        background: white;
        width: 1.5px;
        height: 1.5px;
        box-shadow:
        1px 0 0 white,
        2px 0 0 white,
        3px 0 0 white,
        4px 0 0 white,
        4px -1px 0 white,
        4px -2px 0 white,
        4px -3px 0 white,
        4px -4px 0 white,
        4px -5px 0 white,
        4px -6px 0 white,
        4px -7px 0 white,
        4px -8px 0 white,
        4px -9px 0 white;
        transform: rotate(45deg);
    }
}

.customCheckbox {
    input[type="checkbox"] + label:before {
        content: '';
        margin-right: 10px;
        display: block;
        vertical-align: text-top;
        width: 16px;
        height: 16px;
        background: #fff;
        border-radius: 2px;
        border: 1px solid $grey-main-extra-light;
        position: absolute;
        top: 2px;
        left: 0px;
    }
}

.padding-left-on-checkbox {
    padding-left: 26px;
}

.customCheckbox {
    input[type="checkbox"] + label {
        position: relative;
        cursor: pointer;
        padding-left: 26px !important;
    }
}

.manySelect{
  .customCheckbox {
    input[type="checkbox"] {
      position: fixed!important;
      opacity: 0;
    }
  }
}

.customCheckbox {
  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
  }
}

.customCheckbox {
    input[type="checkbox"]:checked + label:after {
        content: '';
        position: absolute;
        left: 3px;
        top: 9px;
        background: #fff;
        width: 1.5px;
        height: 1.5px;
        box-shadow:
        1px 0 0 white,
        2px 0 0 white,
        3px 0 0 white,
        4px 0 0 white,
        4px -1px 0 white,
        4px -2px 0 white,
        4px -3px 0 white,
        4px -4px 0 white,
        4px -5px 0 white,
        4px -6px 0 white,
        4px -7px 0 white,
        4px -8px 0 white,
        4px -9px 0 white;
        transform: rotate(45deg);
    }
}

.customCheckbox {
    input[type="checkbox"]:checked + label:before {
        background: $prime-color;
        border: none;
    }
}
.search-quick{
    @extend .horizontal-component;
    .horizontal-component{
        .many-checkbox {
            display: flex;
            flex-flow: column;
        }
    }
}
.horizontal-component{
    .many-checkbox{
        .search-group.checkbox-list{
            @extend .pl-0;
            padding-right: 30px;
            .description{
                display: flex;
                flex-flow: column;
                padding-left: 26px;
            }
        }
    }
}
