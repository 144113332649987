.modal-content {
    &-info {
        .struct-block {
            &-title {
                @extend .text-base-min;
                color: $base-dark;
                margin-bottom: 16px;

            }
            &-value {
                padding-left: 53px;
                padding-right: 44px;
            }
            &-label{
                padding-left: 23px;
            }
            &-info {
                margin-top: 32px;
                height: 210px;
                @include trans();
                .temp-text {
                    @extend .text-base-min;
                    color: $grey-main-light;
                    text-align: center;
                    padding-top: 65px;
                    @include trans();
                }
            }
            &__unit {
                .title{
                    font-style: normal;
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 24px;
                    text-align: center;
                    margin-top: rem-local(23);
                    margin-bottom: rem-local(24);
                    color: $base-dark;
                    @extend .noselect;
                    @include trans();
                    &:hover {
                        cursor: pointer;
                        color: $prime-color;
                    }
                }
                .sub-title {
                    @extend .text-base-min;
                    color: $grey-main-light;
                    text-align: center;
                    margin: rem-local(10) 0;
                    @include trans();
                }
                &--year{
                    width: 41px;
                    margin-right: 25px;
                }
                &--ikz{
                    width: 401px;
                    margin-right: 25px;
                }
                &--pz{
                    width: 81px;
                    margin-right: 23px;
                }
                &--pgz{
                    width: 61px;
                    margin-right: 24px;
                }
                &--ktru{
                    width: 81px;
                    margin-right: 25px;
                }
                &--kvr{
                    width: 60px;
                }
                &--code{
                    width: 215px;
                    margin-right: 25px;
                }
                &--dir-pos-number{
                    width: 81px;
                }
                &.active {
                    .sub-title{
                        color: $base-dark;
                    }
                    .brace-top,.brace-bottom{
                        border-color: $base-dark !important;
                        &:before {
                            color: $base-dark !important;
                        }
                    }
                    .title {
                        font-size: 36px;
                        color: $prime-color !important;
                    }

                }
            }
            &__label {
                &-top--year{
                    width: 99px;
                }
                &-top--ikz{
                    width: 400px;
                }
                &-top--pz{
                    width: 107px;
                }
                &-top--pgz{
                    width: 104px;
                }
                &-top--ktru{
                    width: 91px;
                }
                &-top--kvr{
                    width: 97px;
                }
                &-top--code{
                    width: 230px;
                }
                &-top--dir-pos-number{
                    width: 150px;
                }
                &-bottom--year{
                    width: 108px;
                }
                &-bottom--ikz{
                    width: 407px;
                }
                &-bottom--pz{
                    width: 101px;
                }
                &-bottom--pgz{
                    width: 74px;
                }
                &-bottom--ktru{
                    width: 129px;
                }
                &-bottom--kvr{

                }
                &-bottom--code{
                    width: 215px;
                }
                &-bottom--dir-pos-number{
                    width: 120px;
                }
            }
        }
    }
}
