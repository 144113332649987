@function em($size, $context: 16)
  @return ($size/$context) * 1em

@function rem-local($size, $context: 16)
  @return ($size/$context) * 1rem

@function img-url($image)
  @return url('/epz/static/img/#{$image}')

=image($image)
  background-image: img-url($image)

=trans($what: all, $dur: 0.2s, $easing: ease)
  transition: $what $dur $easing

=smooth-scroll
  -webkit-overflow-scrolling: touch

=smooth-font
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

=gpu
  transform: translate3d(0,0,0)

=md
  +r(1023)
    @content

=sm
  +r(767)
    @content

=xs
  +r(599)
    @content
