.content {
    &-quiz-head-block {
        @extend .content-search-site-map;
    }

    &-quiz-table-block {
        padding-top: 40px;
        padding-bottom: 64px;

        .quiz {
            margin: 0 0 32px 0;
            border: 1px solid $grey-border;
            box-shadow: 0 15px 15px -10px $grey-super-light;
            border-radius: 3px;
            &-content {
                .title_block {
                    @extend .heading-h3;
                    color: $base-dark;
                    padding: 24px 24px 48px 32px;
                    word-wrap: break-word;
                }

                .time_block {
                    padding: 24px 24px 16px 32px;
                }

                .link_block {
                    .total-quiz {
                        padding: 16px 0 16px 24px;
                    }
                }

                &-text {
                    .quiz-title {
                        @extend .text-base-micro;
                        color: $grey-main-light;
                    }

                    .quiz-value {
                        @extend .text-base-min;
                        color: $base-dark;
                        &-r{
                            @extend .text-base-micro;
                            color: $base-dark;
                        }
                    }

                    .quiz-link {
                        a {
                            @extend .text-cap-base;
                            color: $prime-color;
                            float: right;
                            padding-right: 16px;
                            white-space: nowrap;
                        }
                        padding: 24px 0;
                    }
                }
            }
        }
    }
}
