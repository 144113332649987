//Позволяет запретить выделение текста
.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.error {
    color: $base-error !important;
}

.cursor-pointer{
    cursor: pointer;
}

.base-dark-color {
    color: $base-dark;
}

.grey-main-light {
    color: $grey-main-light;
}

.info-icon {
    background: url("../img/icons/info_icon.svg") 50% no-repeat;
    cursor: pointer;
    display: inline-block;
    height: 14px;
    vertical-align: middle;
    width: 14px;
}

.help-icon {
    background: url("../img/icons/help_icon.svg") 50% no-repeat;
    cursor: pointer;
    display: inline-block;
    height: 16px;
    vertical-align: middle;
    width: 16px;
    &.background-size-small{
        background-size: 12px 12px;
        width: 12px;
        margin-bottom: 2px;
    }
}

.icon-order-group{
    margin: 0 0.3rem;
    width: 12px;
    height: 12px;
}

.icon-key-fill{
    background: url("../img/icons/icon_key_fill.svg") 50% no-repeat;
    cursor: pointer;
    display: inline-block;
    height: 40px;
    width: 40px;
}

.icon-print{
    background: url("../img/icons/icon_print.svg") 50% no-repeat;
    cursor: pointer;
    display: inline-block;
    height: 40px;
    width: 40px;
}

.icon-key{
    background: url("/epz/static/img/icons/icon_key.svg") 50% no-repeat;
    cursor: pointer;
    display: inline-block;
    height: 20px;
    width: 20px;
}

.icon-autopay_background {
    background: url("/epz/static/images/newDesign/autopay.png") 50% no-repeat;
    background-size: 60%;
    display: inline-block;
    height: 96px;
    width: 64px;
}

.icon-autopay-order {
    @extend .icon-autopay_background;
    position: absolute;
    top: -45%;
    left: -8%;
}

.icon-autopay-contract {
    @extend .icon-autopay_background;
    position: absolute;
    top: 60%;
    left: -8%;
}

.separator--slash::before {
    content: '/';
    position: absolute;
    top: 50%;
}

.separator--dash {
    position: relative;
    &::before{
        content: '—';
        display: inline-block;
        position: absolute;
        top: -30px;
        right: 50.5%;
        width: 5px;
        height: 5px;
    }
}
.outline-none{
    outline: none !important;
}

.p-event-none{
    pointer-events: none;
}
