.popUp{
.okdpSearch.createDocBtn span.btn {
    width: inherit;
}

.okdpChoose.createDocBtn span.btn.resetBtn, span.btn {
    width: inherit;
}

.okdpChoose.createDocBtn {
    margin: auto;
    padding: 10px;
}

.btn input[type="button"],
.btn input[type="reset"] {
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    padding: 9px;
    border-radius: 5px;
}

.btn input[type="button"] {
    background-color: #f9794c;
}

.btn input[type="reset"],
.btn input.resetButton {
    background-color: #a0a6ab;
}

.btn input[disabled="disabled"] {
    color: #fff;
}

.okdpChoose .btn input {
    font-size: 14px;
    padding: 10px 15px;
}

.searchBtn {
    background: url("../images/newDesign/white_zoom.png") no-repeat 10px 10px;
    padding-left: 40px;
}

.bigOrangeBtn.searchBtn {
    background-color: #0065dd;
}

.okdpSearch .btnLine {
    margin: 0 -15px -17px;
    border-radius: 0 0 5px 5px;
}



&.popupModalOver input[disabled="disabled"] {
    color: #a0a6ab;
    background-color: #eff0f1;
    opacity: 0.5;
    cursor: default;
}

.modal h1 {
    font-size: 23px;
    padding: 0 20px;
}

.modal h1 {
    text-align: center;
    margin-bottom: 35px;
}

.okdpSearch {
    margin-bottom: 20px;
}

.okdpSearch {
    background-color: #eff0f1;
    padding: 18px 15px;
    border-radius: 5px;
}

.okdpSearch input[type="text"] {
    width: 77%;
    margin-right: 4px;
    padding-right: 10px;
    vertical-align: middle;
}

&.popupModalOver input[type="text"], &.popupModalOver input[type="password"] {
    background-color: #fff;
    border: 2px solid #cecece;
    border-radius: 4px;
    box-sizing: border-box;
    color: #5b5b5b;
    //font-family: 'Roboto Slab', sans-serif;
    font-size: 12px;
    height: 34px;
    line-height: 34px;
    margin: 0;
    padding: 0 0 0 0;
    text-indent: 10px;
    vertical-align: middle;
}

&.popupModalOver ul.dynatreeListContainer {
    white-space: nowrap;
}

&.popupModalOver ul.dynatreeListContainer > li {
    margin-bottom: 5px;
}

&.popupModalOver ul.dynatreeListContainer > li.dynatree-lastsib {
    margin-bottom: 0;
}

&.popupModalOver ul.dynatreeListContainer .dynatree-title {
    margin-left: 8px;
}

.treetableCustom ul.dynatree-container {
    border: none;
    max-height: 300px;
}

.treetableCustom ul.dynatree-container ul {
    padding-left: 0;
}

.treetableCustom ul.dynatree-container li {
    padding-left: 0;
}

.treetableCustom ul.dynatree-container .dynatree-node {
    position: relative;
    display: block;
    overflow: hidden;
}

.treetableCustom ul.dynatree-container .dynatree-node .dynatree-title {
    display: block !important;
}

.treetableCustom ul.dynatree-container li:last-child .dynatree-node a {
    border-bottom: 1px solid #dbe1e6;
}

.treetableCustom ul.dynatree-container .dynatree-node.dynatree-has-children {
    min-height: 24px;
}

.treetableCustom .dynatree-container li {
    background-image: none;
    padding: 0;
}

.treetableCustom .dynatree-container > li {
    background-color: #eff0f1;
    margin-bottom: 3px;
    border-radius: 4px;
}

.treetableCustom span.dynatree-expander {
    background-image: url("../images/newDesign/dynatree-sprite.png");
    width: 24px;
    height: 24px;
    position: absolute;
    top: 10px;
    left: 0;
}

.treetableCustom span.dynatree-expander,
.treetableCustom .dynatree-exp-cl span.dynatree-expander,
.treetableCustom .dynatree-exp-cd span.dynatree-expander,
.treetableCustom .dynatree-exp-cdl span.dynatree-expander {
    background-position: 0 0;
}

.treetableCustom .dynatree-exp-e span.dynatree-expander,
.treetableCustom .dynatree-exp-el span.dynatree-expander,
.treetableCustom .dynatree-exp-ed span.dynatree-expander,
.treetableCustom .dynatree-exp-edl span.dynatree-expander {
    background-position: -25px 0;
}

.treetableCustom ul.dynatree-container .dynatree-has-children > .dynatree-title {
    border-bottom: 1px solid #dbe1e6;
}

.treetableCustom ul.dynatree-container a {
    color: #5f6a74;
    font-weight: normal;
}

.treetableCustom .dynatree-node > a {
    display: inline;
}

.treetableCustom ul.dynatree-container {
    white-space: normal;
}

.treetableCustom .dynatree-connector {
    background-image: none;
    float: left;
}

.treetableCustom .dynatree-connector:after {
    content: "—";
    position: absolute;
    top: 6px;
    left: 0;
    display: inline;
    font-size: 12px;
}

.treetableCustom ul.dynatree-container .dynatree-node > .dynatree-title {
    display: block;
    color: #5f6a74;
    font-weight: normal;
    font-size: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-left: -10px;
}

.treetableCustom ul.dynatree-container .dynatree-node.dynatree-has-children > .dynatree-title {
    margin-left: 0;
    padding-top: 13px;
    min-height: 24px;
}

.treetableCustom ul.dynatree-container .dynatree-node > .dynatree-checkbox + .dynatree-title {
    margin-left: 0;
}

.treetableCustom li .dynatree-node .dynatree-title {
    padding-left: 45px;
}

.treetableCustom li li .dynatree-node .dynatree-title {
    padding-left: 75px;
}

.treetableCustom li li li .dynatree-node .dynatree-title {
    padding-left: 105px;
}

.treetableCustom li li li li .dynatree-node .dynatree-title {
    padding-left: 135px;
}

.treetableCustom li li li li li .dynatree-node .dynatree-title {
    padding-left: 165px;
}

.treetableCustom li li li li li li .dynatree-node .dynatree-title {
    padding-left: 195px;
}

.treetableCustom li li li li li li li .dynatree-node .dynatree-title {
    padding-left: 225px;
}

.treetableCustom li li li li li li li li .dynatree-node .dynatree-title {
    padding-left: 255px;
}

.treetableCustom li li li li li li li li li .dynatree-node .dynatree-title {
    padding-left: 285px;
}

.treetableCustom li .dynatree-node .dynatree-checkbox + .dynatree-title {
    padding-left: 70px;
}

.treetableCustom li li .dynatree-node .dynatree-checkbox + .dynatree-title {
    padding-left: 100px;
}

.workTypeTreeTable li li .dynatree-node .dynatree-checkbox + .dynatree-title {
    padding-left: 45px;
}

.treetableCustom li li li .dynatree-node .dynatree-checkbox + .dynatree-title {
    padding-left: 130px;
}

.treetableCustom li li li li .dynatree-node .dynatree-checkbox + .dynatree-title {
    padding-left: 160px;
}

.treetableCustom li li li li li .dynatree-node .dynatree-checkbox + .dynatree-title {
    padding-left: 190px;
}

.treetableCustom li li li li li li .dynatree-node .dynatree-checkbox + .dynatree-title {
    padding-left: 220px;
}

.treetableCustom li li li li li li li .dynatree-node .dynatree-checkbox + .dynatree-title {
    padding-left: 250px;
}

.treetableCustom li li li li li li li li .dynatree-node .dynatree-checkbox + .dynatree-title {
    padding-left: 280px;
}

.treetableCustom li li li li li li li li li .dynatree-node .dynatree-checkbox + .dynatree-title {
    padding-left: 310px;
}

.treetableCustom li .dynatree-node .dynatree-expander {
    left: 15px;
}

.treetableCustom li li .dynatree-node .dynatree-expander {
    left: 45px;
}

.treetableCustom li li li .dynatree-node .dynatree-expander {
    left: 73px;
}

.treetableCustom li li li li .dynatree-node .dynatree-expander {
    left: 100px;
}

.treetableCustom li li li li li .dynatree-node .dynatree-expander {
    left: 135px;
}

.treetableCustom li li li li li li .dynatree-node .dynatree-expander {
    left: 165px;
}

.treetableCustom li li li li li li li .dynatree-node .dynatree-expander {
    left: 195px;
}

.treetableCustom li li li li li li li li .dynatree-node .dynatree-expander {
    left: 225px;
}

.treetableCustom li li li li li li li li li .dynatree-node .dynatree-expander {
    left: 255px;
}

.treetableCustom li .dynatree-node .dynatree-connector:after {
    left: 15px;
}

.treetableCustom li li .dynatree-node .dynatree-connector:after {
    left: 50px;
}

.treetableCustom li li li .dynatree-node .dynatree-connector:after {
    left: 80px;
}

.treetableCustom li li li li .dynatree-node .dynatree-connector:after {
    left: 105px;
}

.treetableCustom li li li li li .dynatree-node .dynatree-connector:after {
    left: 135px;
}

.treetableCustom li li li li li li .dynatree-node .dynatree-connector:after {
    left: 165px;
}

.treetableCustom li li li li li li li .dynatree-node .dynatree-connector:after {
    left: 195px;
}

.treetableCustom li li li li li li li li .dynatree-node .dynatree-connector:after {
    left: 225px;
}

.treetableCustom li li li li li li li li li .dynatree-node .dynatree-connector:after {
    left: 255px;
}

.treetableCustom li .dynatree-node > .dynatree-checkbox {left: 40px;}
.treetableCustom li li .dynatree-node > .dynatree-checkbox {left: 70px;}
.treetableCustom li li li .dynatree-node > .dynatree-checkbox {left: 100px;}
.treetableCustom li li li li .dynatree-node > .dynatree-checkbox {left: 130px;}
.treetableCustom li li li li li .dynatree-node > .dynatree-checkbox {left: 160px;}
.treetableCustom li li li li li li .dynatree-node > .dynatree-checkbox {left: 190px;}
.treetableCustom li li li li li li li .dynatree-node > .dynatree-checkbox {left: 220px;}
.treetableCustom li li li li li li li li .dynatree-node > .dynatree-checkbox {left: 250px;}
.treetableCustom li li li li li li li li li .dynatree-node > .dynatree-checkbox {left: 280px;}

.treetableCustom li .dynatree-node.dynatree-has-children > .dynatree-checkbox {left: 40px;}
.treetableCustom li li .dynatree-node.dynatree-has-children > .dynatree-checkbox {left: 70px;}
.treetableCustom li li li .dynatree-node.dynatree-has-children > .dynatree-checkbox {left: 100px;}
.treetableCustom li li li li .dynatree-node.dynatree-has-children > .dynatree-checkbox {left: 130px;}
.treetableCustom li li li li li .dynatree-node.dynatree-has-children > .dynatree-checkbox {left: 160px;}
.treetableCustom li li li li li li .dynatree-node.dynatree-has-children > .dynatree-checkbox {left: 190px;}
.treetableCustom li li li li li li li .dynatree-node.dynatree-has-children > .dynatree-checkbox {left: 220px;}
.treetableCustom li li li li li li li li .dynatree-node.dynatree-has-children > .dynatree-checkbox {left: 250px;}
.treetableCustom li li li li li li li li li .dynatree-node.dynatree-has-children > .dynatree-checkbox {left: 280px;}

.treetableCustom li .dynatree-node > .code {
    left: 45px;
}

.treetableCustom li li .dynatree-node > .code {
    left: 70px;
}

.treetableCustom li li li .dynatree-node > .code {
    left: 100px;
}

.treetableCustom li li li li .dynatree-node > .code {
    left: 125px;
}

.treetableCustom li li li li li .dynatree-node > .code {
    left: 150px;
}

.treetableCustom li li li li li li .dynatree-node > .code {
    left: 195px;
}

.treetableCustom li li li li li li li .dynatree-node > .code {
    left: 210px;
}

.treetableCustom li li li li li li li li .dynatree-node > .code {
    left: 240px;
}

.treetableCustom li li li li li li li li li .dynatree-node > .code {
    left: 270px;
}

.treetableCustom li .dynatree-node.dynatree-has-children > .code {
    left: 40px;
}

.treetableCustom li li .dynatree-node.dynatree-has-children > .code {
    left: 70px;
}

.treetableCustom li li li .dynatree-node.dynatree-has-children > .code {
    left: 100px;
}

.treetableCustom li li li li .dynatree-node.dynatree-has-children > .code {
    left: 130px;
}

.treetableCustom li li li li li .dynatree-node.dynatree-has-children > .code {
    left: 160px;
}

.treetableCustom li li li li li li .dynatree-node.dynatree-has-children > .code {
    left: 190px;
}

.treetableCustom li li li li li li li .dynatree-node.dynatree-has-children > .code {
    left: 220px;
}

.treetableCustom li li li li li li li li .dynatree-node.dynatree-has-children > .code {
    left: 250px;
}

.treetableCustom li li li li li li li li li .dynatree-node.dynatree-has-children > .code {
    left: 280px;
}

.treetableCustom li .dynatree-node .code + .dynatree-title {
    padding-left: 140px;
}

.treetableCustom li li .dynatree-node .code + .dynatree-title {
    padding-left: 175px;
}

.treetableCustom li li li .dynatree-node .code + .dynatree-title {
    padding-left: 210px;
}

.treetableCustom li li li li .dynatree-node .code + .dynatree-title {
    padding-left: 245px;
}

.treetableCustom li li li li li .dynatree-node .code + .dynatree-title {
    padding-left: 280px;
}

.treetableCustom li li li li li li .dynatree-node .code + .dynatree-title {
    padding-left: 320px;
}

.treetableCustom li li li li li li li .dynatree-node .code + .dynatree-title {
    padding-left: 355px;
}

.treetableCustom li li li li li li li li .dynatree-node .code + .dynatree-title {
    padding-left: 380px;
}

.treetableCustom li li li li li li li li li .dynatree-node .code + .dynatree-title {
    padding-left: 415px;
}

.treetableCustom .dynatree-node > .code {
    position: absolute;
    top: 8px;
}

.treetableCustom .dynatree-node.dynatree-has-children > .code {
    top: 12px;
}

.treetableCustom .dynatree-node > .dynatree-checkbox {
    position: absolute;
    top: 8px;
}

.treetableCustom .dynatree-node.dynatree-has-children > .dynatree-checkbox {
    top: 12px;
}

.treetableCustom li .dynatree-node a.dynatree-title:hover,
.treetableCustom li .dynatree-active a.dynatree-title,
.treetableCustom li .dynatree-selected .dynatree-title,
.treetableCustom li .dynatree-selected .dynatree-title .dynatree-subtitle {
    background-color: #fdd7c9 !important;
    color: #d0562b !important;
    text-decoration: none !important;
}

.treetableCustom li .dynatree-node .dynatree-title:focus {
    background-color: inherit;
    color: inherit;
}

.treetableCustom ul.dynatree-container .dynatree-title:hover {
    color: inherit;
}

span.dynatree-checkbox, label.dynatree-checkbox {
    width: 20px !important;
    height: 20px !important;
    vertical-align: top;
    background-repeat: no-repeat;
    background-image: url("../images/newDesign/custom_check.png") !important;
    background-position: 0 100% !important;
    margin-left: 5px !important;
}

.dynatree-selected span.dynatree-checkbox, label.dynatree-checkbox-selected {
    background-position: 0 0 !important;
}

.width840 {
    width: 840px;
}

.width870 {
    width: 870px;
}

.font23 {
    font-size: 23px;
}

.choiceParametrsTbl td {
    padding: 0 0 12px 0;
    vertical-align: top;
}

.choiceParametrsTbl td:first-child {
    padding-top: 8px !important;
    width: 20px;
}

.choiceParametrsTbl td:nth-child(2) {
    line-height: 14px;
    width: 185px;
}

.choiceParametrsTbl tr:first-child td {
    border-bottom: 2px solid #d3d7db;
}

.choiceParametrsTbl tr.newParamRow td {
    padding: 12px 0 0;
}

.listChoiceParametrs > li {
    padding-bottom: 10px;
}

.listChoiceParametrs > li.btnLineItem, .listChoiceParametrs > li.editLineBtn, .listChoiceParametrs > li.editLineField {
    padding-bottom: 0;
}

.treetableOkdp ul.dynatree-container > li > .dynatree-node > .dynatree-title {
    text-transform: uppercase;
    font-weight: bold;
    min-height: 14px;
}

.treetableCustom .dynatree-title > .dynatree-subtitle {
    color: #a4abb3;
}

ul.ui-autocomplete {
    border: 2px solid #cecece;
    border-top: none;
    border-radius: 0 0 5px 5px;
    background-color: #fff;
    z-index: 10000;
}

a.ui-corner-all {
    display: block;
    padding: 8px 10px;
    border-bottom: 1px solid #cecece;
    text-decoration: none;
    color: #666a6f;
    cursor: pointer;
}

ul.ui-widget-content li:hover {
    background-color: #eee;
}

&.popupModalOver ul.ui-autocomplete {
    border: 2px solid #cecece;
    border-top: none;
    border-radius: 0 0 5px 5px;
    background-color: #fff;
}

&.popupModalOver a.ui-corner-all {
    display: block;
    padding: 8px 10px;
    border-bottom: 1px solid #cecece;
    text-decoration: none;
    color: #666a6f;
    cursor: pointer;
}

&.popupModalOver ul.ui-widget-content li:hover {
    background-color: #eee;
}

.okdpPopUp {
    width: 900px;
    margin-left: -450px;
}
.modal-content.modal-content-extendedSearchParametersPopup{
    width: 900px;
    max-height: 100%;
    top: 3%;
    overflow-y: auto;
    font-size: 13px;
}
.choiceParametrsTbl tr:first-child td {
    border-bottom: 2px solid #d3d7db;
}
.choiceParametrsTbl td:first-child {
    padding-top: 8px !important;
    width: 20px;
}
.choiceParametrsTbl td {
    padding: 0 0 12px 0;
    vertical-align: top;
}
.choiceParametrsTbl td:nth-child(2) {
    line-height: 14px;
    width: 185px;
}
.pseudoInputWarning {
    background: #ffdeda url(../images/newDesign/warning_ico.png) no-repeat 10px 7px;
    border: 2px solid #f1c9c5;
    border-radius: 4px;
    color: #e2351e;
    cursor: pointer;
    font-size: 12px;
    min-height: 20px;
    padding: 0 0 0 30px;
}
.pseudoInputS.height30, .pseudoInputWarning.height30 {
    height: 28px;
    line-height: 28px;
}
#modal-extendedSearchParametersPopup table{
    border-spacing: 0;
    color: #5b5b5b;
    padding: 0;
    width: 100%;
}
&.popupModalOver ul.paramNewChoice li.checkbox-poor:not(.display-block) {
    display: inline-block;
    margin-right: 15px;
}
.orange {
    color: #E8523D !important;
}
.orangeSmall {
    color: #E8523D !important;
    font-size: 11px !important;
}
.resultItem {
    background-color: #fff;
    border-radius: 4px;
    color: #41484e;
    display: inline-block;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 4px 8px;
}
.switcherPar span:last-child {
    display: none;
}
.switcherPar span {
    border-bottom: 1px solid #018ccd;
    color: #018ccd;
    cursor: pointer;
}
.brdTop1White {
    border-top: 1px solid #fff;
}
.shortSelected {
    display: none;
    padding: 10px 0;
}
.searchParamsBlock label {
    width: 120px;
}
.vAlignMiddle {
    vertical-align: middle !important;
}
&.popupModalOver input[type="text"], &.popupModalOver input[type="password"] {
    background-color: #fff;
    border: 2px solid #cecece;
    border-radius: 4px;
    box-sizing: border-box;
    color: #5b5b5b;
    //font-family: 'Roboto Slab', sans-serif;
    font-size: 12px;
    height: 34px;
    line-height: 34px;
    margin: 0;
    padding: 0 0 0 0;
    text-indent: 10px;
    vertical-align: middle;
}
.orangeBtn, .greyBtn {
    background-color: #0065dd;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    padding: 6px 10px;
    text-transform: uppercase;
    text-decoration: none;
}
&.popupModalOver input.height30 {
    height: 30px;
    line-height: 24px;
}
&.popupModalOver input.orangeBtn.height30 {
    line-height: 19px;
}
.parametersTabs .checkLow {
    border-bottom: 3px solid #018ccd;
    cursor: default;
    padding-bottom: 5px;
}
.parametersTabs td > span {
    cursor: pointer;
    display: inline-block;
}
.parasmetersAction td {
    padding-right: 10px;
}
.tdHead, .subTdHead {
    font-weight: bold;
    text-transform: uppercase;
}
.addingTbl {
    padding: 5px 17px 17px 17px;
    table-layout: fixed
}
.noticeTabBox .addingTbl td, .addingTbl td {
    background-color: #eff0f1;
    border-top: none;
    color: #5f6a74;
    word-wrap: break-word;
}
.orangeBtn, .greyBtn {
    background-color: #0065dd;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    padding: 6px 10px;
    text-transform: uppercase;
    text-decoration: none;
}
.delimTr, .noticeTabBox .addingTbl .delimTr, .addingTbl .delimTr {
    background-color: #fff !important;
    font-size: 1px;
    height: 3px;
    line-height: 0;
    overflow: hidden;
    padding: 0 !important;
}
.bigOrangeBtn, .bigGreyBtn {
    background-color: #0065dd;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    padding: 11px 15px;
    text-transform: uppercase;
}
.col3 .tdHead td {
     padding: 15px 15px;
 }
.col3 td:first-child, .col3 td:last-child {
    padding: 10px 15px;
}
.col3 .tdHead td:nth-child(2) {
    padding: 15px 0;
}

.msgError {
    background-color: #fafafa;
    color: red;
    font-size: 12px;
    min-height: 16px;
    padding: 0 0 10px 8px;
}
.popUp h2 {
    font-size: 22px;
    margin-bottom: 20px;
}
.noticeTabBox h2 {
    background-color: #eff0f1;
    border: 2px solid #d3d7db;
    border-bottom: none;
    border-radius: 4px 4px 0 0;
    color: #d0562b;
    font-size: 16px;
    padding: 10px 15px;
    text-transform: uppercase;
}
.noticeTabBox .eArrowKtru {
    color: #d0562b;
    background: url(../images/newDesign/expand_arrow_alt.png) no-repeat;
    background-position-y: 5px;
    cursor: pointer;
    float: left;
    margin-top: 5px;
    width: 30px;
    height: 18px;
    background-size: 16px;
}
.eArrowKtru{
    cursor: pointer;
}
.okdpChoose {
    margin-top: 20px;
    text-align: center;
}

.okdpChoose .btn input {
    font-size: 14px;
    padding: 10px 15px;
}

#chooseOrganizationDialogDataHeader {
    table-layout: fixed;
    text-align: left;
}

#chooseOrganizationDialogDataHeader tr {
    border-bottom: 3px solid #fff;
}

.displaytagTable td {
    vertical-align: top;
}

#chooseOrganizationDialogData td {
    padding-right: 0;
}

#chooseOrganizationDialogData td:last-child {
    border-right: 2px solid #fff;
}

#chooseOrganizationDialogData tr {
    border-bottom: 3px solid #fff;
}

#chooseOrganizationDialogData tr:last-child {
    border-bottom: none;
}

#chooseOrganizationTablePaginatorContainer,
.paginatorContainer {
    margin-top: 20px;
    background-color: #eff0f1;
    padding: 15px;
    border-radius: 5px;
}

#chooseOrganizationTablePaginator, #chooseOrganizationTablePaginator ul.paging{
    padding-bottom: 0;
    margin: 0;
    li.allRecords{
        margin-top: 5px;
        margin-right: 5px;
    }
}

#chooseOrganizationTablePaginatorContainer li,
.paginatorContainer li {
    padding: 5px 6px 5px 8px;
    text-align: center;
}

#chooseOrganizationTablePaginatorContainer .allRecords,
.paginatorContainer .allRecords, .tuningQuickSearchToolBox {
    float: right;
    padding: 0;
}

#chooseOrganizationTablePaginatorContainer .paging,
.paginatorContainer .paging {
    text-align: left;
}

#chooseOrganizationTablePaginatorContainer .paging .leftArrow,
.paginatorContainer .paging .leftArrow {
    padding-left: 0;
}

#chooseOrganizationDialogDataHeader th:nth-child(1),
#chooseOrganizationDialogDataContainer td:nth-child(1) {
    width: 16px;
    vertical-align: middle;
}

#chooseOrganizationDialogDataHeader th:nth-child(2),
#chooseOrganizationDialogDataContainer td:nth-child(2) {
    width: 200px;
    max-width: 200px;
}

#chooseOrganizationDialogDataHeader th:nth-child(3),
#chooseOrganizationDialogDataContainer td:nth-child(3) {
    width: 125px;
}

#chooseOrganizationDialogDataHeader th:nth-child(4),
#chooseOrganizationDialogDataContainer td:nth-child(4) {
    width: 110px;
}

#chooseOrganizationDialogDataHeader th:nth-child(5),
#chooseOrganizationDialogDataContainer td:nth-child(5) {
    width: 140px;
}

#chooseOrganizationDialogDataHeader th:nth-child(6),
#chooseOrganizationDialogDataContainer td:nth-child(6) {
    width: 160px;
    max-width: 160px !important;
}

#chooseOrganizationDialogDataContainer tr {
    cursor: pointer;
}

.fz44_94,
.fz223 {
    background: url("/epz/static/images/newDesign/fz-icon.png") no-repeat;
    width: 15px;
    height: 17px;
    display: inline;
    line-height: 20px;
    /*чтобы иконки документов отображались в ie полностью*/
    font-size: 12.5px;
}

.fz223:after,
.fz44_94:after {
    display: inline;
    margin-left: 20px;
}

.fz44_94:after {
    content: "44-ФЗ";
    color: #d0562b;
}

.fz223:after {
    content: "223-ФЗ";
    color: #2a944f;
}

.choiceTableSelectedRow td {
    background-color: #fdd7c9;
}

.choiceTableSelectedRow .fz44_94,
.choiceTableSelectedRow .fz223 {
    background: url("../images/newDesign/FZ-icon-selected.png") no-repeat;
}

.parentCompany #goodssearch {
    width: 100%;
}

.parentCompanyChoice {
    margin-top: 20px;
}

.parentCompanyChoice li {
    margin-bottom: 15px;
}

#chooseExtendedAttributeDialogData th,
#chooseExtendedAttributeDialogData td {
    text-align: left;
}

#chooseExtendedAttributeDialogData td {
    padding: 9px 0 9px 15px;
}

#chooseExtendedAttributeDialogData th:nth-child(3),
#chooseExtendedAttributeDialogData td:nth-child(4) {
    text-align: center;
}

#chooseExtendedAttributeDialogData td:nth-child(2) {
    width: 250px;
}

#chooseExtendedAttributeDialogData td:nth-child(3) {
    width: 80px;
}

#chooseOrganizationDialogDataContainer {
    max-height: 300px !important;
}

.displaytagTable thead th {
    text-transform: uppercase;
    background: #dbe1e6;
    height: 62px;
    padding-left: 15px;
    text-align: left;
}

.displaytagTable.height35 thead th {
    height: 35px;
}

.displaytagTable thead th:first-child {
    border-radius: 0;
}

.displaytagTable thead th:last-child {
    border-radius: 0;
}

.displaytagTable td {
    background-color: #eff0f1;
    padding: 9px 15px;
}

.displaytagTable td {
    vertical-align: top;
}

.displaytagTable tr {
    border-bottom: 3px solid #fff;
}

.displaytagTable thead.custom-padding th {
    padding: 0px 1px 0px 1px;
}

.okdpPopUp {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
}

.modal-content-okdp-okved {
    width: 900px;
    height: auto;
    max-height: 70%;
    overflow-y: auto;
}

&.popupModalOverlay {
    margin-left: -250px;
    position: initial;
    overflow-y: auto;
    z-index: 901;
    width: 650px;
    left: 50%;
    top: 50%;
    background-color: #fff;
    border-radius: 5px;
}
.okdpPopUp {
    width: 900px;
    margin-left: -450px;
}

.popUpWrapper {
    padding: 40px 40px 20px;
}
.modal span.closePopUp {
    cursor: pointer;
}
&.popupModalOverlay h1 {
    font-size: 30px;
    text-transform: uppercase;
}
&.popupModalOverlay .modal h1 {
    text-align: center;
    margin-bottom: 35px;
    word-wrap: break-word !important;
}
&.popupModalOverlay .modal h1 {
    font-size: 23px;
    padding: 0 20px;
}
.modal-content-quiz .modal-header *{
    word-wrap: break-word !important;
}
&.popupModalOverlay input, &.popupModalOverlay textarea, &.popupModalOverlay select, &.popupModalOverlay button {
    cursor: pointer;
    outline: none;
}
&.popupModalOverlay .margBtm5 {
    margin-bottom: 5px !important;
}
&.popupModalOverlay .okdpSearch {
    background-color: #eff0f1;
    padding: 18px 15px;
    border-radius: 5px;
}
&.popupModalOverlay .msgError {
    background-color: #fafafa;
    color: red;
    font-size: 12px;
    min-height: 16px;
    padding: 0 0 10px 8px;
}
&.popupModalOverlay .okdpChoose {
    margin-top: 20px;
    text-align: center;
}
&.popupModalOverlay .btn input[type='button'], &.popupModalOverlay .btn input[type='reset'] {
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    padding: 9px;
    border-radius: 5px;
    line-height: normal;
    letter-spacing: .05em;
}
&.popupModalOverlay .btn input[type='button'] {
    background-color: $prime-color;
    border: 1px solid $prime-color;
}
&.popupModalOverlay .btn input[type='reset'], .btn input.resetButton {
    background-color: #fff;
    border: 1px solid $prime-color;
    color: $prime-color;
}
&.popupModalOverlay .okdpChoose .btn input {
    font-size: 14px;
    padding: 10px 15px;
}
&.popupModalOverlay h2 {
    font-size: 22px;
    text-transform: uppercase;
}
.noticeTabBox h2 {
    background-color: #eff0f1;
    border: 2px solid #d3d7db;
    border-bottom: none;
    border-radius: 4px 4px 0 0;
    color: #d0562b;
    font-size: 16px;
    padding: 10px 15px;
    text-transform: uppercase;
}
.popUp h2 {
    font-size: 22px;
    margin-bottom: 20px;
}
.inlineBlock {
    display: inline-block;
}

.margBot15 {
    margin-bottom: 15px;
}
.toggleGrey {
    color: #aaaaaa;
}
.margLeft3 {
    margin-left: 3px !important;
}
&.popupModalOverlay ol, ul {
    list-style-type: none;
}
.displayNone {
    display: none;
}
.toggleGrey span {
    margin-left: 5px;
    cursor: pointer;
}
.toggleGrey span:first-child {
    margin-left: 0px;
}
.floatNone {
    float: none !important;
}
.noticeTabBox .eArrowKtru {
    color: #d0562b;
    background: url(../images/newDesign/expand_arrow_alt.png) no-repeat;
    background-position-y: 5px;
    cursor: pointer;
    float: left;
    margin-top: 5px;
    width: 30px;
    height: 18px;
    background-size: 16px;
}
.noticeTabBox .cArrowKtru {
    background: url("../images/newDesign/collapse_arrow_alt.png") no-repeat;
    background-position-y: 5px;
    background-size: 16px;
}
&.popupModalOverlay table {
    border-collapse: collapse;
    border-spacing: 0;
    color: #5b5b5b;
    padding: 0;
    width: 100%;
}
.tableLayoutAuto {
    table-layout: auto !important;
}
#chooseOrganizationDialogDataHeader {
    table-layout: fixed;
    margin-top: 20px;
    text-align: left;
}
.wrapperTableHeight {
    max-height: none;
    overflow-y: auto;
    overflow-x: hidden;
}
#chooseOrganizationDialogDataContainer {
    max-height: 300px !important;
}
&.popupModalOverlay .margTop5 {
    margin-top: 5px;
}
&.popupModalOverlay .greyBox {
    background-color: #eff0f1;
    border-radius: 5px;
    padding: 13px 15px;
}
&.popupModalOverlay .allRecords {
    color: #909EBB;
    float: right;
}
&.popupModalOverlay .noticeTabBox p {
    padding-bottom: 10px;
}
&.popupModalOverlay .paging {
    text-align: center;
}
&.popupModalOverlay .paging li {
    display: inline;
    font-size: 12px;
    padding: 3px;
}
&.popupModalOverlay .paging .currentPage {
    background-color: #fff;
    border-radius: 3px;
    color: #d0562b;
    font-weight: bold;
    margin: 0 5px;
    padding: 5px 11px;
}
&.popupModalOverlay a, &.popupModalOverlay a:focus, &.popupModalOverlay img {
    border: none;
    outline: none;
}
&.popupModalOverlay a {
    color: #0065dd;
    text-decoration: none;
}
&.popupModalOverlay .paging li a {
    color: #5f6a74;
}
&.popupModalOverlay .paging .leftArrow a, &.popupModalOverlay .paging .rightArrow a {
    background: url("../images/newDesign/expand_btn_sprite.png") no-repeat 0 -244px;
    padding-right: 15px;
}
&.popupModalOverlay .paging .rightArrow a {
    background-position: 0 -164px;
}
&.popupModalOverlay .noticeTabBox strong {
    text-transform: uppercase;
}
&.popupModalOverlay .allRecords strong {
    color: #41484e;
}
&.popupModalOverlay .displaytagTable tr {
    border-bottom: 3px solid #fff;
}
#chooseOrganizationDialogDataContainer tr {
    cursor: pointer;
}
.width_30_pr {
    width: 30% !important;
}

.width_25_pr {
    width: 25% !important;
}

.positionAbsolute {
    position: absolute;
}
.cursorPointer {
    cursor: pointer;
}
.width-25-pr {
    width: 25% !important;
}
.padTB5px {
    padding: 5px 0 5px 0;
}
.padBot-25-px {
    padding-bottom: 25px;
}
.noticeTabBox td {
    border-top: 1px solid #e1e1e1;
    padding: 9px 15px;
    vertical-align: top;
}
.displaytagTable td {
    background-color: #eff0f1;
    padding: 9px 15px;
}
.displaytagTable td {
    vertical-align: top;
}
.choiceTableSelectedRow td {
    background-color: #fdd7c9;
}
.noticeTabBox tbody td {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}
.noticeTabBox table td {
    color: #7b7b7b;
}
.noticeTabBox table td:first-child {
    color: #5f6a74;
    width: 280px;
}
.noticeTabBox table td:first-child {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    -webkit-border-top-right-radius: 0;
    -webkit-border-bottom-right-radius: 0;
    -moz-border-radius-topright: 0;
    -moz-border-radius-bottomright: 0;
}
.noticeTabBox table td:last-child {
    -webkit-border-top-left-radius: 0;
    -webkit-border-bottom-left-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -moz-border-radius-topleft: 0;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}
.noticeTabBox table tr:last-child td {
    border-bottom: none;
}
.noticeTabBox table thead th:first-child {
    -webkit-border-top-right-radius: 0;
    -webkit-border-bottom-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -moz-border-radius-topright: 0;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}
#chooseOrganizationDialogDataHeader th:nth-child(1), #chooseOrganizationDialogDataContainer td:nth-child(1) {
    width: 16px;
    vertical-align: middle;
}
.displaytagTable thead th:last-child {
    border-radius: 0;
}
#chooseOrganizationDialogDataHeader th:nth-child(2), #chooseOrganizationDialogDataContainer td:nth-child(2) {
    width: 200px;
    max-width: 200px;
}
&.popupModalOverlay .customCheckbox > label {
    width: auto;
}
#chooseOrganizationDialogDataHeader tr {
    border-bottom: 3px solid #fff;
}
&.popupModalOverlay .padLeft40 {
    padding-left: 40px !important;
}
&.popupModalOverlay .padLeft10 {
    padding-left: 10px !important;
}
.noticeTabBox th {
    padding-left: 15px;
    text-align: left;
}
.displaytagTable thead th {
    text-transform: uppercase;
    background: #dbe1e6;
    height: 62px;
    padding-left: 15px;
    text-align: left;
}
.noticeTabBox table thead th {
    height: 62px;
}
.displaytagTable thead th:first-child {
    border-radius: 0;
}
&.popupModalOverlay ul.dynatree-container {
    font-family: tahoma, arial, helvetica;
    font-size: 10pt;
    white-space: nowrap;
    padding: 3px;
    margin: 0;
    background-color: white;
    border: 1px dotted gray;
    overflow: auto;
}
.treetableCustom ul.dynatree-container {
    border: none;
    max-height: 300px;
}
.treetableCustom ul.dynatree-container {
    white-space: normal;
}
.noticeTabBox i {
    color: #909EBB;
    padding-top: 15px;
    font-size: 12px;
    line-height: 35px;
}
.colorValue {
    color: #b4b4b4 !important;
}
&.popupModalOverlay input[type='text'], &.popupModalOverlay input[type='password'] {
    background-color: #fff;
    border: 2px solid #cecece;
    border-radius: 4px;
    box-sizing: border-box;
    color: #5b5b5b;
    //font-family: "Roboto Slab", sans-serif;
    font-size: 12px;
    height: 34px;
    line-height: 34px;
    margin: 0;
    padding: 0 0 0 0;
    text-indent: 10px;
    vertical-align: middle;
}
.okdpSearch input[type='text'] {
    width: 73%;
    margin-right: 4px;
    padding-right: 10px;
    vertical-align: middle;
}
.hidden {
    display: none;
}
.opacity0 {
    opacity: 0;
}
.zIndex2 {
    z-index: 2;
}
.positionAbsoluteImportant {
    position: absolute !important;
}
&.popupModalOverlay input[type='radio'] {
    margin-top: 0px;
    position: relative;
    top: 1px;
}
&.popupModalOverlay input[type=radio]:not(:checked).customRadioButton + span.customRadioButtonSpan {
    background: url("../images/newDesign/radioButtonNotChecked.png");
    width: 18px;
    height: 18px;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
}
.padLeft25 {
    padding-left: 25px !important;
}
.displayBlock {
    display: block;
}
.padTop-25-px {
    padding-top: 25px;
}
.expandArrowClassifier {
    background: url("../images/newDesign/expand_arrow.png") no-repeat;
    background-position-y: 5px;
    cursor: pointer;
    float: left;
    vertical-align: middle;
    width: 14px;
    height: 18px;
}
.collapseArrowClassifier {
    background: url("../images/newDesign/collapce_arrow.png") no-repeat !important;
    background-position-y: 5px !important;
}
&.popupModalOverlay input[type=radio]:checked.customRadioButton + span.customRadioButtonSpan {
    background: url("../images/newDesign/radioButtonChecked.png");
    width: 18px;
    height: 18px;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
}
&.popupModalOverlay ul.dynatree-container li {
    list-style-image: none;
    list-style-position: outside;
    list-style-type: none;
    -moz-background-clip: border;
    -moz-background-inline-policy: continuous;
    -moz-background-origin: padding;
    background-attachment: scroll;
    background-color: transparent;
    background-repeat: repeat-y;
    background-position: 0 0;
    margin: 0;
    padding: 0;
}
.treetableCustom .dynatree-container li {
    background-image: none;
    padding: 0;
}
.treetableCustom .dynatree-container > li {
    background-color: #eff0f1;
    margin-bottom: 3px;
    border-radius: 4px;
}
.treetableCustom ul.dynatree-container li {
    padding-left: 0px;
}
&.popupModalOverlay ul.dynatree-container li.dynatree-lastsib {
    background-image: none;
}
span.dynatree-node {
    display: -moz-inline-box;
}
.treetableCustom ul.dynatree-container .dynatree-node {
    position: relative;
    display: block;
    overflow: hidden;
}
.treetableCustom ul.dynatree-container .dynatree-node.dynatree-has-children {
    min-height: 24px;
}
&.popupModalOverlay ul.dynatree-container ul {
    padding: 0 0 0 16px;
    margin: 0;
}
.treetableCustom ul.dynatree-container ul {
    padding-left: 0px;
}
span.dynatree-checkbox, label.dynatree-checkbox {
    width: 20px !important;
    height: 20px !important;
    vertical-align: top;
    background-repeat: no-repeat;
    background-image: url("../images/newDesign/custom_check.png") !important;
    background-position: 0 100% !important;
    margin-left: 5px !important;
}
span.dynatree-empty, span.dynatree-vline, span.dynatree-connector, span.dynatree-expander, span.dynatree-icon, span.dynatree-checkbox, span.dynatree-radio, span.dynatree-drag-helper-img, #dynatree-drop-marker {
    width: 16px;
    height: 16px;
    display: -moz-inline-box;
    display: inline-block;
    vertical-align: top;
    background-repeat: no-repeat;
    background-position: left;
    background-image: url("../images/icons.gif");
    background-position: 0 0;
}
span.dynatree-connector {
    background-position: -16px -64px;
}
.treetableCustom .dynatree-connector {
    background-image: none;
    float: left;
}
.treetableCustom .dynatree-connector::after {
    content: "—";
    position: absolute;
    top: 6px;
    left: 0px;
    display: inline;
    font-size: 12px;
}
.treetableCustom li .dynatree-node .dynatree-connector::after {
    left: 21px;
}
.treetableCustom li li .dynatree-node .dynatree-connector::after {
    left: 50px;
}
.treetableCustom li li li .dynatree-node .dynatree-connector::after {
    left: 80px;
}
.treetableCustom li li li li .dynatree-node .dynatree-connector::after {
    left: 105px;
}
.treetableCustom li li li li li .dynatree-node .dynatree-connector::after {
    left: 135px;
}
.treetableCustom li li li li li li .dynatree-node .dynatree-connector::after {
    left: 165px;
}
.treetableCustom li li li li li li li .dynatree-node .dynatree-connector::after {
    left: 195px;
}
span.dynatree-checkbox {
    margin-left: 3px;
    background-position: 0px -32px;
}
.dynatree-selected span.dynatree-checkbox, label.dynatree-checkbox-selected {
    background-position: 0 0 !important;
}
.dynatree-selected span.dynatree-checkbox {
    background-position: -32px -32px;
}
.treetableCustom .dynatree-node > .dynatree-checkbox {
    position: absolute;
    top: 8px;
}

#innerTableRubricator .dynatree-checkbox {
    display: none !important;
}
#innerTableRubricator .dynatree-lastsib .dynatree-connector, #innerTableRubricator .dynatree-exp-c .dynatree-connector {
    display: none !important;
}
#innerTableRubricator .dynatree-checkbox, #innerTableRubricator .dynatree-has-children .dynatree-checkbox {
    display: none !important;
}
.treetableCustom li .dynatree-node .dynatree-title {
    padding-left: 45px;
}
.treetableCustom li .dynatree-node a.dynatree-title:hover, .treetableCustom li .dynatree-active a.dynatree-title, .treetableCustom li .dynatree-selected .dynatree-title, .treetableCustom li .dynatree-selected .dynatree-title .dynatree-subtitle {
    background-color: #fdd7c9 !important;
    color: #d0562b !important;
    text-decoration: none !important;
}
.treetableCustom li li .dynatree-node .dynatree-title {
    padding-left: 75px;
}
.treetableCustom li li li .dynatree-node .dynatree-title {
    padding-left: 105px;
}
.treetableCustom li li li li .dynatree-node .dynatree-title {
    padding-left: 135px;
}
.treetableCustom li li li li li .dynatree-node .dynatree-title {
    padding-left: 165px;
}
.treetableCustom li li li li li li .dynatree-node .dynatree-title {
    padding-left: 195px;
}
.treetableCustom li li li li li li li .dynatree-node .dynatree-title {
    padding-left: 225px;
}
.treetableCustom ul.dynatree-container .dynatree-node .dynatree-title {
    display: block !important;
}
.treetableCustom ul.dynatree-container .dynatree-has-children > .dynatree-title {
    border-bottom: 1px solid #dbe1e6;
}
.treetableCustom ul.dynatree-container .dynatree-node > .dynatree-title {
    display: block;
    color: #5f6a74;
    font-weight: normal;
    font-size: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-left: -10px;
}
.treetableCustom li .dynatree-node .dynatree-checkbox + .dynatree-title {
    padding-left: 70px;
}
.treetableCustom li li .dynatree-node .dynatree-checkbox + .dynatree-title {
    padding-left: 100px;
}
.treetableCustom li li li .dynatree-node .dynatree-checkbox + .dynatree-title {
    padding-left: 130px;
}
.treetableCustom li li li li .dynatree-node .dynatree-checkbox + .dynatree-title {
    padding-left: 160px;
}
.treetableCustom li li li li li .dynatree-node .dynatree-checkbox + .dynatree-title {
    padding-left: 190px;
}
.treetableCustom li li li li li li .dynatree-node .dynatree-checkbox + .dynatree-title {
    padding-left: 220px;
}
.treetableCustom li li li li li li li .dynatree-node .dynatree-checkbox + .dynatree-title {
    padding-left: 250px;
}
.treetableOkdp ul.dynatree-container > li > .dynatree-node > .dynatree-title {
    text-transform: uppercase;
    font-weight: bold;
    min-height: 14px;
}
.treetableCustom ul.dynatree-container .dynatree-node.dynatree-has-children > .dynatree-title {
    margin-left: 0px;
    padding-top: 13px;
    min-height: 24px;
}
.treetableCustom ul.dynatree-container .dynatree-node > .dynatree-checkbox + .dynatree-title {
    margin-left: 0px;
}
span.dynatree-expander {
    background-position: 0px -80px;
    cursor: pointer;
}
.treetableCustom span.dynatree-expander {
    background-image: url("../images/newDesign/dynatree-sprite.png");
    width: 24px;
    height: 24px;
    position: absolute;
    top: 10px;
    left: 0px;
}
.treetableCustom span.dynatree-expander, .treetableCustom .dynatree-exp-cl span.dynatree-expander, .treetableCustom .dynatree-exp-cd span.dynatree-expander, .treetableCustom .dynatree-exp-cdl span.dynatree-expander {
    background-position: 0 0;
}
.dynatree-exp-el span.dynatree-expander, .dynatree-exp-edl span.dynatree-expander {
    background-position: -32px -96px;
}
.treetableCustom .dynatree-exp-e span.dynatree-expander, .treetableCustom .dynatree-exp-el span.dynatree-expander, .treetableCustom .dynatree-exp-ed span.dynatree-expander, .treetableCustom .dynatree-exp-edl span.dynatree-expander {
    background-position: -25px 0;
}
.treetableCustom li .dynatree-node .dynatree-expander {
    left: 15px;
}
.treetableCustom li li .dynatree-node .dynatree-expander {
    left: 45px;
}
.treetableCustom li li li .dynatree-node .dynatree-expander {
    left: 73px;
}
.treetableCustom li li li li .dynatree-node .dynatree-expander {
    left: 100px;
}
.treetableCustom li li li li li .dynatree-node .dynatree-expander {
    left: 135px;
}
.treetableCustom li li li li li li .dynatree-node .dynatree-expander {
    left: 165px;
}
.treetableCustom .dynatree-node.dynatree-has-children > .dynatree-checkbox {
    top: 12px;
}

.modal-content-okdp-okved .okdpChoose.createDocBtn {
    width: 60%;
    margin: auto;
    padding: 10px;
}

&.popupModalOverlay .paginator.greyBox {
    border: 3px solid #eff0f1;
    border-radius: 5px;
    background-color: #fff;
    min-height: 72px;
}

div#ktruCT {
    font-size: 13px;
}

.classifiersGroupName{
    background: none !important;
    cursor: default !important;
    width: auto !important;
    height: auto !important;
}

.listOrg li span, .listKtru li span, .listClassifiers li span {
    display: inline-block;
    width: 18px;
}

.listOrg li span:first-child, .listKtru li span:first-child, .listClassifiers li span:first-child {
    background: url("../images/newDesign/close_blue_ico.png") no-repeat 0 2px;
    cursor: pointer;
    display: inline-block;
    height: 20px;
    padding: 0;
    vertical-align: middle;
}

.listOrg input, .listKtru input, .listClassifiers input {
    margin-right: 7px;
}

.listOrg label, .listOrg input, .saveExtendedSearch .listOrg input, .listKtru label, .listKtru input, .saveExtendedSearch .listKtru input,.listClassifiers label, .listClassifiers input, .saveExtendedSearch .listClassifiers input {
    vertical-align: middle;
}

.listOrg, .listKtru, .listClassifiers {
    padding: 10px 0px;
}

.manySelectWrap .closeLine {
    background-image: url("../images/newDesign/close_ico.png");
    width: 15px;
    height: 15px;
    display: block;
    float: right;
    margin-right: 8px;
    cursor: pointer;
}

.delBtn {
    background: url("../images/newDesign/close_ico.png") no-repeat 0 0;
    cursor: pointer;
    display: inline-block;
    height: 15px;
    width: 15px;
}

.ui-helper-hidden-accessible {
    display: none;
}
.modal-content-org{
    width: 900px;
    top: 0;
}
.modal-text-fz223{
    overflow-x: hidden;
    overflow-y: auto;
}
.alignCenter {
    text-align: center;
}
.alignCenterImportant {
    text-align: center !important;
}
&.popupModalOverlay .collapceArrowColored {
    background: url("../images/newDesign/collapse_arrow_alt.png") no-repeat 0 50% !important;
    color: #d0562b;
}
#innerTableCharacteristicsKtru label {
    padding-left: 10px;
    width: 180px !important;
}

#groupsId {
    table-layout: fixed;
    td {
        padding-left: 0;
        padding-right: 0;
    }
}

#chooseExtendedAttributeTablePaginatorContainer.paginatorContainer{
    background: transparent;
    margin: 0;
    padding: 0;
}
 #chooseExtendedAttributeTablePaginatorContainer{
     .paging .leftArrow a{
         background: none;
     }
     .paging .rightArrow a{
         background: none;
     }
     .paging .currentPage{
         background: #eff0f2;
         color: #0065dd;
         border-radius: 50%;
         display: inline-block;
         width: 40px;
         height: 40px;
         padding-top: 11px;
     }
     .paging li{
         margin-left: 5px;
         margin-right: 5px;
     }
 }
.editingBtn {
    background: url("../images/newDesign/edit_ico.png") no-repeat 0 0;
    display: inline-block;
    height: 15px;
    margin-right: 10px;
    width: 16px;
}
.checkIco {
    background: url("../images/newDesign/check_ico.png") no-repeat 0px 0px;
    cursor: pointer;
    display: inline-block;
    height: 18px;
    width: 18px;
}
.drugClearButton {
    display: inline-block;
    width: 0px;
    vertical-align: middle;
}

.drugClearButton > div {
    width: 11px;
    height: 10px;
    left: -30px;
    position: relative;
    background: url(../images/newDesign/clear.png) no-repeat 0 0;
    cursor: pointer;
}

.noticeTabBox #classifiersTable td{
    border-top: none;
}
.width_35_pr{
    width: 35%!important;
}
#chooseMedicalProductDialogDataHeader th:nth-child(1) {
    width: 16px;
    vertical-align: middle;
}

.worktypeContainer .dynatree-selected {
    margin: 3px 0;
}

.classifireBlock{
    max-width: 170px;
    word-break: break-word;
}
}
