.form-group {
    position: relative;
    &__input {
        padding: 12px 16px;
        border: 1px solid #DAE4EE;
        border-radius: 3px;
        width: 100%;
        text-overflow: ellipsis;

        @extend .text-base-min;
        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: $grey-main-light;
            font-size: 13px;

        }
        &::-moz-placeholder { /* Firefox 19+ */
            color: $grey-main-light;
            font-size: 13px;
        }
        &:-ms-input-placeholder { /* IE 10+ */
            color: $grey-main-light;
            font-size: 13px;
        }
        &:-moz-placeholder { /* Firefox 18- */
            color: $grey-main-light;
            font-size: 13px;
        }
    }
    label {
        margin-bottom: rem-local(4);
        color: $grey-main;
        @extend .text-base-min;
    }
    input[placeholder] {
        text-overflow:ellipsis;
    }
    .off-icon + .icon{
        display: none;
    }
    //Для корректного отображения иконки
    .icon {
        position: absolute;
        bottom: 10px;
        right: 15px;
        font-size: inherit;
        line-height: inherit;
        img{
            pointer-events: none;
        }
    }
    &_no-label {
        .icon {
            position: absolute;
            bottom: 10px;
            right: 15px;
        }
    }

    &_search {
        position: absolute;
        left: 330px;
        top: 32px;
        background: #ffffff;
    }

}
.form-group_no-icon {
    .form-group__input {
        padding-right: 15px;
    }
}
.form-group_small {
    .form-group__input {
        @extend .text-base-min;
        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: $grey-main-light;
            font-size: 13px;

        }
        &::-moz-placeholder { /* Firefox 19+ */
            color: $grey-main-light;
            font-size: 13px;
        }
        &:-ms-input-placeholder { /* IE 10+ */
            color: $grey-main-light;
            font-size: 13px;
        }
        &:-moz-placeholder { /* Firefox 18- */
            color: $grey-main-light;
            font-size: 13px;
        }
    }

}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .form-group {
        &__input {
            padding: 9px 38px 14px 15px;
        }
    }
}
