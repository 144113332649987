.quick-search-param {
    .params-text {
        color: $base-dark !important;
    }
    a:hover, .pseudo-a:hover {
        text-decoration: none;
        color: $prime-color-dark !important;
        cursor: pointer;
    }

    .d-block-quick {
        display: block !important;
        margin-top: 19px;
        & + .select-group {
            //margin-top: 11px;
        }
    }

    .mt-4-quick {
        @extend .mt-4;
    }
    .mb-3-quick {
        @extend .mb-3;
    }
    .noQuick {
        display: none;
    }
    .pseudoBetween {
        position: absolute;
        right: 0;
        top: 3px;
    }
    .checkbox-filter:last-child {
        margin-bottom: 3px;
    }
    .expansion-block {
        cursor: pointer;
    }
    #search-by-price {
        margin-bottom: 8px;
    }
    .form-group .icon {
        //top: 5px;
    }

    .border-with-botandtop {
        border-top: 1px solid $grey-border;
        border-bottom: 1px solid $grey-border;
    }

    .border-with-bottom {
         border-bottom: 1px solid $grey-border;
     }

    #total-save-search__total {
        float: right;
        background: #909ebb;
        color: #fff;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        display: inline-block;
        text-align: center;
        vertical-align: middle;
        @extend .text-base-nano;
    }

    .none-border {
        border: none;
    }


    .param-title {
        padding-bottom: 32px;
        padding-top: 40px;
        color: $base-dark;
        border-bottom: 1px solid $grey-border;
    }

    .total-save-search_align {
        width: 100%;
    }

    .filters {
        .block {
            padding: 24px 0 24px 0;

            $components: 'div:first-child > .row > div:not(:first-child)';
            .extendable-group {
                > #{$components} {@extend .mt-3;}
                &-hide_title {
                    .extendable-group-title {
                        display: none;
                    }
                    > #{$components} {@extend .mt-3;}
                }
            }

        }

        .search-group #search-dropdown .dropdown-inner {
            width: 100%;
        }

        .filter-title {
            margin: 0 0 12px;
            &--small{
                &.solo-title {
                    margin-bottom: 0 !important;
                }
            }
        }

        .checkbox-filter {
            margin: 0;
            margin-bottom: 1rem;
            &:last-child{
                margin-bottom: 1px;
            }
            .params-text{
                display: block;
            }
        }


        .loc-block {
            position: relative;
            margin-top: 10px;
            margin-left: 0px;
        }

        .okp-block {
            padding-right: 0;
            padding-left: 0;
            border: 0;
        }

        .okp-block:last-child {
            padding-left: 0;
        }

        h3 {
            @extend .text-base-min;
        }

        .heading-h3 {
            @extend .text-base-min;
        }

        .loc-block__separator {
            display: none;
        }

        .more-params__title {
            margin-bottom: 0px;
        }

        .block-info {
            margin-top: 0px;
        }
        .cat-text_slider {
            margin-left: 0 !important;
            margin-bottom: 3px;
        }
        .slider {
            &-item{
                &__title{
                    &:first-letter{
                        text-transform: uppercase;
                    }
                }
                &__separator {
                    padding-top: 1rem;
                    border-top: 1px solid $border-color;
                    margin-top: 1rem;
                }
                &__amount {
                    @extend .text-base-min;
                    color: $grey-main-light;
                }
                .cat-text_small {
                    color: $grey-main-light;
                    cursor: pointer;
                    &.toggleEnabled{
                        color: $prime-color !important;
                    }
                }
            }
        }
    }

    .solo-title--label{
        .form-group_small{
            label{
                @extend .text-base-min;
                color: $base-dark;
                font-weight: bold;
                margin-bottom: rem-local(16);
            }
        }
    }
    .ET44312_TU44class {
        padding-left: 30px;
    }
}
