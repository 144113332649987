.modal {
    .not-hierarchical-list {
        .modal-text-block {
            padding: 0;
            border-top: 0;
            border-bottom: 0;
            max-width: 95%;
            .drugItem {
                cursor: default;
            }
        }
        &__item{
            margin: 0;
            padding: 5px 0px;
            @extend .text-base-min;
            &-checkbox{

            }
            &-label-for-checkbox{
                display: block;
                &::before{
                    bottom: 0;
                }
                &:after{
                    bottom: 0;
                }

            }
        }
        .item-exclude {
            @extend .text-base-min;
        }
        &__single-choice {
            input[type=checkbox] + label {
                &:before, &:after {
                    opacity: 0;
                }
            }
            input[type=checkbox] + label {
                padding: 10px 0 10px 26px !important;
            }
            input[type=checkbox]:checked + label, input[type=checkbox] + label:hover {
                background: $grey-main-extra-light;
                color: $prime-color;
            }
        }
    }
    #chooseOrganizationDialogDataBody {
        max-height: 330px;
    }
}
