.modal{
    &-content{
        &-type-work{
            .dynatree-expander{
                top: 11px;
                &-uncover{
                    top: 8px;
                }
            }
            .dynatree-container{
                overflow: hidden;
            }
            .add{
                .Btn input[type=button] {
                    padding: 12px 18px;
                }
            }
            .other-search{
                input{
                    width: 75% !important;
                    line-height: 25px;
                    padding: 7px;
                }
                .blueBtn{
                    background-color: $prime-color;
                    border-radius: 3px;
                    padding: 12px 12px;
                    @extend .text-base-button;
                    .addBtn{
                        background: $prime-color url(/epz/static/images/newDesign/add_ico.png) no-repeat 0 0;
                        padding-left: 25px;
                        padding-bottom: 2px;
                    }
                }
            }
        }
    }
    .type-work-Choose{
        padding: 24px;
    }
}
