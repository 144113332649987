@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.custom-fadein {
    -webkit-animation: fadein 1.5s ease-out; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1.5s ease-out; /* Firefox < 16 */
    -ms-animation: fadein 1.5s ease-out; /* Internet Explorer */
    -o-animation: fadein 1.5s ease-out; /* Opera < 12.1 */
    animation: fadein 1.5s ease-out;
}

.trans {
    @include trans();
}
