.news-detail-wrap {
    padding: 45px 0;
    .all-categories{
        position: relative;
    }
}
.news-detail {
    ul {
        list-style-type: disc;
        list-style-position: inside;
    }
    ol {
        list-style-type: decimal;
        list-style-position: inside;
        padding: 0;
    }
    ul ul, ol ul {
        list-style-type: circle;
        list-style-position: inside;
        margin-left: 15px;
    }
    ol ol, ul ol {
        list-style-type: lower-latin;
        list-style-position: inside;
        margin-left: 15px;
    }
  &__title {
      color: #334059;
      margin-bottom: 22px;
  }
  &__info {
      margin-bottom: 41px;
      .news__date {
        margin-right: 21px;
        color: $grey-main-light;
        @extend .text-base-micro;
      }
      .news-status {
        color: $prime-color;
        text-transform: uppercase;
        @extend .text-cap-micro;
        &__icon {
            position: relative;
            img {
                max-width: 12px;
                vertical-align: baseline;
            }
        }
        &_alert {
            color: #E8523D;
        }
    }
  }
  &__content {
        border-top: 1px solid #E4E7F2;
        color: $base-dark;
        text-align: justify;
        @extend .heading-h4;
        p {
            margin-bottom: 28px;
        }
  }
}
