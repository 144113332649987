.smartbanner-show {
    margin-top: 180px;
}

.smartbanner-show .smartbanner {
    display: block;
}

/** Default **/
.smartbanner {
    position: fixed;
    left: 0;
    top: 0;
    display: none;
    width: 100%;
    height: 180px;
    line-height: 180px;
    font-family: inherit;
    background: $grey-super-light;
    z-index: 9998;
    -webkit-font-smoothing: antialiased;
    overflow: hidden;
    -webkit-text-size-adjust: none;
}

.smartbanner-container {
    margin: 0 auto;
    white-space: nowrap;
}

.smartbanner-close {
    display: inline-block;
    vertical-align: middle;
    margin: 0 5px 0 5px;
    font-family: inherit;
    font-size: 20px;
    text-align: center;
    color: #888;
    text-decoration: none;
    border: 0;
    border-radius: 14px;
    -webkit-font-smoothing: subpixel-antialiased;
}

.smartbanner-close:active,
.smartbanner-close:hover {
    color: #aaa;
}

.smartbanner-icon {
    background-image: url(../img/icons/eis-mobile-logo.png) !important;
    display: inline-block;
    vertical-align: middle;
    width: 150px;
    height: 150px;
    margin-right: 12px;
    background-size: cover;
    border-radius: 10px;
}

.smartbanner-info {
    display: inline-block;
    vertical-align: middle;
    @extend .heading-h2;
    font-weight: bold;
    width: 300px;

    span {
        display: none;
    }
}

.smartbanner-title {
    font-size: 13px;
    line-height: 18px;
}

.smartbanner-button {
    position: absolute;
    right: 20px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-weight: bold;
    color: $prime-color;
    text-transform: uppercase;
    text-decoration: none;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
    @extend .heading-h1;
}

.smartbanner-button:active,
.smartbanner-button:hover {
    color: #aaa;
}

.smartbanner-button-text {
}

.smartbanner-button-text:active,
.smartbanner-button-text:hover {
}

.smartbanner-close:active,
.smartbanner-close:hover {
    color: #aaa;
}

.smartbanner-button:active,
.smartbanner-button:hover {
    color: #aaa;
}

.smartbanner-button-text {
}

.smartbanner-button-text:active,
.smartbanner-button-text:hover {
}

/** iOS **/
.smartbanner-ios {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
    line-height: 180px;
}

.smartbanner-ios .smartbanner-close {
    border: 0;
    width: 50px;
    height: 50px;
    line-height: 50px;
    color: #888;
    text-shadow: 0 1px 0 white;
    font-size: 50px;
}

.smartbanner-ios .smartbanner-close:active,
.smartbanner-ios .smartbanner-close:hover {
    color: #aaa;
}

.smartbanner-ios .smartbanner-icon {
    background: rgba(0, 0, 0, 0.6);
    background-size: cover;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}

.smartbanner-ios .smartbanner-info {
    color: #6a6a6a;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
}

.smartbanner-ios .smartbanner-title {
    @extend .heading-h1;
    color: $base-dark;
    font-weight: bold;
    white-space: pre-wrap;
}

/** Android **/
.smartbanner-android {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
    line-height: 180px;
}

.smartbanner-android .smartbanner-close {
    border: 0;
    width: 50px;
    height: 50px;
    line-height: 50px;
    color: #888;
    text-shadow: 0 1px 0 white;
    font-size: 50px;
}

.smartbanner-android .smartbanner-close:active,
.smartbanner-android .smartbanner-close:hover {
    color: #eee;
}

.smartbanner-android .smartbanner-icon {
    background-color: transparent;
    box-shadow: none;
}

.smartbanner-android .smartbanner-info {
    color: #6a6a6a;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
}

.smartbanner-android .smartbanner-title {
    @extend .heading-h1;
    color: $base-dark;
    font-weight: bold;
    white-space: pre-wrap;
}

.smartbanner-android .smartbanner-button {
    min-width: 12%;
    color: #d1d1d1;
    padding: 0;
    background: none;
    border-radius: 0;
    box-shadow: 0 0 0 1px #333, 0 0 0 2px #dddcdc;
}

.smartbanner-android .smartbanner-button:active,
.smartbanner-android .smartbanner-button:hover {
    background: none;
}

.smartbanner-android .smartbanner-button-text {
    text-align: center;
    display: block;
    padding: 0 10px;
    background: #42b6c9;
    background: linear-gradient(to bottom, #42b6c9, #39a9bb);
    text-transform: none;
    text-shadow: none;
    box-shadow: none;
}

.smartbanner-android .smartbanner-button-text:active,
.smartbanner-android .smartbanner-button-text:hover {
    background: #2ac7e1;
}

/** Windows **/
.smartbanner-windows {
    background: #f4f4f4;
    background: linear-gradient(to bottom, #f4f4f4, #cdcdcd);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
    line-height: 80px;
}

.smartbanner-windows .smartbanner-close {
    border: 0;
    width: 18px;
    height: 18px;
    line-height: 18px;
    color: #888;
    text-shadow: 0 1px 0 white;
}

.smartbanner-windows .smartbanner-close:active,
.smartbanner-windows .smartbanner-close:hover {
    color: #aaa;
}

.smartbanner-windows .smartbanner-icon {
    background: rgba(0, 0, 0, 0.6);
    background-size: cover;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}

.smartbanner-windows .smartbanner-info {
    color: #6a6a6a;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
}

.smartbanner-windows .smartbanner-title {
    color: #4d4d4d;
    font-weight: bold;
}

.smartbanner-windows .smartbanner-button {
    padding: 0 10px;
    min-width: 10%;
    color: $prime-color;
}

.smartbanner-windows .smartbanner-button:active,
.smartbanner-windows .smartbanner-button:hover {
    background: #dcdcdc;
    background: linear-gradient(to bottom, #dcdcdc, #efefef);
}

.smartbanner-windows .smartbanner-button-text {
}

.smartbanner-windows .smartbanner-button-text:active,
.smartbanner-windows .smartbanner-button-text:hover {
}

