@import './helpers/all';
@import './fonts/fonts';
@import './lib/scss/bootstrap';
@import './lib/scss/customScrollbar';
@import './lib/scss/custom-col';

@import 'layout/index';
@import './blocks/checkbox';
@import './blocks/radiobutton';
@import './blocks/header';
@import './blocks/footer';
@import './blocks/menu';
@import './blocks/search';
@import './blocks/buttons';
@import './blocks/dropdown';
@import './blocks/news';
@import './blocks/slider';
@import './blocks/icons';
@import './blocks/statistic';
@import './blocks/modal';
@import './blocks/paginator';
@import './blocks/alert';
@import './blocks/card';
@import './blocks/popup';
@import './blocks/params';
@import './blocks/form-group';
@import './blocks/select';
@import './blocks/tooltip';
@import './blocks/tabs/common';
@import './blocks/search/all';
@import './blocks/show-by';
@import './blocks/start-page-ktru';
@import './blocks/custom-date-picker';
@import './blocks/gpselect';
@import './blocks/gpchevron';

@import './pages/index';
@import './pages/news';
@import './pages/docs';
@import './pages/news-detail';
@import './pages/support';
@import './pages/card-page';
@import './pages/purchases';
@import './pages/site-map';
@import './pages/quiz';
@import './pages/base';
@import 'src/sass/pages/etp';
@import './pages/search/index';
@import './blocks/mobile';
@import './blocks/daterangepicker';
@import './modal/index';
@import './card/index';

//@import '../../node_modules/air-datepicker/dist/css/datepicker.min';
@import './lib/smartbannerapp';
body {
    position: relative;
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-family: 'Roboto';
    background-color: #FFFFFF;
    min-width: 980px;
    opacity: 0;
}

/* элемент с таким классом используется для определения версии страницы, не изменяйте имя класса и не удаляйте элемент */
.poorVisionLink{
    cursor: pointer;
}

.container {
    min-width: 980px;
    .info {
        margin-top: 24px;
        margin-bottom: 22px;
    }
}
.content {
    min-width: 980px;
    padding: 24px 0;
    &-main {
        background-color: $grey-super-light;;
        padding-bottom: 0;
    }
    &-news {
        padding: 44px 0;
    }
    &-search-registry-bar{
        padding: 40px 0 4px 0;
        background: $grey-super-light;
        .sub-search{
            padding-top: 19px;
        }
    }
    &-search-registry-block{
        padding-top: 32px;
    }
}

.searchBlockStyle{
    min-width: 980px;
    max-width: 1150px;
    width: 100%;
    padding: 24px 15px 0;
    margin-right: auto;
    margin-left: auto;
}


#OrdersExtendedAttributesTag {
    .select-vars {
        overflow-y: auto;
        max-height: 250px;
    }

    .messageValidation {
        margin-top: 10px;
    }
}
#OrdersExtendedAttributesTag{
    .extendedAttributesListItem {
        margin-bottom: 10px;

        .extendedAttributesListItem {
            label {
                display: inline;
            }
        }
    }
}
.hotline {
    height: 100%;
    padding-left: 25px;
    border-left: 1px solid $grey-main-extra-light;
    .phone {
        // display: flex;
        // flex-direction: column;
        // align-items: baseline;
        &__text {
            color: $grey-main-light;
            @extend .text-base-min;
        }
        &__num {
            display: inline-block;
            margin-top: 16px;
            margin-bottom: 20px;
            color: $base-dark;
            @extend .heading-h2;
        }
    }
}

//pageloader submit
.pageloader
{
    background: rgba( 255, 255, 255, 0.8 );
    display: none;
    height: 100%;
    position: fixed;
    width: 100%;
    z-index: 9999;
}

.pageloader img
{
    left: 50%;
    margin-left: -32px;
    margin-top: -32px;
    position: absolute;
    top: 50%;
}

figure {
    margin: 0;
}

input::-ms-clear{
    display: none;
}

#searchAttachedFileTag .customCheckbox{
  margin-bottom: 5px;
}

@media (max-width: 1292px) {
    body {
        overflow-x: hidden;
    }
    html{
        -ms-overflow-x: hidden;
    }
    .statistic .params-years__item{
        margin-right: 4px;
    }
}
@media (max-width: 980px) {
    body {
        overflow-x: auto;
    }
    html{
        -ms-overflow-x: visible;
    }
}

@media (max-width: 1064px) {
    .hotline {
        .phone__num {
            font-size: 23px;
        }
    }
}

.colorPlaceholder{
    color: #b4b4b4 !important;
}
.cursorPointer{
    cursor: pointer !important;
}

.additional {
    padding-top:8px;//content-search-registry-block:32px + 8px
    padding-bottom: 40px;
    &-block {
        padding: 24px 32px;
        background-color: $grey-super-light;

        &__icon {
            padding-right: 24px;
        }

        &__text {
            color: $base-dark;
            @extend .heading-h3;

            &--sub {
                @extend .text-base-min;
                display: block;
                padding-top: 8px;
            }

            .custom-class {
                color: $grey-main-light;
            }
        }
    }
}
