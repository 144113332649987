.modal{
    &-content {
        &-message {
            width: 384px;
            position: absolute;


            .icon-message {
                width: 48px;
                height: 48px;
                position: relative;
                right: 150px;
            }

            .envelope {
                background: url(/epz/static/img/icons/icon_envelope.svg) no-repeat;
            }

            .rss {
                background: url(/epz/static/img/icons/icon_message_rss.svg) no-repeat;
            }

            .close_message {
                background: url(/epz/static/img/icons/icon_message_close.svg) no-repeat;
            }

            .ok {
                background: url(/epz/static/img/icons/icon_message_ok.svg) no-repeat;
            }

            .errorMessage {
                max-width: 48px;
                max-height: 48px;
                background: url(/epz/static/img/icons/icon_error_message.svg) no-repeat;
            }

            .messageChoose {
                padding: 20px 44px;
            }
        }
    }
}
