body {
    color: $base-dark;
}

.cabinet {
    padding-top: 37px;
    position: relative;
}

#contentTypeBase64,#signTypeFileBase64,#contentTypeFileBase64,#signTypeBase64{
    padding-left: 10px;
}

#signTypeFileBase64{
    .clearAll{
        line-height: 15px;
    }
}

.checkCryptoSignButtons{
    #clearContentBtn{
        background-color: white;
        color: $prime-color;
        border: 1px solid;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}
.checkCryptoSign form {
    padding: 0 10px;
}

.checkCryptoSign p {
    font-size: 15px;
    padding: 10px 0 10px;
}

.checkCryptoSignCertInfo div {
    font-size: 15px;
}

.checkCryptoSignFileUp {
    position: relative;
    overflow: hidden;
    width: 140px;
    height: 35px;
    padding: 8px 4px;
    color: black;
    text-align: center;
}

.checkCryptoSignFileUp label {
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

label.largeSize.blueBtn {
    line-height: 18px;
    padding-top: 9px;
}

.checkCryptoSignFileUp input[type='file'] {
    display: none;
}

.checkCryptoSignSelect {
    width: 300px;
    border: 2px solid grey;
}

.custom-tooltip-main {
    position: relative;
    display: inline-block;
    .tooltiptext{
        @extend .text-base-micro;
        font-family: 'Roboto';
        visibility: hidden;
        background-color: $dim-gray;
        color: #fff;
        padding: 8px;
        border-radius: 6px;
        position: absolute;
        z-index: 50;
        width: 50rem;
        top: 100%;
        left: 10%;
        *{
            margin: 0 !important;
        }
    }
    &:hover{
        .tooltiptext {
            visibility: visible;
        }
    }
}
.banners-top {
    padding: 0.45rem 0 0.5rem 0;
    &-item {
        border-radius: 3px;
        height: 88px;
        background: #fff;
        @extend .text-base-mid;
        color: $prime-color;
        padding: 0 24px 0 16px;
        cursor: pointer;
        &__title {
            padding-left: .8rem;
        }
    }
    a{
        text-decoration: none;
    }
}

.switcher{
    .switch {
        display: inline-block;
        cursor: pointer;
    }

    .switch .inactive-redaction-toggle {
        opacity: 0;
        width: 0;
        height: 0;
        display: none;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
        margin-right: 16px;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 10px;
        width: 10px;
        left: 3px;
        bottom: 3px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

    .inactive-redaction-toggle:checked + .slider {
        background-color: $prime-color;
    }

    .inactive-redaction-toggle:focus + .slider {
        box-shadow: 0 0 1px $prime-color;
    }

    .inactive-redaction-toggle:checked + .slider:before {
        -webkit-transform: translateX(16px);
        -ms-transform: translateX(16px);
        transform: translateX(16px);
    }

    .slider.round {
        border-radius: 34px;
        width: 32px;
        height: 16px;
        position: relative;
        display: inline-block;
        vertical-align: middle;
    }

    .slider.round:before {
        border-radius: 50%;
    }
}

.mt58{
   margin-top: 58px !important;
}

.mb81{
    margin-bottom: 81px !important;
}

.grey-text-main {
    color: $grey-main;
}

.padLeft175 {
    padding-left: 175px;
}

.nr-background {
    background-color: #F9F8FB;
}

.paddingLineMain {
    padding: 8px 38px 8px 14px;
}

.paddingLeftMain38 {
    padding-left: 38px;
}

.paddingRightMain24 {
    padding-right: 24px;
}

.paddingLeftMain32 {
    padding-left: 32px;
}

.paddingTopMain80 {
    padding-bottom: 80px;
}

.paddingTopMain54 {
    padding-bottom: 62px;
}

.paddingBottomMain80 {
    padding-bottom: 80px;
}

.paddingBottomMain46 {
    padding-bottom: 46px;
}

.inlineBlockMain {
    display: inline-block;
}

.blockMain {
    display: block !important;
}

.textAlingCenterMain {
    text-align: center;
}

.vertAlignTopMain {
    vertical-align: top;
}

.maxWidthMain780 {
    max-width: 780px;
}

.refDocumentation {
    max-width: 352px;
    max-height: 56px;
    padding-top: 72px;
    padding-left: 12px;
    text-align: left;
}

.marginRight100 {
    margin-right: 100px;
}

@media (max-width: 1050px) {
    .maxWidthMain780 {
        max-width: 650px;
    }
}

@media (max-width: 980px) {
    .maxWidthMain980 {
        max-width: 980px;
    }
    .padLeft175 {
        padding-left: 135px;
    }
    .marginLeftMain14 {
        margin-left: 14px;
    }
}

.bannerGoszakaz {
    svg {
        width: 100% !important;
        height: 160px !important;
    }
}

.marginBottom36 {
    margin-bottom: 36px !important;
}
