.alert {
    padding: 12px 0;
    background-color: #E8523D;
    color: #ffffff;
    &-block {
        margin-left: 23px;
        &__icon {
            background: #fff;
            width: 48px;
            height: 48px;
            min-width: 48px;
            min-height: 48px;
            border-radius: 50%;
            text-align: center;
            padding: 10px;
            margin-right: 48px;
            .icon {
                max-width: 24px;
            }
        }
        &__text {
            @extend .text-base-micro;
            color: #ffffff;
            .text {
                display: block;
                &-sub {
                    color: rgba(255, 255, 255, 0.5);
                }
            }
        }
    }
    .btn-alert {
        @extend .btn;
        @extend .btn-default_white;
        text-transform: none!important;
        font-weight: 300!important;
        line-height: 30px!important;
    }
}
.button-close-alert {
    cursor: pointer;
}
