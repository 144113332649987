.statistic {
    background-color: #ffffff;
    padding-top: 47px;
    padding-bottom: 80px;
    .statistic__link {
        @extend .text-base-min;
        text-decoration: none!important;
        .text {
            margin-right: 5px;
        }
    }
    .statistic-btn {
        width: auto;
        padding: 0 16px;
        margin-right: 5px;
        text-transform: none;
        line-height: 30px;
        background: #eff0f2;
        border: 0;
        &.active {
            background-color: $prime-color !important;
            color: $white !important;
        }
        &:hover {
            background-color: $prime-color-dark;
            color: $white;
        }
        .btn-text {
            @extend .text-base-min;
        }
    }
    .stat-tab {
        &.active, &:hover {
            .stat-tab__round {
                fill: $prime-color;
                stroke: $prime-color;
            }
            .stat-tab__elem {
                stroke: $white;
            }
            .stat-tab__title{
                color: $prime-color;
                cursor: default;
            }
        }
        &__title{
            @extend .text-base-min;
            margin-left: rem-local(12);
        }
        &:first-child{
            margin-right: rem-local(40);
        }
        &__svg--round{
            border-radius: 50%;
        }

    }
    .statistic-tabs {
        border-bottom: 1px solid $border-color;
        margin-bottom: rem-local(40);
        .active-tab {
            color: $prime-color;
            border-bottom: 2px solid $prime-color;
            background: none;
        }
        .tab_text{
            @extend .text-base-button;
            text-align: center;
            padding-bottom: rem-local(12);
            margin-right: rem-local(24);
            &:hover{
                cursor: pointer;
            }
        }
        .sub-text {
            color: $prime-color;
            @extend .text-base-min;
        }
    }

    .params {
        border-right: 1px solid $border-color;
        background: $white;
        height: 100%;
        &-title {
            @extend .text-base-button;
            color: $base-dark;
            text-align: left;
            padding-top: rem-local(16);
            margin-bottom: rem-local(32);
        }
        &-dropdown {
             margin-bottom: rem-local(16);
             padding-right: .5rem;
            & [data-collapse="true"] + .search_region {
                display: block;
            }

            & [data-collapse="false"] + .search_region {
                display: none;
            }
            #purchase-region-content{
                max-height: 400px;
                min-width: 248.5px;
            }
            .search_region{
                min-width: 248.5px;
                z-index: 999;
                border: 1px solid #dae4ee;
                box-shadow: inset 0 2px 1px #eff0f2;
                top: 1.6em;
                height: 26px;
            }
        }
        .dropdown-list{
            top: 22px !important;
        }
        &-years {
            color: $grey-main-light;
            @extend .text-base-micro;
            cursor: pointer;
            max-width: 80px;
            &__item {
                display: inline-block;
                padding-bottom: 3px;
                margin-right: 11px;
            }
        }
        &-stats {
            padding-top: 21px;
            padding-bottom: rem-local(95);
            border-bottom: 1px solid $border-color;
            &__item {
                position: relative;
                display: inline-block;
                margin-bottom: rem-local(16);
                @extend .heading-h4;
                label {
                    color: $grey-main-light;
                }
            }
            &__color {
                display: inline-block;
                margin-right: 12px;
                vertical-align: middle;
                width: 16px;
                height: 16px;
                min-width: 16px;
                background-color: #BCC5D6;
                border-radius: 2px;
            }
            &__price {
                color: $base-dark;
                @extend .heading-h4;
            }
            &__text {
                color: $base-dark;
                @extend .text-base-min;
            }
        }
        &-ecoms {
            padding: 10px 0;
            height: 170px;
            &__item {
                display: block;
                &:nth-child(2){
                    min-height: 56px;
                }
                .price {
                    @extend .params-stats__price;
                }
            }
        }
    }

    .grafics {
        margin-top: rem-local(48)
    }

    .grafics-btn-wrap {
        &:not(.slick-disabled){
            path {
                fill: $prime-color;
            }
            &:hover {
                circle {
                    fill: $prime-color;
                }

                path {
                    fill: $white;
                }
            }
        }
        &.slick-disabled {
            path {
                fill: $grey-main-light;
            }
        }
    }


    .total-content {
        padding-top: rem-local(16);
        padding-bottom: rem-local(16);
        .title {
            @extend .heading-h1;

            &--secondary {
                color: $grey-main-light;
            }

            &--no-data {
                @extend .text-base-min;
                color: $grey-main-light;
                margin-top: rem-local(150);
            }
        }
        .sub-title {
            @extend .text-base-mid;
            color: $grey-main-light;
        }
    }

    .slick-disabled {
        cursor: default !important;
    }

    .eis-tab {
        min-height: 400px;
        &__period {
            @extend .heading-h4;
            margin-top: rem-local(48);
            margin-bottom: rem-local(24);
            .highlight {
                font-weight: bold;
            }
        }
        &__item {
            .eis-content {
                border: 1px solid $border-color;
                border-radius: 3px;
                height: 480px;
                width: 352px;
                text-align: center;
                display: flex;
                flex-direction: column;
                &__header{
                    padding: rem-local(40) 0;
                    margin: 0 rem-local(32);
                    border-bottom: 1px solid $border-color;
                }
                &__caption{
                    @extend .heading-h1;
                    line-height: 48px;
                    color: $base-dark;
                    margin-top: rem-local(55);
                    .currency-symbol{
                        color: $grey-main-light;
                    }
                }
                &__desc{
                    @extend .heading-h2;
                    margin-top: rem-local(8);
                    padding: 0 rem-local(16);
                    color: $grey-main-light;
                }
                &__link{
                    margin-top: auto;
                    margin-bottom: rem-local(24);
                    @extend .text-base-min;
                    color: $prime-color;
                }

            }
        }
        &__g-btn{
            position: relative;
            bottom: 270px;
            .grafics-btn-wrap{
                top: 0;
                background: none;
                width: 48px;
                height: 48px;
                svg{
                    margin: 0 !important;
                }
            }
            .grafics-btn_prev{
                left: -25px;
            }
            .grafics-btn_next{
                right: -25px;
            }
        }
    }

    #map > svg > g > path:hover {
        fill: $prime-color;
    }

    #map > svg > g > path {
        cursor: pointer;
    }

    .preloader {
        background: rgba(255, 255, 255, 255);
        display: none;
        height: 100%;
        position: absolute;
        width: 100%;
        z-index: 9999;
        top: 0;

        .preloader-container {
            margin-left: 43%;
            margin-top: 5%;
            position: absolute;
            width: 15%;
            height: 15%;

            img {
                margin: auto;
                display: block;
            }

            div {
                text-align: center;
                padding-top: 15%;
                color: $grey-main-light;
                @extend .text-base-min;
            }
        }
    }
}

.content-cr-block {
    .cr-banners {
        margin-top: rem-local(40);
        &__item {
            display: flex;
            align-items: center;
            &--text {
                margin-left: 1rem;
                .text {
                    @extend .text-base-mid;
                    margin-right: 1rem;
                }
                .sub-text {
                    @extend .text-base-micro;
                    color: $grey-main-light;
                }
            }
        }
    }
    .cr-typical-reports{
        padding: 1.95rem;
        border: 1px solid $border-color;
        border-radius: 3px;
        &__title {
            @extend .heading-h3;
        }
        &__text {
            @extend .text-base-min;
            color: $grey-main-light;
        }
    }

    .cr-statistics {
        .period{
            @extend .text-base-min;
            color: $grey-main-light;
        }

        .breadcrumb {
            padding: 24px 0;
            align-items: center;
        }
        .container-table{

            .heading-table{
                @extend .heading-h5;
            }

            .table-block{
                padding-top: rem-local(26);
                padding-bottom: rem-local(26);
                table {
                    tbody {
                        max-height: 520px;
                        overflow: auto;
                        display: block;
                        tr {
                            &:first-child {
                                td {
                                    border-top: 0;
                                }
                            }
                            td:first-child {
                                width: 5%;
                                text-align: center;
                            }
                            td:last-child {
                                width: 20%;
                            }
                        }
                    }
                    thead, tbody tr {
                        display: table;
                        width: 100%;
                        table-layout: fixed;
                    }
                    thead {
                        width: 100%;
                        tr {
                            th {
                                vertical-align: middle;
                                &:first-child {
                                    width: 5%;
                                }
                                &:last-child {
                                    width: 20%;
                                }
                            }
                        }
                    }
                }
            }
        }

        .navBreadcrumb {
            display: flex;
            flex-flow: wrap;

            &__item {
                margin-left: 8px;

                &_first {
                    margin: 0;
                }

                &_active {
                    color: $grey-main-light;
                }

                .span{
                    cursor: default;
                }
            }

            &__text {
                @extend .text-base-micro;
            }

            &__icon {
                margin-right: 6px;

                &_back {
                    position: relative;
                }
            }
        }

        .stat-block {
            &__title {
                @extend .heading-big-text;
            }
            &__item {
                display: flex;
                padding-top: 32px;
                align-items: baseline;

                &_count {
                    font-size: 26px;
                    line-height: 32px;
                    font-weight: bold;
                }
                &_sub-count {
                    @extend .heading-h3;
                    color: #909EBB;
                    margin-left: 24px;
                }
            }
            &__description {
                @extend .text-base-min;
                color: $grey-main-light;
            }
        }

        .y-graph-legend {
            &__count {
                font-weight: bold;
                font-size: 16px;
                line-height: 16px;
                color: $base-dark;
                text-align: right;
            }
            &__label {
                @extend .text-base-min;
                color: $grey-main-light;
                text-align: right;
                line-height: 16px;
            }
        }

        .color-horiz-legend {
            display: flex;
            &__text {
                margin-right: 32px;
            }
            &__color {
                margin-right: 12px;
                width: 16px;
                height: 16px;
                min-width: 16px;
                background-color: #bcc5d6;
                border-radius: 2px;
            }
            &__count {
                font-weight: bold;
                font-size: 16px;
                line-height: 16px;
                color: $base-dark;
            }
            &__descr {
                @extend .text-base-min;
                color: $grey-main-light;
            }
        }

        rect.bar.link {
            cursor: pointer;
        }
    }
}

.content-cr-block {
    .cr-banners {
        margin-top: rem-local(40);
        &__item {
            display: flex;
            align-items: center;
            &--text {
                margin-left: 1rem;
                .text {
                    @extend .text-base-mid;
                    margin-right: 1rem;
                }
                .sub-text {
                    @extend .text-base-micro;
                    color: $grey-main-light;
                }
            }
        }
    }
    .cr-typical-reports{
        padding: 1.95rem;
        border: 1px solid $border-color;
        border-radius: 3px;
        &__title {
            @extend .heading-h3;
        }
        &__text {
            @extend .text-base-min;
            color: $grey-main-light;
        }
    }

    .cr-statistics {

    }
}
.content-banners-block{
    .banners{
        margin-top: rem-local(32);
        &__item {
            display: flex;
            border-radius: 3px;
            cursor: pointer;
            height: 72px;
            box-shadow: 0 4px 12px rgba(51, 64, 89, 0.1);
            align-items: center;
            padding-left: 24px;
            img {
                width: 40px;
                height: 40px;
            }
            &--text {
                margin-left: 1rem;
                .text {
                    @extend .text-base-mid;
                    margin-right: rem-local(16);
                }
                .sub-text {
                    @extend .text-base-micro;
                    color: $grey-main-light;
                }
            }
            &__active{
                background: $prime-color;
                    .text {
                        color: #fff;
                    }
                    .sub-text {
                        color: #C4DCF7;
                    }
            }
        }
    }
}
.cr-report-statistics{
    padding: 1.5rem;
    border: 1px solid #e4e7f2;
    border-radius: 3px;
    &__title {
        @extend .heading-h3;
        margin-bottom: 19px;
    }
    &__img {
        width: 88px;
        height: 94px;
    }
    &__btn{
        color: $prime-color;
        @extend .text-base-button;
        img{
            width: 14px;
            height: 12px;
            margin-left: rem-local(9);
        }
        span {
            vertical-align: middle;
        }
    }
}

.fontSize9 {
    font-size: 9px !important;
}

.font-weight-500 {
    font-weight: 500 !important;
}

.font-weight-700 {
    font-weight: 700 !important;
}

.line-height-16 {
    line-height: 16px !important;
}

.padding-bottom-24 {
    padding-bottom: 24px;
}


