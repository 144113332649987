.descriptLow {
    background: url(../images/newDesign/warning.png) no-repeat 0 0;
    color: #d0562b;
    font-size: 13px;
    padding: 0 0 15px 35px;
}
.bigGreyBtn, .greyBtn {
    background-color: #a0a6ab;
}
.darkGreyText {
    color: #7b7b7b !important;
}
.inlineInputGroup .inputLabel {
    float: left;
    //width: 90px;
    line-height: 30px;
}
.validation-error {
    color: red;
    font-size: 12px;
    padding: 5px;
}
.require {
    color: red;
    padding-left: 3px;
}
.orange {
    color: #E8523D !important;
}
.message-box {
    padding: 20px;
    background-color: #eff0f1;
    font-size: 14px;
    color: #5f6a74;
    border-radius: 5px;
}
.inlineInputGroup {
    font-size: 12px;
}
.inlineInputGroup .inputLabel {
    float: left;
    width: 90px;
    line-height: 30px;
}
.simpleLineHeight16 {
    line-height: 16px !important;
}
.inlineInputGroup .inputWrap {
    overflow: hidden;
    display: block;
}
.captcha-image {
    float: left;
    width: 200px;
    height: 50px;
    margin-right: 20px;
}
.captcha-image input {
    width: 100%;
}
.captcha-box input {
    width: 235px;
}
.captcha-actions {
    padding-top: 5px;
}
.captcha-box .captcha-repeat {
    padding-right: 10px;
}
.captcha-box a {
    text-decoration: none;
    border-bottom: 1px dashed #018ccd;
}
.btn-line {
    text-align: center;
    padding: 20px 0;
}
.captcha-box .captcha-listen:before {
    background-image: url(../img/captcha-listen.png);
}
.captcha-box .captcha-repeat:before {
    background-image: url(../img/captcha-repeat.png);
}
.captcha-box li:before{
    content: '';
    width: 12px;
    height: 15px;
    background-repeat: no-repeat;
    display: inline-block;
    margin-right: 5px;
    vertical-align: middle;
}
