
.sortBy {
    padding: 28.5px 0 0px 0px;
    font-size: 12px;
}

.lowList li, .sortBy > ul > li {
    display: inline;
}

.sortBy > ul > li.sortItem {
    line-height: 26px;
}

.lowList label {
    padding-right: 12px;
    vertical-align: middle;
}

.lowList input {
    margin: -1px 7px 0 0;
}

.sortBy > ul > li:first-child {
    color: #909EBB;
}

.sortBy > ul:first-child {
    float: right;
}

.sortBy > ul:last-child > li {
    padding-right: 8px;
}

ol, ul {
    list-style-type: none;
}

.pageSelect {
    position: relative;
}

.pageSelect span {
    background: url(../images/newDesign/sort_arrow.png) no-repeat 88% -121px;
    border: 1px solid #fff;
    cursor: pointer;
    display: inline-block;
    padding: 3px 17px 3px 7px;
    text-align: center;
    width: 45px;
}

.pageSelect span i {
    font-style: normal;
    padding: 0 2px;
}

.pageSelect ul {
    background-color: #fff;
    border: 1px solid #d3d7db;
    border-top: none 0;
    border-radius: 0 0 3px 3px;
    display: none;
    left: 0;
    position: absolute;
    top: 18px;
    width: 45px;
    z-index: 10;
}

.pageSelect li {
    cursor: pointer;
    padding: 2px 11px;
}

.sortItem.activeSort span {
    background: #eff0f1 url(../images/newDesign/sort_arrow.png) no-repeat 93% 9px;
    border-bottom: none 0;
    border-radius: 4px;
    color: #d0562b;
    padding: 5px 35px 5px 10px;
}

.sortItem.activeSort.arrowTop span {
    background: #eff0f1 url(../images/newDesign/sort_arrow.png) no-repeat 93% -39px;
}

.sortItem span {
    color:  #0065dd;
    cursor: pointer;
    padding: 0;
}

.pageSelect .openList {
    background: url("../images/newDesign/sort_arrow.png") no-repeat 88% -186px;
    border: 1px solid #d3d7db;
    border-radius: 4px 4px 0 0;
}
