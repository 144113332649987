.paginator {
    margin: 30px 0;
    &__mt24 {
        margin: 24px 0 0 0 !important;
    }
    &__mt28 {
        margin: 28px 0 0 0 !important;
    }

    .pages {
        display: inline-block;
        padding: 0;
        margin: 0;
        .page {
            display: inline-block;
            list-style: none;
            &.disabled {
                display: none;
            }
            &__link {
                display: inline-block;
                padding: 10px 0;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                color: $grey-main-light;
                text-align: center;
                @extend .text-base-min;
                &_active {
                    background: rgba(239, 240, 242, 1);
                    color: $prime-color;
                }
                &:hover {
                    text-decoration: none;
                    background: rgba(239, 240, 242, 1);
                }
            }
            .link-text-enum{
                color: $grey-main-light;
            }
        }
    }
    .paginator-button {
        display: inline-block;
        margin-left: 12px;
    }
}
