.modal-content {
    &-update-password, &-update-user {
        .user-data {
            .block {
                margin-bottom: 18px;

                label {
                    @extend .text-base-min;
                    color: $grey-main;
                }

                &__input {
                    @extend .text-base-min;
                    color: $base-dark;
                    padding: 9px 14px;
                    margin-top: 6px;
                    border: 1px solid $border-color;
                    box-shadow: inset 0px 2px 1px $grey-super-light;
                    border-radius: 3px;
                    width: 100%;
                    height: auto !important;
                    &:disabled {
                        box-shadow: none;
                    }
                }
                &__value{
                    @extend .text-base-min;
                    color: $base-dark;
                }

            }
        }
        .modal-footer{
            margin-top: rem-local(73);
        }
    }

    &-userRegistration, &-user-verification, &-update-user, &-update-password {
        .password{
            input::-ms-reveal {display: none;}

            position: relative;

            &-icon{
                position: absolute;
                top: 13px;
                right: 10px;
                display: inline-block;
                width: 25px;
                height: 15px;
                background: url(/epz/static/img/icons/icon-input-eye.svg) 0 0 no-repeat;
            }

            &-icon.view{
                background: url(/epz/static/img/icons/icon-input-eye-close.svg) 0 0 no-repeat;
                height: 20px;
            }
        }
    }
    &-user-registration {
        max-height: 85% !important;
    }
}
