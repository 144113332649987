.modal-content {
    &-classifiers{
        .classifire-block{
            #classifiersTable, #classifiersModalTable{
                #groupsId{
                    #classifierExp{
                        padding-left: 0px !important;
                    }
                    tr td:first-child{
                        padding-left: 0px !important;
                    }
                    tr td.width-25-pr.padBtm5{
                        padding-right: 10px !important;
                        word-wrap: break-word !important;
                    }
                }
                .msgError {
                    padding: 0px 20px;
                    &[style*="visibility: hidden;"]{
                        display: none !important;
                    }

                }
                .treetable{
                    padding-top: 0 !important;
                }
                .okdpSearch {
                    padding: 15px 27px !important;
                    &.createDocBtn{
                        .resetBtn input{
                            margin-top: 26px !important;
                        }
                    }
                }
            }
            .okdpSearch input[type=text]{
                width: 84% !important;
            }
            #goodssearchKTRU+.btn+.resetBtn{
                right: 12.5em !important;
            }
        }
        .scrollOnModalByY{
            max-height: 350px;
        }
        &.single-classifier{
            #tree{
                max-height: 350px;
                overflow: hidden;
            }
            .dynatree-container li{
                padding-top: 10px;
            }
            .scrollOnModalByY{
                max-height: none !important;
            }
            .scrollOnModalClassifiersItemsByY{
                max-height: 280px;
            }
            .paginator{
                margin-top: 20px;
                .paginator-button:hover{
                    background: none !important;
                }
                .page{
                    padding: 0 !important;
                }
                a{
                    vertical-align: middle !important;
                }

            }
            #goodssearchKTRU+.btn+.resetBtn{
                right: 7em !important;
            }
            .okdpSearch input[type=text]{
                padding-right: 40px;
            }
        }
    }
    &-classifiers.modal-ktru{
        .classifiers-paginator-block{
            position: absolute;
            width: 95%;
            top: 97%;
            .select-vars{
                background: $mainBackground;
            }
        }
        .modal-footer{
            padding-top: 10%;
        }
    }
}
