.mobile-banner{
    max-height: 185px;
    &-background{
        position: relative;
    }
    &-title{
        position: absolute;
        width: 574px;
        height: 64px;
        left: 353px;
        top: 70px;

        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 19px;
        line-height: 24px;

        color: #334059;
    }

    &-content{
        position: absolute;
        width: 574px;
        height: 77px;
        left: 353px;
        top: 132px;

        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 20px;

        color: #6F7C98;
    }
    #Group_2, #Group_3{
        cursor: pointer;
        position: absolute;
        width: 136px;
        height: 40px;
        top: 240px;
    }
    #Group_2 {
        left: 352px;
    }
    #Group_3{
        left: 510px;
    }
}

.autosetup-banner {
    max-height: 333px;
    display: -webkit-box;
    display: -ms-flexbox;

    &-background {
        position: relative;
        width: 544px;
    }

    @media (max-width: 1010px) {
        &-background {
            width: 459px;
        }
    }

    &-title {
        position: absolute;
        height: 48px;
        left: 193px;
        top: 20px;

        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 19px;
        line-height: 24px;

        color: #334059;
    }
    @media (min-width: 1010px) {
        &-title {
            top: 40px;
            width: 327px;
        }
    }

    .np-title {
        height: 72px;
    }

    &-content {
        position: absolute;
        height: 136px;
        left: 192px;
        top: 104px;

        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 20px;

        color: #6F7C98;

    }
    @media (min-width: 1010px) {
        &-content{
            width: 328px;
        }
    }
    .np-content {
        height: 120px;
        top: 128px;
    }

    &-os {
        position: absolute;
        width: 82px;
        height: 20px;
        left: 354px;
        top: 270px;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 20px;
        color: #909EBB;
    }
}
