.modal{
    &-content{
        &-edsContainer{
            .modal{
                &-text{
                    &-block{
                        &:nth-child(n+2){
                            padding-bottom: 1.6rem;
                        }
                        &:last-child{
                            padding-bottom: 2.15rem;
                        }
                        .ep-info{
                            color: $grey-main-light;
                            @extend .text-base-min;
                            &--item{
                                @extend .no-gutters;
                                padding-bottom: rem-local(10);
                            }
                            &__name{
                                @extend .heading-h3;
                                padding-bottom: rem-local(17);
                                color: $base-dark;
                            }
                            &__title{
                                width: 107px;
                                margin-right: rem-local(16);
                            }
                            &__value{
                                color: $base-dark;
                            }
                        }
                    }
                }
                &-footer{
                    border: none;
                }
            }
        }
    }
    &-icon{
        width: 56px;
        height: 56px;
        background: url(/epz/static/img/icons/icon_sign.svg) no-repeat;
        position: relative;
        &-uncheck{
            width: 24px;
            height: 24px;
            background: url(/epz/static/img/icons/icon_uncheck.svg) no-repeat;
            position: absolute;
            right: -7px;
            &.isExt{
                background: url(/epz/static/img/icons/ep_icon_uncheck.svg) no-repeat;
            }
        }
        &-check{
            width: 24px;
            height: 24px;
            background: url(/epz/static/img/icons/icon_check.svg) no-repeat;
            position: absolute;
            right: -7px;
            &.isExt{
                background: url(/epz/static/img/icons/ep_icon_uncheck.svg) no-repeat;
            }
        }
    }
}
