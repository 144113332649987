.modal {
    #tree{
        max-height: 282px;
        overflow: auto;
    }
    &-content-ppo{
        #tree{
            max-height: 350px;
        }
        .mCSB_inside > .mCSB_container {
            margin-right: 0;
        }
    }
    .treetableCustom ul.dynatree-container {
        border: 0;
        white-space: normal;
        @extend .text-base-min;
        font-family: 'Roboto';
        padding: 0;
        margin: 0;
        background-color: inherit;
        border: none;
    }
    .dynatree-partsel span.dynatree-checkbox {
        background-position: 0 -59px !important;
    }
    .dynatree-partsel span.dynatree-checkbox.disabled-checkbox {
        background-position: 0 -80px !important;
    }
    .dynatree-selected span.dynatree-checkbox, label.dynatree-checkbox-selected {
        background-position: 0 0 !important;
    }
    .treetableCustom .dynatree-connector {
        background-image: none;
        float: left;
    }
    .dynatree-exp-cd span.dynatree-expander, .dynatree-exp-e span.dynatree-expander, .dynatree-exp-ed span.dynatree-expander {
        background-position: 0;
    }
    .dynatree-exp-e span.dynatree-expander, .dynatree-exp-ed span.dynatree-expander,.dynatree-exp-el span.dynatree-expander, .dynatree-expanded span.dynatree-expander {
        transform: rotate(0);
    }
    span.dynatree-checkbox{
        width: 20px;
        height: 20px;
    }
    &-text {
        &-block {
            .dynatree {
                &-expander {
                    max-width: 12px;
                    max-height: 10px;
                    background: url(/epz/static/img/icons/icon_expander.svg) no-repeat;
                    display: inline-block;
                    position: relative;
                    top: 5px;
                    transform: rotate(-90deg);
                    &-uncover {
                        max-width: 12px;
                        max-height: 12px;
                        display: inline-block;
                        top: 4px;
                        left: 3px;
                        margin-left: 20px;
                        background: url(/epz/static/img/icons/icon_expander_uncover.svg) no-repeat;
                    }
                }
                &-connector {
                    max-width: 12px;
                    max-height: 10px;
                    display: inline-block;
                    top: 6px;
                }
                &-container {
                    li{
                        background-image: none;
                        padding: 21px 0 0 0;
                        li{
                            padding: 13px 0 0 0;
                        }
                    }
                }
                &-checkbox {
                    max-width: 16px;
                    max-height: 17px;
                    background: url(/epz/static/img/icons/icon_checkboxes_modal.svg) no-repeat;
                    display: inline-block;
                    top: 1px;
                    margin-left: 4px;
                    margin-right: 8px;
                    background-position: 0 -19px !important;
                    &.disabled-checkbox{
                        background-position: 0 -39px !important;
                    }
                }
                &-title {
                    display: inline-block;
                    max-width: 85%;
                    word-wrap: break-word;
                    color: $base-dark;
                }
            }
            a.dynatree-title{
                font-weight: normal;
                vertical-align: middle;
                font-size: inherit;
                :link, :focus{
                    background: none !important;
                    border: none;
                }
            }
        }
    }

    .treetableCustom li.ppo-dynatree,
    .treetableCustom li.ppo-dynatree__selected {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .treetableCustom li.ppo-dynatree           > span.dynatree-node,
    .treetableCustom li.ppo-dynatree__selected > span.dynatree-node {
        display: block !important;
        padding-top: 6px !important;
        padding-bottom: 7px !important;
    }

    .treetableCustom li.ppo-dynatree           > span.dynatree-node > span.code,
    .treetableCustom li.ppo-dynatree__selected > span.dynatree-node > span.code,
    .treetableCustom li.ppo-dynatree           > span.dynatree-node > a.dynatree-title,
    .treetableCustom li.ppo-dynatree__selected > span.dynatree-node > a.dynatree-title {
        vertical-align: baseline !important;
    }

    .treetableCustom li.ppo-dynatree__selected > span.dynatree-node,
    .treetableCustom li.ppo-dynatree__selected > span.dynatree-active > a.dynatree-title {
        background-color: $grey-super-light !important;
        color: $prime-color !important;
    }

    .treetableCustom li.ppo-dynatree > span.dynatree-node,
    .treetableCustom li.ppo-dynatree > span.dynatree-node > a.dynatree-title:focus,
    .treetableCustom li.ppo-dynatree > span.dynatree-active > a.dynatree-title,
    .treetableCustom li.ppo-dynatree > span.dynatree-active > a.dynatree-title:focus
    {
        background-color: inherit !important;
        color: inherit !important;
    }
}

//Стили для dynatree при помощи padding для возможности применить hover ко всей
.treetableCustomPadding{
    .treetableCustom ul.dynatree-container {
        border: none;
        overflow: hidden;
    }
    .dynatree-expander{
        position: absolute !important;
        top: 18px;
    }

    .treetableCustom ul.dynatree-container ul {
        padding-left: 0;
    }

    .treetableCustom ul.dynatree-container li {
        padding-left: 0;
    }

    .treetableCustom ul.dynatree-container .dynatree-node {
        position: relative;
        display: block;
        overflow: hidden;
    }

    .treetableCustom ul.dynatree-container .dynatree-node .dynatree-title {
        display: block !important;
        max-width: none !important;
        b{
            display: inline-block !important;
            &.dynatree-title{
                padding: 0;
            }
        }
    }

    .treetableCustom ul.dynatree-container .dynatree-node.dynatree-has-children {
        min-height: 24px;
    }

    .treetableCustom .dynatree-container li {
        background-image: none;
        padding: 0;
    }

    .treetableCustom .dynatree-node > a {
        display: inline;
    }

    .treetableCustom ul.dynatree-container {
        white-space: normal;
    }

    .treetableCustom .dynatree-connector {
        background-image: none;
        float: left;
    }

    .treetableCustom ul.dynatree-container .dynatree-node > .dynatree-title {
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .treetableCustom ul.dynatree-container .dynatree-node.dynatree-has-children > .dynatree-title {
        margin-left: 0;
        padding-top: 13px;
        min-height: 24px;
    }

    .treetableCustom ul.dynatree-container .dynatree-node > .dynatree-checkbox + .dynatree-title {
        margin-left: 0;
    }

    .treetableCustom li .dynatree-node .dynatree-title {
        padding-left: 45px;
    }

    .treetableCustom li li .dynatree-node .dynatree-title {
        padding-left: 75px;
    }

    .treetableCustom li li li .dynatree-node .dynatree-title {
        padding-left: 105px;
    }

    .treetableCustom li li li li .dynatree-node .dynatree-title {
        padding-left: 135px;
    }

    .treetableCustom li li li li li .dynatree-node .dynatree-title {
        padding-left: 165px;
    }

    .treetableCustom li li li li li li .dynatree-node .dynatree-title {
        padding-left: 195px;
    }

    .treetableCustom li li li li li li li .dynatree-node .dynatree-title {
        padding-left: 225px;
    }

    .treetableCustom li li li li li li li li .dynatree-node .dynatree-title {
        padding-left: 255px;
    }

    .treetableCustom li li li li li li li li li .dynatree-node .dynatree-title {
        padding-left: 285px;
    }

    .treetableCustom li .dynatree-node .dynatree-checkbox + .dynatree-title {
        padding-left: 70px;
    }

    .treetableCustom li li .dynatree-node .dynatree-checkbox + .dynatree-title {
        padding-left: 100px;
    }

    .workTypeTreeTable li li .dynatree-node .dynatree-checkbox + .dynatree-title {
        padding-left: 45px;
    }

    .treetableCustom li li li .dynatree-node .dynatree-checkbox + .dynatree-title {
        padding-left: 130px;
    }

    .treetableCustom li li li li .dynatree-node .dynatree-checkbox + .dynatree-title {
        padding-left: 160px;
    }

    .treetableCustom li li li li li .dynatree-node .dynatree-checkbox + .dynatree-title {
        padding-left: 190px;
    }

    .treetableCustom li li li li li li .dynatree-node .dynatree-checkbox + .dynatree-title {
        padding-left: 220px;
    }

    .treetableCustom li li li li li li li .dynatree-node .dynatree-checkbox + .dynatree-title {
        padding-left: 250px;
    }

    .treetableCustom li li li li li li li li .dynatree-node .dynatree-checkbox + .dynatree-title {
        padding-left: 280px;
    }

    .treetableCustom li li li li li li li li li .dynatree-node .dynatree-checkbox + .dynatree-title {
        padding-left: 310px;
    }

    .treetableCustom li .dynatree-node .dynatree-expander {
        left: 15px;
    }

    .treetableCustom li li .dynatree-node .dynatree-expander {
        left: 45px;
    }

    .treetableCustom li li li .dynatree-node .dynatree-expander {
        left: 73px;
    }

    .treetableCustom li li li li .dynatree-node .dynatree-expander {
        left: 100px;
    }

    .treetableCustom li li li li li .dynatree-node .dynatree-expander {
        left: 135px;
    }

    .treetableCustom li li li li li li .dynatree-node .dynatree-expander {
        left: 165px;
    }

    .treetableCustom li li li li li li li .dynatree-node .dynatree-expander {
        left: 195px;
    }

    .treetableCustom li li li li li li li li .dynatree-node .dynatree-expander {
        left: 225px;
    }

    .treetableCustom li li li li li li li li li .dynatree-node .dynatree-expander {
        left: 255px;
    }

    .treetableCustom li .dynatree-node .dynatree-connector:after {
        left: 15px;
    }

    .treetableCustom li li .dynatree-node .dynatree-connector:after {
        left: 50px;
    }

    .treetableCustom li li li .dynatree-node .dynatree-connector:after {
        left: 80px;
    }

    .treetableCustom li li li li .dynatree-node .dynatree-connector:after {
        left: 105px;
    }

    .treetableCustom li li li li li .dynatree-node .dynatree-connector:after {
        left: 135px;
    }

    .treetableCustom li li li li li li .dynatree-node .dynatree-connector:after {
        left: 165px;
    }

    .treetableCustom li li li li li li li .dynatree-node .dynatree-connector:after {
        left: 195px;
    }

    .treetableCustom li li li li li li li li .dynatree-node .dynatree-connector:after {
        left: 225px;
    }

    .treetableCustom li li li li li li li li li .dynatree-node .dynatree-connector:after {
        left: 255px;
    }

    .treetableCustom li .dynatree-node > .dynatree-checkbox {left: 40px;}
    .treetableCustom li li .dynatree-node > .dynatree-checkbox {left: 70px;}
    .treetableCustom li li li .dynatree-node > .dynatree-checkbox {left: 100px;}
    .treetableCustom li li li li .dynatree-node > .dynatree-checkbox {left: 130px;}
    .treetableCustom li li li li li .dynatree-node > .dynatree-checkbox {left: 160px;}
    .treetableCustom li li li li li li .dynatree-node > .dynatree-checkbox {left: 190px;}
    .treetableCustom li li li li li li li .dynatree-node > .dynatree-checkbox {left: 220px;}
    .treetableCustom li li li li li li li li .dynatree-node > .dynatree-checkbox {left: 250px;}
    .treetableCustom li li li li li li li li li .dynatree-node > .dynatree-checkbox {left: 280px;}

    .treetableCustom li .dynatree-node.dynatree-has-children > .dynatree-checkbox {left: 40px;}
    .treetableCustom li li .dynatree-node.dynatree-has-children > .dynatree-checkbox {left: 70px;}
    .treetableCustom li li li .dynatree-node.dynatree-has-children > .dynatree-checkbox {left: 100px;}
    .treetableCustom li li li li .dynatree-node.dynatree-has-children > .dynatree-checkbox {left: 130px;}
    .treetableCustom li li li li li .dynatree-node.dynatree-has-children > .dynatree-checkbox {left: 160px;}
    .treetableCustom li li li li li li .dynatree-node.dynatree-has-children > .dynatree-checkbox {left: 190px;}
    .treetableCustom li li li li li li li .dynatree-node.dynatree-has-children > .dynatree-checkbox {left: 220px;}
    .treetableCustom li li li li li li li li .dynatree-node.dynatree-has-children > .dynatree-checkbox {left: 250px;}
    .treetableCustom li li li li li li li li li .dynatree-node.dynatree-has-children > .dynatree-checkbox {left: 280px;}

    .treetableCustom li .dynatree-node > .code {
        left: 45px;
    }

    .treetableCustom li li .dynatree-node > .code {
        left: 70px;
    }

    .treetableCustom li li li .dynatree-node > .code {
        left: 100px;
    }

    .treetableCustom li li li li .dynatree-node > .code {
        left: 125px;
    }

    .treetableCustom li li li li li .dynatree-node > .code {
        left: 150px;
    }

    .treetableCustom li li li li li li .dynatree-node > .code {
        left: 195px;
    }

    .treetableCustom li li li li li li li .dynatree-node > .code {
        left: 210px;
    }

    .treetableCustom li li li li li li li li .dynatree-node > .code {
        left: 240px;
    }

    .treetableCustom li li li li li li li li li .dynatree-node > .code {
        left: 270px;
    }

    .treetableCustom li .dynatree-node.dynatree-has-children > .code {
        left: 40px;
    }

    .treetableCustom li li .dynatree-node.dynatree-has-children > .code {
        left: 70px;
    }

    .treetableCustom li li li .dynatree-node.dynatree-has-children > .code {
        left: 100px;
    }

    .treetableCustom li li li li .dynatree-node.dynatree-has-children > .code {
        left: 130px;
    }

    .treetableCustom li li li li li .dynatree-node.dynatree-has-children > .code {
        left: 160px;
    }

    .treetableCustom li li li li li li .dynatree-node.dynatree-has-children > .code {
        left: 190px;
    }

    .treetableCustom li li li li li li li .dynatree-node.dynatree-has-children > .code {
        left: 220px;
    }

    .treetableCustom li li li li li li li li .dynatree-node.dynatree-has-children > .code {
        left: 250px;
    }

    .treetableCustom li li li li li li li li li .dynatree-node.dynatree-has-children > .code {
        left: 280px;
    }

    .treetableCustom li .dynatree-node .code + .dynatree-title {
        padding-left: 140px;
    }

    .treetableCustom li li .dynatree-node .code + .dynatree-title {
        padding-left: 175px;
    }

    .treetableCustom li li li .dynatree-node .code + .dynatree-title {
        padding-left: 210px;
    }

    .treetableCustom li li li li .dynatree-node .code + .dynatree-title {
        padding-left: 245px;
    }

    .treetableCustom li li li li li .dynatree-node .code + .dynatree-title {
        padding-left: 280px;
    }

    .treetableCustom li li li li li li .dynatree-node .code + .dynatree-title {
        padding-left: 320px;
    }

    .treetableCustom li li li li li li li .dynatree-node .code + .dynatree-title {
        padding-left: 355px;
    }

    .treetableCustom li li li li li li li li .dynatree-node .code + .dynatree-title {
        padding-left: 380px;
    }

    .treetableCustom li li li li li li li li li .dynatree-node .code + .dynatree-title {
        padding-left: 415px;
    }

    .treetableCustom .dynatree-node > .code {
        position: absolute;
        top: 8px;
    }

    .treetableCustom .dynatree-node.dynatree-has-children > .code {
        top: 12px;
        cursor: pointer;
    }

    .treetableCustom .dynatree-node > .dynatree-checkbox {
        position: absolute;
        top: 8px;
    }

    .treetableCustom .dynatree-node.dynatree-has-children > .dynatree-checkbox {
        top: 12px;
    }

    .treetableCustom li .dynatree-node a.dynatree-title:hover,
    .treetableCustom li .dynatree-node .dynatree-expander:hover ~ a.dynatree-title,
    .treetableCustom li .dynatree-node .code:hover ~ a.dynatree-title,
    .treetableCustom li .dynatree-active a.dynatree-title,
    .treetableCustom li .dynatree-selected .dynatree-title,
    .treetableCustom li .dynatree-selected .dynatree-title .dynatree-subtitle {
        background-color: $grey-super-light !important;
        color: $prime-color !important;
        text-decoration: none !important;
    }

    .treetableCustom li .dynatree-node .dynatree-title:focus {
        background-color: inherit;
        color: inherit;
        outline: none;
    }

    .treetableCustom ul.dynatree-container .dynatree-title:hover {
        color: inherit;
    }
}
