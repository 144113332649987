$common-w: 256px;
%focus {
    border: 1px solid $prime-color-super-light;
}

%common-cell {
    display: inline-block;
    text-align: center;
    line-height: 22px;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    width: 24px;
    height: 24px;
}

.calendar {
    &-container {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: 888;
    }
    &-wrapper {
        position: relative;
        width: 100%;
        color: $base-dark;
    }
    &-inner {
        position: relative;
    }
    &_error {
        .calendar__error-text {
            display: block;
        }
        .calendar-input {
            border: 1px solid $base-error;
        }
    }
    &__error-text {
        display: none;
        left: 0;
        position: absolute;
        top: 45px;
        color: $base-error;
        font-size: 11px;
    }
    &-input {
        width: 100%;
        height: 40px;
        position: relative;
        background: $white;
        border: 1px solid $grey-extra-light;
        border-radius: 3px;
        padding: 5px 40px 5px 15px;
        font-size: 13px;
        &_error {
            border: 1px solid $base-error;
        }
        &_one {
            padding-left: 55px;
        }
        &_two {
            display: flex;
            align-items: center;
            padding-left: 0;
            .wrapper-input {
                position: relative;
                padding-left: 35px;
                .label {
                    transform: translate(10px, -71%);
                }
                .mark {
                    position: absolute;
                    top: 15%;
                    left: 0;
                    padding: 0;
                    background: none;
                }
            }
        }
        .wrapper-input {
            width: 100%;
            height: 100%;
        }
        .label {
            position: absolute;
            top: 18px;
            left: 0;
            transform: translate(10px, -42%);
        }
        &__text {
            width: 100%;
            height: 98%;
            outline: none;
            font-size: 13px;
            border: 1px solid transparent;
            color: $base-dark;
        }
        &__icon {
            &-btn {
                position: absolute;
                top: 8px;
                right: 8px;
            }
        }
    }

    &-days {
        position: absolute;
        background: $white;
        box-shadow: 0 4px 12px rgba(51, 64, 89, 0.2);
        min-height: 270px;
        width: $common-w;
        font-size: 11px;
        &-controls {
            padding: 16px 20px 23px 20px;
            button {
                &:first-child {
                    margin-right: 11px;
                }
            }
            &:before {
                content: 'Выбрать даты:';
                display: inline-block;
                position: relative;
                top: 0;
                right: 0;
                margin-right: 16px;
            }
        }

        &-toggles {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px 16px 20px;
            .togler {
                .c-btn {
                    width: 24px;
                    height: 24px;
                }
                &_next {
                    img {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
    &-dates {
        .dates-head {
            color: $grey-main-light;
            padding: 0 16px;
            border-bottom: 1px solid $border-color;
            .cell {
                background: transparent!important;
            }
        }
        .dates-content {
            padding: 5px 16px;
        }
        .row {
            display: flex;
            margin: 0!important;
        }
        .cell {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 24px;
            width: calc(100% / 7);
            text-align: center;
            &__item {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 24px;
                width: 24px;
                border-radius: 50%;
                cursor: pointer;
                border-width: 1px;
                border-style: solid;
                border-color: transparent;
                &_today {
                    border-color: $prime-color;
                }
                &_selected {
                    background: $prime-color!important;
                    color: $white;
                }
                &:hover {
                    background: $grey-super-light;
                }
            }
            &_range {
                background: $grey-super-light;
                &_top_end {
                    border-top-right-radius: 10px;
                }
                &_top_start {
                    border-top-left-radius: 10px;
                }
                &_bottom_end {
                    border-bottom-right-radius: 10px;
                }
                &_bottom_start {
                    border-bottom-left-radius: 10px;
                }
            }
            &_hover_range{
                background: $grey-super-light
            }
            &_hover_range.cell_selected, &_hover_range.cell_selected_till{
                &:before {
                    background: $grey-super-light
                }
            }
            &_selected {
                .cell__item {
                    background: $prime-color!important;
                    color: $white;
                }
                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 50%;
                    height: 100%;
                    background: $white;
                }
                &_till {
                    &:before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 50%;
                        width: 50%;
                        height: 100%;
                        background: $white;
                    }
                }
            }
        }
    }
}

.c-btn {
    cursor: pointer;
    outline: none;
    &_icon {
        background: transparent;
        border: 1px solid transparent;
    }
    &_fill {
        background: $grey-super-light;
        border-radius: 3px;
        border: 1px solid transparent;
        width: 31px;
        height: 24px;
        color: $prime-color;
        &_active {
            background: $prime-color!important;
            color: $white!important;
        }
    }
    &:focus {
        @extend %focus;
    }
}
