.mw-80{
    max-width: 80%;
}

.mw-83{
    max-width: 83%;
}
@mixin  width-percent($num){
    @for $i from 1 through $num {
        .wp-#{$i} {
            width: $i*1%;
        }
        .wp-#{$i}-d-5 {
            width: $i+0.5%;
        }
    }
}
@include width-percent(30);
