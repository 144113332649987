.extended-search-param {
    padding-bottom: rem-local(64) !important;
    .params-text {
        color: $base-dark;
    }
    .description-text, .single-component > .filter-title--small{
        color: $grey-main-light;
        @extend .text-base-micro;
    }

    $selectors: ('.filter-title--small.ikz-title', '.filter-title--small.extendable-group-title',
    '.filter-title--small.extendable-panel-title', '.filter-title--small.many-checkbox-title',
    '.filter-title--small.kbk-title');
    @each $selector in $selectors {
        .single-component {
            & > div > #{$selector} {
                display: none;
            }
        }
    }

    .cat-text_slider_total {
        display: none;
    }
    .none-border-top-ext {
        border-top: none;
    }
    .search-block {
        div {
            width: auto;
        }
    }

    a:hover, .pseudo-a:hover {
        text-decoration: none;
        color: $prime-color-dark !important;
        cursor: pointer;
    }

    .filter-title {
        &--small{
            &.solo-title {
                @extend .heading-h3;
            }
        }
    }

    .solo-title{
        .filter-title {
            &--small{
                @extend .heading-h3;
            }
        }
    }

    .loc-block {
        &__title {
            ~ .cat-text {
                padding: 0 !important;
            }
        }
    }

    .group-separator {
        &_left {
            border-left: 1px solid $grey-extra-light;
            padding-left: 32px;
            &--empty{
                padding-left: 32px;
            }
        }

        &_right {
            border-right: 1px solid $grey-extra-light;
            padding-right: 32px;
            &--empty{
                padding-right: 32px;
            }
        }
    }
    .mr-b{
        margin-bottom: rem-local(32);
    }
    .mr-t{
        margin-top: rem-local(32);
    }
    .removeEl{
        float: none !important;
        margin-top: 0 !important;
    }

    .form-group_small{
        label{
            @extend .text-base-min;
            margin-bottom: rem-local(4);
            color: $grey-main;
        }
    }
    .mw-content{
        max-width: fit-content;
    }
    &__title{
        @extend .heading-h1;
        color: $base-dark;
        line-height: 48px;
        margin-bottom: rem-local(40);
    }
}

