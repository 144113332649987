.news-page {
    background: #ffffff;
    .page-search {
        border-bottom: 1px solid #E4E7F2;
        padding-bottom: 47px;
        .news-search {
            .news-button {
                line-height: 40px;
            }
        }
        .news-detail {
            .news-title {
                color: $base-dark;
            }
        }
    }
}

.news-link {
    &:hover {
        .news-content {
            .news-title {
                color: #014DA8;
            }
            .news-text {
                color: #6F7C98;
            }
        }
    }
}

.news-group {
    border-bottom: 1px solid #E4E7F2;
    padding-top: 45px;
    padding-bottom: 48px;

    .news-content {
        .news-title {
            margin-top: 11px;
            margin-bottom: 16px;
        }
        .pinkBg{
            background: none !important;
            background-color: #fdd7c9 !important;
            padding: 0 0 0 0 !important;
            font-weight: inherit !important;
            text-transform: none !important;
            cursor: default !important;
        }
    }

}

.news-header {
    .news-date {
        margin-right: 21px;
        color: $grey-main-light;
        @extend .text-base-micro;
    }
    .news-status {
        color: $prime-color;
        text-transform: uppercase;
        @extend .text-cap-micro;
        .icon {
            position: relative;
            img {
                max-width: 12px;
                max-height: 12px;
                vertical-align: baseline;
            }
        }
        &_alert {
            color: #E8523D;
        }
    }
}

.news-search {
    .page-title {
        position: relative;
        margin-bottom: 22px;
        .icon {
            position: absolute;
            top: 14px;
            left: 155px;
        }
    }
}

.all-categories {
    border: 1px solid #E4E7F2;
    border-radius: 3px;
    padding: 18px 0;
    text-transform: none!important;
    .categories {
        padding: 0;
        margin: 0;
        .category {
            padding: 4px 0 4px 22px;
            margin-bottom: 4px;
            list-style: none;
            &:last-child {
                margin-bottom: 0;
            }
            &__link {
                color: $base-dark;
                @extend .text-base-min;
                cursor: pointer;
            }
            &_active {
                border-left: 2px solid $prime-color;
                .category__link {
                    color: $prime-color;
                }
            }
        }
    }
}

@media (max-width: 1140px) {

    .news-search {
        .form-group {
            margin-right: 5px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
}
