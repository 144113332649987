.page-tabs {
    .text {
        &_grey {
            color: $grey-main-light;
        }
        &_dark {
            color: $base-dark;
        }
        &_prime {
            color: $prime-color;
        }
        &_prime_dark {
            color: $prime-color-dark;
        }
        &_small {
            @extend .text-base-micro;
        }
        &_large {
            @extend .heading-h4;
        }
    }
    .block-text {
        margin-bottom: 20px;
        &__title {
            margin-bottom: 5px;
            @extend .text-base-min;
            &_small {
                @extend .text-base-micro;
            }
        }
        &__text {
            @extend .text-base-min;
        }
        .icon {
            margin-right: 10px;
            img {
                max-width: 40px;
            }
        }
        &_border {
            border-right: 1px solid #E4E7F2;
        }
        .time-block {
            cursor: pointer;
            &:hover {
                color: $prime-color-dark;
            }
        }
    }
    .table {
        width: 100%;
        thead {
            tr {
                th {
                    padding: 10px 0;
                    @extend .text_grey;
                    @extend .text-cap-micro;
                    &:last-child {
                        text-align: right;
                    }
                }
            }
        }
        tbody {
            tr {
                border-top: 1px solid #E4E7F2;
                border-bottom: 1px solid #E4E7F2;
                td {
                    padding: 10px 0;
                    @extend .text_dark;
                    @extend .text-base-min;
                    &:last-child {
                        text-align: right;
                    }
                }
            }
        }
        &-total {
            border-bottom: none!important;
            &_sum {
                @extend .heading-h4;
            }
        }
    }
    .block {
        margin-top: 60px;
        background-color: #ffffff;
        &__title {
            margin-bottom: 40px;
        }
        &-inner {
            padding: 20px 0;
            border-top: 1px solid #E4E7F2;
            &_inside {
                margin-top: 26px;
            }
            &__title {
                display: inline-block;
                margin-bottom: 20px;
            }
            &_no-border {
                border: none
            }

            .text-inner {
                margin-left: 10px;
                padding-left: 65px;
                display: inline-block;
                &_large {
                    padding-left: 110px;
                }
            }
            .text__label {
                position: relative;
                &_large {
                    @extend .text-base-min;
                    &:first-child {
                        margin-bottom: 5px;
                    }
                }
                .label {
                    position: absolute;
                }
            }
        }
        &-wrap {
            height: 100%;
            padding-left: 32px;
            padding-right: 32px;
            &_no-padding {
                padding-left: 0;
                &_right {
                    padding-right: 0;
                }
            }
            &_border {
                border-left: 1px solid #E4E7F2;
            }
        }
    }
}

@import 'common-info';
@import 'define-deliver';
@import 'events-magazine';
@import 'lots';
