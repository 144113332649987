.dropdown {
    position: relative;
    &-inner {
        display: flex;
        align-items: baseline;
        cursor: pointer;
        .close__icon{
            min-width: 8px;
            min-height: 8px;
            &:hover path{
                fill: $prime-color;
            }
        }
    }
    &__text {
        display: inline-block;
        padding-right: 5px;
        @extend .text-base-min;
        color: $base-dark;
        &_selected {
            color: $prime-color;
            // max-width: 200px;
            display: inline-block;
            vertical-align: text-top;
            line-height: 14px;
            path{
                fill: $prime-color;
            }
            #sort-by-dropdown &{
                padding-right: 0.55rem;
            }
        }
    }
}
.dropdown-list {
    display: none;
    cursor: pointer;
    position: absolute;
    top: 35px;
    background-color: #ffffff;
    margin: 0;
    padding-left: 0;
    padding: 25px 0;
    box-shadow: 0px 4px 12px rgba(51, 64, 89, 0.2);
    border-radius: 3px;
    max-width: 264px;
    z-index: 800;

    &_region {
        box-shadow: 0px 4px 12px rgba(51, 64, 89, 0.2);
    }
    &__item {
        padding: 8px 24px;
        list-style: none;
        @extend .text-base-min;
        &:hover {
            color: $prime-color;
            background: $grey-super-light !important;
            .saved-settings__title {
                color: $prime-color !important;
            }
            path{
                fill: $prime-color;
            }
        }
        &_selected {
            color: #909EBB !important;
            background: $grey-super-light;
            .saved-settings__title{
                color: $grey-main-light !important;
            }
        }

        svg {
            margin-right: 16px;
        }
    }
    #saved-settings &{
        width: 39rem;
        max-width: none;
        left: -25rem;
        &__item {
            padding: 6px 24px;
        }
    }
    #sort-by-dropdown & {
        &__item{
            white-space: nowrap;
            &_selected {
                color: $prime-color !important;
                background: none;
                path{
                    fill: $prime-color
                }
            }
        }
        padding: 0 !important;
        top: 22px !important;

    }

  .saved-settings {
    &__title {
      color: $base-dark;
    }
      &__href{
         width: 100%;
     }
  }
    &_visible {
        display: block;
    }
}
.sort-by-fixed-width {
    ul.dropdown-list {
        min-width: 264px;
        li {
            white-space: inherit !important;
        }
    }
}
