.lots {
    .table-lots {
       .table-head {

       }
       .table-body {
           .table-data {
            //    border-bottom: 1px solid #EFF0F2;
           }
       }
    }
    .text-inner {
        padding-left: 16px!important;
    }
}
.offer {
    padding: 6px 0;
    border-top: 1px solid #E4E7F2;
    border-bottom: 1px solid #E4E7F2;
    &-btn {
        cursor: pointer;
    }
    &__title {
        @extend .text_prime;
        &_active {
            @extend .text_dark;
        }
    }
}