@import './sortBy';
@import './registerBox';
@import './paginator';
@import './search';
@import './currentPar';
@import './popup';
@import './loadProperties';
@import './qa';
@import './obozLK';
@import './opros';

.formValidation input[type=button]{
    cursor:pointer;
}

.searchBlockAll + .parametrs {
        padding: 0;
}

.margBtm4 {
    margin-bottom: 4px;
}

.margBtm8 {
    margin-bottom: 8px;
}

.margBtm10 {
    margin-bottom: 10px;
}

.margBtm20 {
    margin-bottom: 20px;
}

.padTop20 {
    padding-top: 20px !important;
}

.padLeft0 {
    padding-left: 0;
}

.padLeft4 {
    padding-left: 4px;
}

.padLeft5 {
    padding-left: 5px !important;
}
.padTop5 {
    padding-top: 5px;
}
.modal-header.padBtm5, .padBtm5 {
    padding-bottom: 5px;
}
.padBtm0 {
    padding-bottom: 0px;
}


.padLeft10 {
    padding-left: 10px;
}

.padRight10, table.padRight10 td {
    padding-right: 10px;
}

.padRight14 {
    padding-right: 14px !important;
}

.padRight16 {
    padding-right: 16px !important;
}

.marginBottom0 {
    margin-bottom: 0;
}

.displayInlineBlock {
    display: inline-block;
}

.inlineBlock {
    display: inline-block;
}

.displayNoneUsual {
    display: none;
}

.padTop8 {
    padding-top: 8px;
}

.padTop40 {
    padding-top: 40px !important;
}

.padTop41 {
    padding-top: 41px !important;
}

.padTop47 {
    padding-top: 47px !important;
}

.padTop108 {
    padding-top: 108px !important;
}

.margTop20 {
    margin-top: 20px;
}

.padRight20 {
    padding-right: 20px;
}
.padBtm5 {
    padding-bottom: 5px;
}
.withoutBorder {
    border: none !important;
}
.width630 {
    width: 635px !important;
}
.width500 {
    width: 500px !important;
}
.width145 {
    width: 145px !important;
}
.margLeft20 {
    margin-left: 20px;
}
.margLeft10 {
     margin-left: 10px;
 }
.width-245-px {
    width: 245px;
}
.width-140px{
    width: 140px !important;
    select{
        width: 140px !important;
    }
    .select{
        width: 140px !important;
    }
}
.displayNone{
    display: none;
}

.marginLeft10px{
    margin-left: 10px;
}

.word-break-all {
    word-break: break-all;
}

/*keyboard control styles start*/
.showToolTipKeyboardControl ul, .showToolTipKeyboardControl .toolTipDelim {
    display: inline;
}

.outlineGreenKeyboardControl {
    outline: 3px solid green;
}

.backgroundColorGreenKeyboardControl {
    background-color: green;
}

.outlineNoneKeyboardControl {
    outline: none !important;
}

.backgroundColorNoneKeyboardControl {
    background-color: transparent !important;
}

div.jqcarousel ul.carousel td a.outlineRedKeyboardControl {
    outline: none;
}

div.jqcarousel ul.carousel td a.outlineRedKeyboardControl img {
    border: 3px solid rgba(229, 91, 68, 0.77);
}

.outlineRedKeyboardControl {
    outline: 3px solid rgba(229, 91, 68, 0.77);
}

.logo.outlineRedKeyboardControl {
    outline: 3px solid rgba(229, 91, 68, 0.77) !important;
}

.outlineRedBtnUpKeyboardControl {
    outline: 3px solid rgba(229, 91, 68, 0.77);
    width: 100px;
}

.backgroundColorRedKeyboardControl:not(.dynatree-node) {
    background-color: rgba(229, 91, 68, 0.77);
}

.dynatree-container .dynatree-node.backgroundColorRedKeyboardControl .dynatree-expander {
    background-color: rgba(229, 91, 68, 0.77);
}

.dynatree-container a.dynatree-title.outlineRedKeyboardControl {
    background-color: rgba(229, 91, 68, 0.77);
}

.outlineBlueKeyboardControl {
    outline: 3px solid blue;
}

.backgroundColorBlueKeyboardControl {
    background-color: blue;
}

.outlineOrangeKeyboardControl {
    outline: 3px solid orange;
}

.backgroundColorOrangeKeyboardControl {
    background-color: orange;
}

.outlineAquaKeyboardControl {
    outline: 3px solid aqua;
}

.backgroundColorAquaKeyboardControl {
    background-color: aqua;
}

.outlineDarkOrangeKeyboardControl {
    outline: 3px solid DarkOrange;
}

.backgroundColorDarkOrangeKeyboardControl {
    background-color: DarkOrange;
}

.outlinePinkKeyboardControl {
    outline: 3px solid pink;
}

.backgroundColorPinkKeyboardControl {
    background-color: pink;
}

.outlineFuchsiaKeyboardControl {
    outline: 3px solid Fuchsia
}

.backgroundColorFuchsiaKeyboardControl {
    background-color: Fuchsia;
}

.borderColorRedKeyboardControl {
    border-color: red;
}

.borderRedKeyboardControl {
    border: red 2px solid;
}

.outlineAbstractKeyboardControl {
    outline: 3px solid #fffa20;
}

.backgroundColorAbstractKeyboardControl {
    background-color: #fffa20;
}

.choiceTableKeyboardControl td {
    background-color: rgba(229, 91, 68, 0.77);
}
/*keyboard control styles end*/
.width873 {
    width: 873px!important;
}
.margLeft245 {
    margin-left: 245px !important;
}
.margBtm0 {
    margin-bottom: 0 !important;
}
.padTop0 {
    padding-top: 0 !important;
}
.margTop10, input.margTop10, .popupModalOverlay input[type=text].margTop10 {
    margin-top: 10px;
}
.width520 {
    width: 520px;
}
.width50 {
    width: 50px;
}
.width260 {
    width: 260px;
}
.maxHeight230 {
    max-height: 230px;
}
.overflowX {
    overflow-x: auto !important;
}
.width20 {
    width: 20px !important;
    min-width: 20px;
}
.padBtm15 {
    padding-bottom: 15px !important;
}
.padBtm44 {
    padding-bottom: 44px !important;
}
.padTop5 {
    padding-top: 5px !important;
}
.parametersTabs, .parametersTabs td, .parasmetersAction, .parasmetersAction td {
    vertical-align: top;
}
.margLeft5 {
     margin-left: 5px;
 }
textarea {
    background-color: #fff;
    border: 2px solid #d3d7db;
    border-radius: 5px;
    box-sizing: border-box;
    color: #5b5b5b;
    //font-family: 'Roboto Slab', sans-serif;
    font-size: 12px;
    padding: 2px 5px;
    width: 99%;
    outline: none;
    resize: none;
}
.vAlignTop {
    vertical-align: top !important;
}
a:hover {
    text-decoration: none;
}
.doubleArrowDown {
    background: url("../images/newDesign/double-arrow.png") no-repeat 0 50%;
}
.doubleArrowUp {
    background: url("../images/newDesign/double-arrow-rotate.png") no-repeat 0 50%;
}
.help {
    background: url("../images/newDesign/header_icons_sprite.png") no-repeat -137px -45px;
    cursor: pointer;
    display: inline-block;
    height: 16px;
    vertical-align: top;
    width: 16px;
    margin-left: 0;
}
label.widthAuto {
    width: auto;
}
.browserIco {
    background: url("../images/newDesign/browser_ico.png") no-repeat 0 2px;
    color: #5f6a74;
    font-size: 14px;
    margin-bottom: 20px;
    padding: 0 0 0 55px;
}

.eMailIco {
    background: url("../images/newDesign/e_mail_ico.png") no-repeat 0 24px;
    color: #5f6a74;
    font-size: 14px;
    margin-bottom: 20px;
    padding: 20px 0 0 55px;
}

.eMailIco strong {
    display: block;
    padding-top: 5px;
}
.width165 {
    width: 165px;
}
label.width300 {
    width: 300px;
}
label.width60 {
    width: 60px;
}
label.width150 {
    width: 150px;
}
label.width100 {
    width: 100px;
}
label.width250 {
    width: 250px;
}
.itemCode {
    display: inline-block;
}
.width450 {
    width: 450px;
}
label.width180 {
    width: 180px;
}
.fullWidth{
    width: 100% !important;
}
.width160 .select {
    width: 160px;
}
.selectRange .width160 .select {
    text-overflow: ellipsis;
}
.saveExtendedSearch .kbkLabels {
    font-size: 80%;
    margin-left: 245px;
}

.expandRow .saveExtendedSearch .kbkLabels {
    font-size: 80%;
    margin-left: 245px;
}
label.kbkHeadLabel {
    margin-right: 0.3em;
    width: 57px;
}
label.kbkSectionLabel {
    margin-right: 0.4vw;
    width: 40px;
}
label.kbkItemLabel {
    margin-right: 1.3em;
    width: 40px;
    margin-left: 3em;
}
label.kbkTypeLabel {
    margin-right: 1.3em;
    width: 40px;
    margin-left: 5.5em;
}
label.kbkKosguLabel {
    margin-right: 1.3em;
    width: 40px;
    margin-left: 2.5em;
}
label.kbkArticleLabel {
    margin-right: 0.1vw;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    label.kbkArticleLabel {
        margin-right: 5px !important;
    }
}
span.kbkHead {
    margin-right: 1.5em;
    width: 101px;
}
span.kbkSection {
    margin-right: 1.3em;
    width: 156px;
}
span.kbkItem {
    margin-right: 1.5em;
    width: 101px;
}
span.kbkType {
    margin-right: 1.5em;
    width: 101px;
}
span.kbkKosgu {
    margin-right: 1.5em;
    width: 101px;
}
.col3 td {
    padding: 10px 0;
}
ul.paramNewChoice li.checkbox-poor:not(.display-block) {
    display: inline-block;
    margin-right: 15px;
}
.errorMessageValidation {
    color: #e2351e !important;
}

#individualForm .inlineInputGroup label,#juridicalForm .inlineInputGroup label{
    width: 20% !important;
    margin-top: 1.5%;
}
.width580 {
    width: 580px !important;
}

.width200{
    width: 200px;
}
td.width200, .width200, input.width200, label.width200, .inlineInputGroup .width200, img.width200{
    width: 200px;
}
td.width165, .width165, input.width165, label.width165, .inlineInputGroup .width165, img.width165{
    width: 165px;
}
.capchaImg {
    border: 1px solid #747474;
    margin-left: 3px;
    vertical-align: middle;
    width: 141px;
    height: 32px;
}
.documentsInfo {
    white-space: normal;
    vertical-align: top;
    padding-right: 0;
}
.padRight12, span.padRight12{
    padding-right: 12px;
}
.padRight13, span.padRight13{
    padding-right: 13px;
}
.padLeft20, span.padLeft20{
    padding-left: 20px;
}

.margRight5{
    margin-right: 5px;
}

.margRight8{
    margin-right: 8px;
}

.highlightColor {
    background-color: rgba(248, 212, 0, 0.3);
}
.filterBlock{
    font-size: 12px;
}
.maxWidth200{
    max-width: 200px;
}
.width65 {
    width: 65px !important;
}
.margLeft220 {
    margin-left: 220px !important;
}
.margBot15 {
    margin-bottom: 15px;
}
.fontSize10 {
    font-size: 10px;
}
input.height30 {
    height: 30px;
    line-height: 24px;
}

input.orangeBtn.height30 {
    line-height: 19px;
}

.height30 {
    height: 30px;
    line-height: 26px;
}

.moreRubrics {
    height: 129px;
    .countKTRU {
        color: #0065dd;
    }
}

.pseudoInputS.height30, .pseudoInputWarning.height30 {
    height: 28px;
    line-height: 28px;
}
.floatRightImportant {
    float: right !important;
}

.floatLeftImportant {
    float: left !important;
}
.top0{
    top: 0 !important;
}
.maxHeight100Percent{
    max-height: 100% !important;
}
.borderRadius4{
    border-radius: 4px !important;;
}
.border2SolidGray{
    border: 2px solid #d3d7db !important;;
}
.marginBottom20{
    margin: 0 0 20px 0 !important;;
}
.padTop0Other25{
    padding: 0 25px 25px 25px !important;;
}
.borderBottom2Gray{
    border-bottom: 2px solid #d3d7db !important;;
}
.borderTopNone{
    border-top: none !important;;
}
.borderRightNone{
    border-right: none !important;;
}
.borderLeftNone{
    border-left: none !important;;
}
.marginBottomMinus5{
    margin-bottom: -5px !important;
}
.padTop0Left0Other25{
    padding: 0 25px 25px 0 !important;
}
.width210{
    width: 210px !important;
}
.floatLeftImportant {
    float: left !important;
}
.width220 {
    width: 220px !important;
}
.fontSize14{
    font-size: 14px !important;
}
.maxWidth200{
    max-width: 200px;
}
.cursorPointer{
    cursor: pointer;
}
.padRight5 {
    padding-right: 5px;
}
.expandArrow {
    background: url("../images/newDesign/expand_arrow.png") no-repeat 0 50%!important;
    cursor: pointer;
    margin-left: 10px;
    margin-top: 8px;
    float: left;
    vertical-align: middle;
    width: 20px;
    height: 15px;
}
.collapceArrowColored {
    background: url("../images/newDesign/collapse_arrow_alt.png") no-repeat 0 50% !important;
    color: #d0562b;
}
.margTop5 {
    margin-top: 5px;
}
.margTop7 {
    margin-top: 7px;
}
#kbk .kbkLabels label{
    width: 8.6vw;
    min-width: 160px;
}
.ikzInfo {
    background: url("../images/newDesign/header_icons_sprite.png") no-repeat 0 -45px;
    cursor: pointer;
    display: inline-block;
    height: 16px;
    vertical-align: top;
    width: 16px;
}
.tuningQuickSearchBlock {
    position: relative;
}

.tuningQuickSearchBox {
    background-color: #fff;
    display: none;
    position: absolute;
    width: 100%;
    z-index: 100;
}

.tuningQuickSearchBox .tuningQuickSearchList {
    border: 2px solid #d3d7db;
    border-radius: 0 0 3px 3px;
    border-top: none 0;
    max-height: 227px;
    overflow-y: auto;
}

.tuningQuickSearchBox .tuningQuickSearchList li {
    border-bottom: 1px solid #eff0f1;
    color: #666a6f;
    line-height: 16px;
    padding: 6px 10px 6px 10px;
}

.tuningQuickSearchToolBox {
    float: right;
}

.editingBtn {
    background: url("../images/newDesign/edit_ico.png") no-repeat 0 0;
    display: inline-block;
    height: 15px;
    margin-right: 10px;
    width: 16px;
}
.descriptTenderTd .help{
    background-size: 110px;
    background-position-x: -97px;
    background-position-y: -32px;
}

.editingBtn:hover {
    background: url("../images/newDesign/edit_ico.png") no-repeat 0 -31px;
}

.checkIco {
    background: url("../images/newDesign/check_ico.png") no-repeat 0 0;
    cursor: pointer;
    display: inline-block;
    height: 18px;
    width: 18px;
}

.delBtn:hover {
    background: url("../images/newDesign/close_blue_ico.png") no-repeat 0 0;
}

.tuningQuickSearchTitle, .editingBtn, .delBtn {
    cursor: pointer;
}

.rubricatorSidebarBackground{
    height: 100%;
    background: #eff0f1;
    margin: 37px 0;
    border-radius: 3px;
}
.blockKTRU{
    height: 300px;
    width: 210px;
    background: #fff;
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
}
.pad10{
    padding: 10px;
}
.searchKTRU{
    background-color: #fff;
    border: 2px solid #cecece;
    height: 100%;
}
.imageKTRU{
    height: 50px;
    width: 50px;
    display: block;
    margin: 12px auto;
}
.headerKTRU{
    text-align: center;
    color: #41484e;
    overflow: hidden;
    width: 100%;
}
.headerKTRU:hover{
    color: #0065dd;
}
.countKTRU{
    padding-bottom: 15px;
    color: #909ebb;
    font-size: 12px;
    text-align: center;
}
.parentRubricatorKtruLi{
    color:#41484e;
    font-weight: 500;
    text-transform:uppercase;
    font-size: 12px;
}
.childrenRubricatorKtruLi{
    color: #41484e;
    padding: 0 0 5px 10px;
    font-size: 11px;
    display: block;
}
a.hrefKTRU:hover{
    text-decoration: none;
}
.arrowKTRU{
    display: none;
    color: #0065dd;
    font-size: 12px;
    margin: 0 0 10px 10px;
    cursor: pointer;
}
.rubricatorSidebarArrow{
    height: 11px;
    transform: rotate(180deg);
    margin-right: 5px;
}
.rubricatorSidebar .dynatree-expander{
    display: none;
}
.rubricatorSidebar .treetableCustom .dynatree-connector::after, .treetableCustom .dynatree-connector:after{
    display: none;
}
.rubricatorSidebar span.dynatree-connector {
    display: none;
}
.rubricatorSidebar ul.dynatree-container{
    border: none;
    overflow: hidden;
    text-overflow: ellipsis;
}
.rubricatorSidebar ul.dynatree-container li{
    background-image:none;
    padding: 0;
    white-space: normal !important;
    word-wrap: break-word !important;
}
.rubricatorSidebar #rubricatorTree {
    width: 90%;
    max-height: 100%;
    background: #fff;
    margin-left: 10px;
    overflow: auto;
}
.rubricatorSidebar span.dynatree-title{
    display: inline-block;
    min-height: 24px;
    padding-left: 12px;
    width: 100%;
}
.rubricatorSidebar .dynatree-selected span.dynatree-title{
    border-left: 2px solid #0065dd;
    color: #0065dd;
    padding-left: 10px;
}
.positionAbsolute {
    position: absolute;
}
.displayBlockImportant {
    display: block !important;
}
.ktruRow .formValidation, .parametrs {
    min-width: auto;
}
.ktruRow .parametrs {
    min-width: auto;
    .registerBox *{
        table-layout: fixed !important;
        word-wrap: break-word;
    }
}
.ktruRow .errorContainer {
    width: auto !important;
}
//Делает не нужные, пустые отступы
#customerTag, #agencyTag {
    .errorContainer {
        //min-height: 30px;
        //padding-top: 10px;
    }
}

.ktruRow .inlineBlock.width630 {
    width: auto !important;
}
.rubricatorSidebar .dynatree-container li{
    cursor:pointer;
}
.ktruRow label.height30.vAlignTop.padTop0, .ktruRow .saveExtendedSearch label.vAlignTop, .ktruRow li#okpd2IdsTag label.vAlignTop  {
    width: 165px;
}

.modal .quantityWithoutRange .floatLeftImportant{
    width: 580px!important;
}

.KTRUinline-block{
    display: inline-block;
}

.modal #innerTableCharacteristicsKtru .width500{
    width: auto!important;
}
#innerTableRubricator .colorValue span {
    color: #b4b4b4 !important;
}
#innerTableRubricator .dynatree-checkbox {
    display: none !important;
}

#innerTableRubricator .dynatree-lastsib .dynatree-connector, #innerTableRubricator .dynatree-exp-c .dynatree-connector {
    display: none !important;
}

#innerTableRubricator .dynatree-checkbox, #innerTableRubricator .dynatree-has-children .dynatree-checkbox {
    display: none !important;
}

#innerTableRubricator .dynatree-node {
    cursor: pointer !important;
}

#innerTableRubricator .dynatree-container {
    background-color: #eff0f1 !important;
}

.fileUpLoad {
    background-image: url("../images/newDesign/file_load_ico.png");
    background-repeat: no-repeat;
    background-position: 0px 0px;
}

.blueBtn.fileUpLoad {
    background-position: 10px 8px;
    padding-left: 30px;
}

.l-heightNormal{
    line-height: normal !important;
}
.fontBoldTextTd {
    font-weight: bold;
}

.blockIco {
    background-image: url("../images/block-ico-grey.png");
    background-repeat: no-repeat;
    background-position: 0px 0px;
    padding: 1px 20px;
}

.Box.blockIco {
    background-position: 8px 6px;
}

.greyBox.blockIco {
    background-position: 8px 6px;
}

.viewTuning {
    color: #5b5b5b;
    display: inline-block;
    font-size: 13px;
    padding-right: 45px;
    position: relative;
}

.tuningIco {
    background: url("../images/newDesign/option_ico.png") no-repeat 13px 9px;
    cursor: pointer;
    height: 35px;
    position: absolute;
    right: 0;
    top: -8px;
    width: 45px;
    z-index: 10;
}

.tuningIco:hover {
    background-color: #eff0f1;
    border-radius: 5px 5px 0 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
}

.tuningIco:hover ul, .tuningIco:hover .tuningDelim {
    display: inline-block;
}

.tuningIco ul {
    background-color: #eff0f1;
    border-radius: 0 5px 5px 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    display: none;
    font-size: 12px;
    left: 0;
    padding: 15px 15px 5px;
    position: absolute;
    top: 35px;
    width: 310px;
    z-index: 10;
}

.tuningIco li {
    color: #5f6a74;
    cursor: pointer;
    padding: 0 0 10px 30px;
    text-decoration: underline;
}

.tuningIco li:hover {
    text-decoration: none;
}

.tuningIco .option1 {
    background: url("../images/newDesign/option_ico_sprite.png") no-repeat 0 0;
}

.tuningIco .option2 {
    background: url("../images/newDesign/option_ico_sprite.png") no-repeat 0 -95px;
}

.tuningIco .option3 {
    background: url("../images/newDesign/option_ico_sprite.png") no-repeat 0 -195px;
}

.tuningIco .option4 {
    background: url("../images/newDesign/option_ico_sprite.png") no-repeat 0 -289px;
}

.tuningDelim {
    background-color: #eff0f1;
    display: none;
    height: 15px;
    left: 0;
    position: absolute;
    top: 28px;
    width: 45px;
    z-index: 15;
}
.margLeft30 {
    margin-left: 30px !important;
}
.margTop30 {
    margin-top: 30px !important;
}
.margBtmTop30 {
    margin-bottom: 30px !important;
    margin-top: 30px !important;
}
.mb-32px {
    margin-bottom: 32px !important;
}
.padBottom30 {
    padding-bottom: 30px !important;
}
.grayArrow {
    background: url("../images/newDesign/expand_arrow.png") no-repeat 5% !important;
    cursor: pointer;
    background-position-y: 5px;
    float: left;
    margin-top: 5px;
    width: 30px;
    height: 18px;
    background-size: 16px;
}
.labelPasswordHint {
    color: #999999;
    cursor: pointer;
    padding: 7px 5px 5px 12px;
    position: absolute;
    z-index: 1;
}

.text-decoration-underline{
    text-decoration: underline;
}
a.text-decoration-underline:hover{
    text-decoration: none;
}

.width30 {
    width: 30px !important;
}
.vertical-align-middle-block{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 50%;
    height: 15%;
    margin: auto;
}

.padLeft60{
    padding-left: 60px!important;
}

.l-height30{
    line-height: 30px;
}
.w-space-nowrap{
    white-space: nowrap;
}
#startInspectionTag .periodBox td:first-child {
    width: 240px;
    padding-top: 5px;
}
#ktruCT input{
    text-indent: 5px !important;
}

.periodBox td:first-child {
    width: 243px;
}
.periodBox .quarterField {
    width: 100px;
}
.periodBox .yearField {
    width: 110px;
}
.periodBox .quarterField select {
    width: 107px;
}
.labelField {
    width: 20px;
}

.width90{
    width: 90px !important;
}


.blueText {
    color: #018ccd;
    cursor: pointer;
}

.saveExtendedSearch li.search_tag input.hasDatepicker{
    width: 160px;
}


.complaintResult td {
    border-top: none !important;
}

.filterParametrs .ktruRubricatorSelectedSection{
    margin-bottom: 15px;
}

.fz44_icon {
    background: url("/epz/static/images/newDesign/fz-icon.png") no-repeat;
    width: 15px;
    height: 17px;
    display: inline-block;
    line-height: 20px;
}
.color-gray{
    color:#a4a4a4;
}

.colorRed {
    color: red !important;
}

.actual div.headVal {
    font-weight: bold;
}
