.ui{
    &-menu{
        background: #fff;
        border: 1px solid $border-color;
        border-radius: 0 0 3px 3px;
        @extend .text-base-min;
        &-item{
            cursor: pointer;
            padding: 0.8rem;
            .ui-corner-all{
                &__fz{
                    color: $grey-main-light !important;
                    word-spacing: 10px;
                }
                &__title{
                    color: $base-dark;
                }
                &__blocked{
                    width: 24px;
                    height: 24px;
                    vertical-align: middle;
                    background: url(/epz/static/img/icons/status/icon-alert.svg) no-repeat;
                    .warningRed {
                        width: inherit;
                        height: inherit;
                    }
                }
            }
            &:hover{
                background: $grey-super-light;
            }
        }
    }
    &-helper {
        &-hidden{
            &-accessible{
                display: none !important;
            }
        }

    }
    &-autocomplete {
        z-index: 10000;
    }
}

.search-quick ~ .ui-menu {
    .ui-menu-item {
        .ui-corner-all {
            &__fz {
                width: 30% !important;
                &.double-fz{
                    width: 50% !important;
                }
            }
            &__title {
                width: 65% !important;
                &.double-fz{
                    width: 45% !important;
                }
            }
            &__blocked {

            }
        }
    }
}
