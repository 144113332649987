div.submenu_subitem > li > a > span.largeDecor {display: block; padding-left:30px;}
li.submenu-list__item > a > span.largeDecor {display: block;}
div.submenu_subitem > li > a > span:not(.link__desc) {display:inline-block;padding-left:30px;}

.menu-mega > ul > li > ul {left: inherit;}
//.active-subitems{background: rgba(144, 158, 187, 0.2);}

.menu {
    background-color: #ffffff;
    height: 56px;
}

.menu-list , .tabs-list{
    padding: 0;
    margin: 0;
    &__item {
        display: inline-block;
        list-style: none;
        margin-right: 24px;
        padding: 15px 0;
        border-bottom: 2px solid transparent;
        cursor: pointer;
        &:hover {
            border-bottom-color: $prime-color;
            .menu-list__link {
                color: $prime-color;
            }
            .tabs-list__link {
                color: $prime-color;
            }
        }
        &_active {
            border-bottom-color: $prime-color;
            .menu-list__link {
                color: $prime-color!important;
            }
            .tabs-list__link {
                color: $prime-color!important;
            }
        }
    }
    &__link {
        color: $base-dark;
        @extend .text-cap-base;
        &:hover {

            text-decoration: none;
        }
    }
}

.submenu {
    position: absolute;
    top: 55px;
    left: 0;
    z-index: 888;
    display: none;
    &_open {
        display: block;
    }
    &-inner {
        .border_right {
            border-right: 1px solid #8C96AD;
        }
        .border-radius {
            &_right {
                border-bottom-right-radius: 3px;
            }
            &_left {
                border-bottom-left-radius: 3px;
            }
        }
    }
    &-list {
        background: $grey-main;
        //display: inline-block;
        position: relative;
        &__item {
          //padding: 10px 30px 10px 24px;
          cursor: pointer;
          width: 100%;
            a {
              &:hover {
                background: #8C96AD;
              }
            }
            &__disabled{
                padding-top: 10px;
                padding-bottom: 10px;
                padding-right: 30px;
                padding-left: 24px;
                cursor: default;
                &:hover {
                    background: #6f7c98;
                }
            }

          ul{
            a{
              padding-left: 45px;
            }
          }
        }
        &__link {
            @extend .text-base-min;
            text-decoration: none;
            color: #ffffff;
          line-height: 20px!important;
          padding:7px 25px;
         // margin: 0 15px;
          display: block;
            &:hover {
                text-decoration: none;
                color: #ffffff;
            }

            .link__desc {
                opacity: .3;
                white-space: nowrap;
            }

            &--active {
                background-color: #8C96AD;
            }
        }
    }
}

.footer-nav {
    padding: 30px 0;
    height: 88px;
    border-top: 1px solid #DDE0E5;
    border-bottom: 1px solid #DDE0E5;
    &__item {
        display: inline-block;
        padding-right: 18px;
        list-style: none;
    }
    &__link {
        color: $grey-main;
        @extend .text-base-micro;
    }
}

.footer-nav-list {
    padding: 0;
    margin: 0;
}

@media (max-width: 1035px) {
    .menu-list__link {
        font-size: 10px;
    }
}

//NEW MENU

    //.menu-mega > ul > li:hover ul{
    //    display: block;
    //}

  .menu-container {
    width: 100%;
    margin: 0 auto;
  }

  .menu-mobile {
    display: none;
    padding: 15px 0;
    &:after {
      content: "\f394";
      font-family: "Ionicons";
      font-size: 2.5rem;
      padding: 0;
      float: right;
      position: relative;
      top: 50%;
      transform: translateY(-25%);
    }
  }

  .menu-dropdown-icon {
    &:before {
      content: "\f489";
      font-family: "Ionicons";
      display: none;
      cursor: pointer;
      float: right;
      padding: 1.5em 2em;
      background: #fff;
      color: #333;
    }
  }

  .menu-mega{
      position: relative;
    .menu-list {
        .main-link {
            //color: $base-dark;
            //@extend .text-cap-base;
            //text-decoration: none!important;
            color: $base-dark;
            font-size: 13px;
            line-height: 20px;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            .submenu-list__link {
                padding: 15px 0;
                margin-right: 16px;
                display: block;
                text-decoration: none;
              min-width: 350px;
            }
        }
        a._orderSearch{
            cursor: pointer;
        }
        .main-item {
            padding: 15px 0;
            margin-right: 24px;
            border-bottom: 3px solid transparent;

            &:hover {
                .main-link {
                    color: $prime-color;
                }
                border-bottom: 3px solid $prime-color;
            }

        }
        .submenu-col {
            //width:350px;
            height: auto;
            max-height: 400px;
            padding: 15px 0;
            border-right: 1px solid #8c96ad;
            &:last-child {
                border-right: none;
            }

          .child-node {
            .child-node li{
             // padding-left: 25px;
            }
          }
        }

    }
    > ul {
      margin: 0 auto;
      width: 100%;
      list-style: none;
      padding: 0;
      position: relative;
      //position: relative;
      /* IF .menu position=relative -> ul = container width, ELSE ul = 100% width */
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      &:before,&:after {
        content: "";
        display: table;
      }
      &:after {
        clear: both;
      }
      > li {
        float: left;
        padding: 0;
        margin: 0;
        > ul {
          display: none;
          //width: 100%;
          background: #6F7C98;
          position: absolute;
          z-index: 99;
          top: 56px;
          //left: 0;
          margin: 0;
          list-style: none;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          &:before,&:after {
            content: "";
            display: table;
          }&:after {
            clear: both;
          }
          > li {
            margin: 0;
            padding-bottom: 0;
            list-style: none;
            //width: 33.3%;
            background: none;
            float: left;
            > ul {
              display: block;
              padding: 0;
              //margin: 10px 0 0;
              list-style: none;
              -webkit-box-sizing: border-box;
              -moz-box-sizing: border-box;
              box-sizing: border-box;
              &:before,
              &:after {
                content: "";
                display: table;
              }
              &:after {
                clear: both;
              }
              > li {
                float: left;
                width: 100%;
                //padding: 10px 24px;
                margin: 0;
                font-size: .8em;
                a {
                  border: 0;
                  line-height: 34px;
                }
              }
            }
          }
          &.normal-sub {
            width: 300px;
            left: auto;
            padding: 10px 20px;
            > li {
              width: 100%;
              a {
                border: 0;
                padding: 1em 0;
              }
            }
          }
        }
      }
    }
  }

