.etp {
    .switch {
        padding-top: 38px;
        padding-bottom: 38px;

        .slider-text {
            @extend .text-base-min;
            color: $base-dark;
        }
    }

    .text-base-head{
        font-size: 24px;
        line-height: 45px;
        font-style: normal;
        font-weight: 400;
    }

    &-not-actual{
        color: $grey-main-light!important;

        .registry-entry__body-value, .registry-entry__header-mid, .registry-entry__header-href a, .data-block__value{
            color: $grey-main-light!important;
        }
    }
}
