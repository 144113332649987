.figure {
    display: block;
    height: 76px;
    cursor: pointer;
    border-bottom: 1px solid #DAE4EE;
    margin-bottom: 28px;
    &-link {
        text-decoration: none!important;
    }
    &__icon {
        display: inline-block;
        background: #fff;
        width: 56px;
        height: 56px;
        border-radius: 50%;
        text-align: center;
        padding: 16px;
        &_grey {
            background: #EFF0F2;
        }
    }
    &__text {
        display: inline-block;
        margin-left: 16px;
        vertical-align: top;
        max-width: 180px;
        color: $base-dark;
        @extend .text-base-min;
    }
    &:hover {
        .figure__icon {
            background: $prime-color-dark;
            svg {
                path {
                    fill: #ffffff;
                }
            }
        }
        .figure__text {
            text-decoration: underline;
            color: $prime-color-dark;
        }
    }
    &_no-border {
        border-bottom: none;
    }
}
@media(max-width: 1160px) {
    .figure__text {
        max-width: 135px;
    }
}
@media(max-width: 1010px) {
    .figure__text {
        max-width: 180px;
    }
}

.figure-footer {
    &__text {
        margin-left: 15px;
    }
}


.figure-alert {
    .figure__icon {
        background: #fff;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        text-align: center;
        padding: 10px;
        margin-right: 35px;
        .icon {
            max-width: 24px;
        }
    }
    .figure__text {
        @extend .text-base-micro;
        .text {
            display: block;
            &-sub {
                color: rgba(255, 255, 255, 0.5);
            }
        }
    }
}






.icon-text {
    margin: 0 0 1.5rem;
    height: 80px;
    border-bottom: 1px solid $grey-main-extra-light;
    &_no-border {
        border-bottom: none;
    }
    .icon-text {
        &:hover {
            .icon {
                background-color: $prime-color;
            svg {
                path {
                    fill: #ffffff;
                }
            }
            }

        }
    }
    .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 56px;
        height: 56px;
        background-color: #ffffff;
        border-radius: 50%;
        margin-right: 16px;
        &-wrap {
            display: inline-block;
        }
        &:hover {
            background-color: $prime-color;
            svg {
                path {
                    fill: #ffffff;
                }
            }
        }
    }
    .text {
        display: inline-block;
        vertical-align: top;
        max-width: 180px;
        color: $base-dark;
        @extend .text-base-min;
    }
}


.icon-text_row {
    margin: 0 0 0;
}
.no-border {
    border: none;
}

@media (max-width: 1160px) {
    .icon-text {
        .text {
            max-width: 120px;
        }
    }
}
@media (max-width: 1010px) {
    .icon-text {
        .text {
            max-width: 190px;
        }
    }
}
