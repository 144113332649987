@font-face {
    font-family: 'Roboto';
    src: local('Roboto Thin'), local('Roboto-Thin'),
    url('../fonts/roboto2/Roboto-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto';
    src: local('Roboto Light'), local('Roboto-Light'),
    url('../fonts/roboto2/Roboto-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto';
    src: local('Roboto'), local('Roboto-Regular'),
    url('../fonts/roboto2/Roboto-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto';
    src: local('Roboto Medium'), local('Roboto-Medium'),
    url('../fonts/roboto2/Roboto-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto';
    src: local('Roboto Bold'), local('Roboto-Bold'),
    url('../fonts/roboto2/Roboto-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto';
    src: local('Roboto Black'), local('Roboto-Black'),
    url('../fonts/roboto2/Roboto-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
