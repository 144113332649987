.modal{
    &-content{
        &-multiSel{
          .modal-text-block{
              .npaTrMarker{
                  max-width: 95%;
                  span.customCheck{
                      background: url(/epz/static/img/icons/icon_checkboxes_modal.svg) 0 -17px no-repeat;
                      height: 20px;
                      width: 20px;
                      margin: 0;
                      padding: 0 15px;
                  }
                  span.check{
                      background-position: 0 0 !important;
                  }
                  label{
                      margin: 10px 0;
                  }
              }
              .search {
                  #npaInput:not(.colorValue) + span.resetBtn {
                      background: url(/epz/static/img/icons/icon_cross.svg) no-repeat;
                      position: absolute;
                      margin-left: -32px;
                      margin-top: 13px;
                      height: 16px;
                      width: 16px;
                  }
              }
          }
        }
    }
}
