$news-block-height: 448px;

.container-news__mb0__lh0 {
    margin-bottom: 32px !important;
    line-height: 0 !important;
}

.news-block{
    min-height: $news-block-height;
    max-height: 480px;
}
.rss {
    background-color: #ffffff;
    border-radius: 3px;
    &-header {
        display: flex;
        justify-content: space-between;
        height: 65px;
        padding: 20px 7px;

        @include media-breakpoint-up(md) {
            padding: 20px 24px;
        }

        border-bottom: 1px solid #EFF0F2;
        .rss-icon {
            position: relative;
            top: -2px;
            right: -2px;
            margin-left: 8px;
        }
    }
    &-content {
        .news-footer {
            display: block;
            font-size: 10px;
            letter-spacing: 0.1em;
            text-transform: uppercase;
        }
        .news{
            padding: 18px 7px;
            @include media-breakpoint-up(md) {
                padding: 18px 24px;
            }
        }
    }
    &-slider {
        height: 100%;
        .news{
            &-header{
                a:not(.news-link){
                    text-transform: uppercase;
                    letter-spacing: 0.1em;
                    font-size: 10px;
                }
            }
        }
    }
}

.news_large {
    .news-date {
        @extend .text-base-min;
        color: #909EBB !important;
    }
    .news-category {
        margin-left: 10px;
        @extend .text-cap-micro;
    }
}

.news_img{
    background-size: 100% 100%;
    padding: 0 !important;
    cursor: pointer;
    background-color: white;
}

.news_img_text{
    background-size: 100% 100%;
    cursor: pointer;
    background-color: white;
}

.news {
    height: 128px;
    padding: 18px 24px;
    border-bottom: 1px solid #EFF0F2;
    //background-color: #ffffff;
    &_large {
        &:hover {
            text-decoration: none;
            .news__title {
                color: $prime-color-dark;
            }
        }
    }
    &__text_small {
        &:hover {
            text-decoration: none;
            color: $prime-color-dark;
        }
    }

    &__title {
        line-height: 45px;
    }
    &__link {
        &:visited {
            color: $prime-color;
        }
    }
    &:last-child {
        border-bottom: none;
    }
    &-header {
        display: block;
        color: $grey-main-light;
        @extend .text-base-micro;
    }
    &-content {
        display: block;
        color: $base-dark;
        &_large {
            margin: 16px 0 32px 0;
        }
        a{
            color: $base-dark;
            &:hover{
                color: $prime-color-dark;
            }
        }
    }
    &-search{
        .all-categories{
            position: absolute;
            width: 90%;
        }
    }
    &-category {
        @extend .text-cap-micro;
        color: $prime-color;
    }
    &-footer {
        display: block;
        // @extend .text-cap-micro;
    }
    &__text {
        margin-bottom: 10px;
        color: $base-dark;
        @extend .heading-h4;
        &_small {
            color: $base-dark;
            @extend .text-base-min;
            // overflow: hidden;
            // white-space: nowrap;
            // -o-text-overflow: ellipsis;
            // text-overflow: ellipsis;
        }
    }

    &-link {
        outline: none;
        &:hover {
            text-decoration: none;
        }
    }
    &_large {
        position: relative;
        z-index: 20;
        height: $news-block-height;
        padding: 52px 64px;
        border-radius: 3px;
    }
    &__bg {
        position: absolute;
        right:16px;
        top: 0;
        z-index: 0;
        background-color: #ffffff;
        border-radius: 3px;

        @media(min-width:576px){
            width: 95%;
        }

        @media(min-width:768px){
            width: 95.7%;
        }

        @media(min-width:992px){
            width: 95.5%;
        }

        @media(min-width:1200px){
            width: 95.9%;
        }

        img {
            float: right;
            //-webkit-filter: contrast(150%);
            //-moz-filter: contrast(150%);
            //-o-filter: contrast(150%);
            //-ms-filter: contrast(150%);
            //filter: contrast(150%);
            //-moz-opacity: 0.5;
            //-webkit-opacity: 0.5;
        }
    }
}

@media (max-width: 1100px) {
    .news__text {
        margin-bottom: 7px;
    }
}

@media (max-width: 1140px) {
    .news_large {
        .news__title {
            //font-size: 30px;
        }
        .news__text {
            //font-size: 15px;
        }
    }
}

.news-content iframe {
    max-width: 100%;
}
