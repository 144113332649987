.cardMainInfo {
    background: $mainBackground;
    border: 1px solid $grey-extra-light;
    box-sizing: border-box;
    border-radius: 3px;
    margin: 0;
    font-size: 13px;

    &__title {
        color: $grey-main-light;
        @extend .text-base-micro;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
        display: flex;
        span{
            display: inline-block;
        }
    }

    &__content {
        display: block;
        @extend .text-base-min;
    }

    &__purchaseLink {
        color: $prime-color;
        @extend .heading-h3;
    }

    &__blackText {
        @extend .heading-h3;
        color: $base-dark;
    }

    &__state {
        font-size: 13px;
        font-weight: bold;
    }

    &__section {
        margin-top: 16px;
    }

    &_info{
        margin-top: 44px;
        margin-bottom: 44px;

        img{
            margin-right: 10px;
        }
    }
}

.sectionMainInfo {
    padding: 24px 24px 16px;
}

.navBreadcrumb {
    display: flex;

    &__item {
        margin-left: 8px;
        font-size: 11px;

        &_first {
            margin: 0;
        }

        &_active {
            color: $grey-main-light;
        }
    }

    &__text {
        line-height: 2em;
    }

    &__icon {
        vertical-align: text-bottom;
        margin-right: 6px;

        &_back {
            position: relative;
            top: 2px;
        }
    }
}

.breadcrumb {
    padding: 24px 0;
    align-items: center;

    .time-zone {
        flex-basis: 80%;
        margin-top: 24px;

        * {
            @extend .text-base-micro;
        }

        &__title {
            color: $grey-main-light;
        }

        &__value {
            padding-top: 4px;
        }

        &_block{
            width: 100%;
        }
    }
}

.rightBlock {
    font-family: Roboto;
    font-style: normal;

    &__tittle {
        font-weight: normal;
        font-size: 11px;
        line-height: 16px;
        color: #909EBB;
    }

    &__price {
        font-weight: 500;
        font-size: 19px;
        line-height: 24px;
        color: #334059;
    }

    &__text{
        font-weight: normal;
        font-size: 13px;
        line-height: 20px;
        color: #334059;
    }
}

.margBotAndTop4 {
    margin-top: 4px;
    margin-bottom: 4px;
}

.margBotAndTop12 {
    margin-top: 12px;
    margin-bottom: 12px;
}

.margBot12 {
    margin-bottom: 12px;
}

.margBot28{
    margin-bottom: 28px;
}

.margLeft12 {
    margin-left: 12px;
}

.forInn {
    weight: 400;
    style: normal;
    font-size: 16px;
    line-height: 20px;

    &__tittle {
        color: #909EBB;
    }

    &__value {
        color: #334059;
        margin: 0px 4px;
    }
}

.title_address {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #334059;
}

