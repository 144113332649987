.card {
    position: relative;
    display: inline-block;
    width: 100%;
    text-align: center;
    padding: 0 30px;
    &-point {
        display: inline-block;
        position: relative;
        width: 75px;
        height: 75px;
        padding: 15px 0;
        margin-bottom: 24px;
        background: #D8DDE7;
        border-radius: 50%;
        border: 8px solid #EFF0F2;
        color: $grey-main-light;
        @extend .heading-h4;
        &_active {
            background: $grey-main-light;
            color: #ffffff;
        }
    }
    &-total {
        .text {
            display: block;
            color: $base-dark;
            @extend .heading-h2;
            &_small {
                @extend .text-cap-base;
            }
        }
    }
    &-desc {
        margin-top: 8px;
        color: $grey-main;
        @extend .text-base-min;
        width: 100%;
        &:after {
            content: '';
            display: block;
            margin: 0 auto;
            margin-top: 16px;
            margin-bottom: 16px;
            width: 32px;
            height: 2px;
            background: $prime-color;
        }
    }
    &-econom {
        .percent {
            display: block;
            color: $base-dark;
            @extend .heading-h3;
        }
        .desc {
            display: block;
            color: $grey-main-light;
            @extend .text-base-micro;
        }
    }
    .line {
        position: absolute;
        top: 37px;
        display: block;
        width: 50%;
        height: 2px;
        background: #D8DDE7;
        &_left {
            left: 0;
        }
        &_right {
            right: 0;
        }
        &_active {
            background: $grey-main-light;
        }
    }
}