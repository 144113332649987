.b-top{
    border-top: 1px solid $grey-border;
}
.b-bottom{
    border-bottom: 1px solid $grey-border;
}
.b-right{
    border-right: 1px solid $grey-border;
}
.b-left{
    border-left: 1px solid $grey-border;
}
.brace-bottom {
    border-bottom: $border-color solid 1px;
    border-left: $border-color solid 1px;
    border-right: $border-color solid 1px;
    height: 8px;
    border-bottom-right-radius:4px;
    border-bottom-left-radius:4px;
    @include trans();
    &:before {
        content: '\2759';
        top: -2px;
        left: 50%;
        position: relative;
        font-size: 7px;
        font-weight: 900;
        color: $border-color;
        @include trans();
    }
}
.brace-top {
    border-top: $border-color solid 1px;
    border-left: $border-color solid 1px;
    border-right: $border-color solid 1px;
    height: 8px;
    border-top-right-radius:4px;
    border-top-left-radius:4px;
    @include trans();
    &:before {
        content: '\2759';
        top: -18px;
        left: 50%;
        position: relative;
        font-size: 7px;
        font-weight: 900;
        color: $border-color;
        @include trans();
    }
}
.brace-empty{
    height: 8px;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .brace-top {
        &:before {
            top: -18px !important;
        }
    }
}
