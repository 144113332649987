.modal-content {
    &-load-properties {
        #csvSettingTree{
            height: 35vh;
        }
        .unload-block {
            &-caption {
                @extend .text-base-min;
                color: $base-dark;
                margin-bottom: rem-local(12);
            }
            &-list {
                height: 461px;
                &__item{
                    padding: rem-local(16) 0;
                    border-top: 1px solid $border-color;
                    .file-name {
                        @extend .text-base-micro;
                        color: $grey-main-light;
                    }
                    .caption {
                        @extend .text-base-min;
                        color: $base-dark;
                    }
                    .link {
                        @extend .text-base-micro;
                        color: $prime-color;
                        &.ssd{
                            color: $prime-color-super-light;
                        }
                    }
                }
            }
        }
        .save-param-block {
            padding: rem-local(24) 0;
            border-top: 1px solid $border-color;
            &__btn-save{
                @extend .text-base-button;
                color: $prime-color;;
            }
            &__input {
                width: 50%;
                margin-right: rem-local(24);
                &:valid + .save-param-block__btn-save {
                    color: $prime-color;
                    &:hover {
                        cursor: pointer;
                    }
                }
            }

        }
        .progress-block {
            background: #fdfdfd;
            padding: rem-local(16) 0 rem-local(16) rem-local(24);
            border-top: 1px solid $border-color;
            &__step {
                @extend .text-base-min;
                color: $grey-main-light;
                border: 1px solid $grey-main-light;
                border-radius: 50%;
                width: 24px;
                height: 24px;
                text-align: center;
                margin-right: rem-local(16);
                position: relative;
                @include trans();
                span {
                    position: absolute;
                    top: 1px;
                    left: 8px;
                }
               &.active {
                   background: $prime-color;
                   color: $white;
                   border-color: $prime-color;
                   box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.0877491);
               }
            }
            &__title {
                @extend .text-base-min;
                color: $grey-main-light;
                white-space: nowrap;
                @include trans();
                &.active {
                    color: $base-dark;
                }
            }
            &__separator {
                width: 150px;
                border-top: 1px solid $grey-main-light;
                margin: 0 rem-local(16);
            }

        }
        .two-stage {
            @include trans();
        }
        .first-stage {
            @include trans();
        }
    }
}
