.page {
    background: #ffffff;
    .page-search {
        border-bottom: 1px solid #E4E7F2;
        padding-bottom: 47px;
        .docs-search {
            .docs-button {
                width: 95px;
            }
        }
        .docs-detail {
            .docs-title {
                color: $base-dark;
            }
        }
    }
}

.last-section-docs{
    display: inline-block;
}

.page-title {
    position: relative;
    .icon {
        position: absolute;
        top: 14px;
        left: 155px;
    }
}

.docs-link {
    &:hover {
        .docs-content {
            .docs-title {
                color: #014DA8;
            }
            .docs-text {
                color: #6F7C98;
            }
        }
    }
}

.docs-group {
    border-bottom: 1px solid #E4E7F2;
    padding: 18px 0 18px 0;
    .row{
        .col-3{
            .docs-date{
                color:  $grey-main-light;
                font-size:11px;
                text-transform: lowercase;
            }
        }
        .col-5{
            flex: 0 0 70%;
            max-width: 70%;

            .docs-size{
                color:  $grey-main-light;
                font-size:80%;
                margin-left: 1%;
            }
        }
        .docs-title{
            color: #334059;
            text-decoration: none;
            line-height: 26px;
        }
        .docs-title:hover{
            color: #0065dd;
        }
        .col-sm{
            .docs-status{

            }

            .docs-size{
                color:  $grey-main-light;
                font-size:80%;
                margin-left: 20%;
            }
        }
    }

}

.all-categories {
    border: 1px solid #E4E7F2;
    border-radius: 3px;
    padding: 28px 0 18px 0;
    text-transform: none!important;
    position: absolute;
    width: 260px;
    .categories {
        line-height: 20px;
        font-size: 13px;
        padding: 0;
        margin: 0;
        .category {
            padding: 4px 24px;
            margin-bottom: 4px;
            list-style: none;
            &:last-child {
                margin-bottom: 0;
            }
            &__link {
                color: $base-dark;
                @extend .text-base-min;
            }
            &_active {
                border-left: 2px solid $prime-color;
             //   padding-left: 20px;
                .category__link {
                    color: $prime-color;
                }
            }
        }
    }
}

@media (max-width: 1140px) {

    .docs-search {
        .form-group {
            margin-right: 5px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
}
.docs-search .row .col-8 .page-search .align-items-end .col-12 .docs-input .icon{
    cursor: pointer;
}

.paging{
    padding-bottom: 25px;
    .last-section-docs {
        text-decoration-line: none;
        color: #0065dd;
        display: inline-block;
    }
}

.docs-search {
    &__clear-btn {
        position: absolute;
        margin-left: -32px;
        margin-top: 13px;
        right: 2.5rem;
        bottom: rem-local(10);
        opacity: .75;
        img {
            height: 10px;
            width: 10px;
        }
        &:hover {
            cursor: pointer;
            opacity: 1;
        }
    }
}
