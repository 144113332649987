.search {
    position: relative;
    &-group {
      position: relative;
    }
    &__input {
        position: relative;
        width: 100%;
        background: #FFFFFF;
        border-radius: 3px;
        border-color: transparent;
        height: 56px;
        padding-left: 24px;
        padding-right: 60px;
        color:#000;
        @extend .text-base-min;

        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color:$grey-main-light;
          }
        &::-moz-placeholder { /* Firefox 19+ */
            color:$grey-main-light;
          }
        &:-ms-input-placeholder { /* IE 10+ */
            color:$grey-main-light;
          }
        &:-moz-placeholder { /* Firefox 18- */
            color:$grey-main-light;
          }
    }
    &__btn {
      position: absolute;
      top: 18px;
      right: 16px;
      background: transparent;
      border: 0;
      cursor: pointer;
    }
    &__title{
        @extend .heading-h1;
        color: $base-dark;
        line-height: 48px;
        margin-bottom: 24px;
    }
    .checkbox-search+label{
        color: $base-dark;
    }
    .clear{
        &__btn{
            position: absolute;
            top: 15px;
            right: 50px;
            background: transparent;
            border: 0;
            cursor: pointer;
            img{
                width: 10px;
                height: 10px;
            }
        }
    }
    .searchField{
        min-height: 56px;
        background: #ffffff;
    }
}
.box-shadow-search-input{
    box-shadow: 0 15px 15px -10px $grey-super-light;
}
#search-dropdown{
    > .dropdown-inner{
        display: inline-flex;
    }
}
.sub-search {
    height: 72px;
    padding-top: 18px;
}
.no-information {
    display:flex;
    @extend .text-base-min;
    color: $base-dark;
    padding: 3rem 0;
    justify-content: center;
    font-style: italic !important;
}
.no-information-old {
    @extend .text-base-min;
    color: $base-dark;
    padding: 3rem 0;
    font-style: italic !important;
}
