.modal{
   &-text{
       &-block{
           &-tree{
               .greyBox{
                   background: $grey-super-light;
               }
               .paginator{
                   padding: 0;
               }
               .allRecords{
                   float: right;
               }
         }
       }
   }
    &-content{
        &-organization-two{
            width: 736px;
            .errorIcon{
                display: inline-block;
                width: 24px;
                height: 24px;
            }
            .rowOrganization{
                height: 96px;
            }
            .rowOrganization:hover{
                background: $grey-super-light;
                color: $prime-color;
                cursor: pointer;
            }
            .fz{
                display: block;
            }
        }
    }
}
