.titleField {
    display: inline-block;
    font-size: 24px;
    font-weight: normal;
    padding-right: 30px;
    vertical-align: middle;
}
.pollTbl {
    background-color: #eff0f1;
    border-radius: 5px;
    margin: 10px 0 20px 0;
}

.modal-header .pollTbl{
    margin-bottom: 0px;
}

.widthAuto, .widthAutoUsual {
    width: auto !important;
}
.pollTbl td {
    color: #5f6a74;
    font-size: 12px;
    font-weight: bold;
    padding-left: 25px;
    text-align: center;
    text-transform: uppercase;
}
.noticeTabs td.currentTab span.td-content, .pollTbl td.currentTab span {
    border-bottom: 3px solid #018ccd;
    cursor: default;
}
.noticeTabs td span, .pollTbl td span {
    border-bottom: 3px solid #eff0f1;
    cursor: pointer;
    display: inline-block;
    padding: 15px 0 12px;
    width: 100%;
}
.mainBox div.parametrs{
    padding: 0;
}
