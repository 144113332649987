.show-by {
    @extend .text-base-min;
    .select-wrap, .select-wrap-modal{
        width: 80px;
    }
    &__title {
        margin-right: 10px;
    }
}

.show-by .gp-select-wrap {
    width: 80px;
}
