.common-info {
    .block-text {
        margin-bottom: 20px;
        
        &:last-child {
            margin-bottom: 0;
        }
        &:first-child {
            margin-bottom: 20px;
        }
    }
    
}