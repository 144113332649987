.select {
    // padding: 7px 12px;
    padding: 12px 16px;
    width: 100%;
    border: 1px solid #DAE4EE;
    border-radius: 3px;
    cursor: pointer;
    outline: none;
    &-group {
        position: relative;
        cursor: pointer;
        .select-icon {
            background: img-url('icons/icon_arrow_region--gray.svg') no-repeat center;
            width: 20px;
            height: 20px;
            right: 8px;
            @extend .align-middle-absolute;
            &.arrow-select-vars_open{
                background: img-url('icons/icon_arrow_region.svg') no-repeat center !important;
                transform: scale(1, -1);
            }
        }
    }
    &-wrap, &-wrap-modal {
        width: 100%;
        .sub-text {
            @extend .text-base-min;
            color: $grey-main;
        }
    }
    &-vars {
        position: absolute;
        display: none;
        top: -1px;
        width: 100%;
        z-index: 990;
        border-bottom: 1px solid #DAE4EE;
        border-right: 1px solid #DAE4EE;
        border-left: 1px solid #DAE4EE;
        border-bottom-right-radius: 3px;
        &__item {
            padding: 7px 12px;
            background-color: #FFFFFF;
            cursor: pointer;
            &:hover {
                background: $base-hover-item-list;
                color: $prime-color-dark;
            }
            &-input{
                border: 1px solid #dae4ee;
                border-radius: 3px;
                width: 100%;
                padding: 9px 15px;
            }
            &-noEvent{
                padding: 7px 12px;
                background-color: #fff;
            }
            &--selected {
                color: $prime-color;
            }
            &--icon__cross {
                width: 10px;
                height: 10px;
                min-width: 10px;
                min-height: 10px;
            }
        }
        &__link {
            display: inline-block;
            width: 100%;
            color: $base-dark;
            text-decoration: none!important;

        }
        &_open {
            display: block;
        }
    }

    &-wrap--button {
        width: auto;
        .select {
            padding: 12px 48px 12px 24px;
            background: $prime-color;
            border-color: $prime-color;
            color: $white;
            @extend .text-base-button;
            &-vars {
                &__item {
                    color: $base-dark;
                }
            }
            &-group {
                position: relative;
                cursor: pointer;
                .select-icon {
                    background: img-url('icons/icon_arrow--white.svg') no-repeat;
                    width: 16px;
                    height: 16px;
                    margin-left: rem-local(10);
                    right: rem-local(17);
                    @extend .align-middle-absolute;
                    &.arrow-select-vars_open{
                        background: img-url('icons/icon_arrow--white.svg') no-repeat !important;
                        transform: scale(1, -1);
                    }
                }
            }
            &.select-text {
                padding-right: rem-local(48);
            }
        }
    }


    &-overflow{
        max-height: 250px;
        overflow-x: hidden;
        overflow-y: hidden;
        .mCSB_container {
            margin: 0 !important;
        }
    }



    &-text {
        @extend .text-base-min;
    }
    &-truncate {
        padding-right: 10%;
        @extend .text-truncate;
    }
    &.select-text{
        padding-right: rem-local(16);
        @extend .text-truncate;
    }
}
//стиль для InternetExplorer
.iePadding {
    padding: 9.7px 16px;
}
.modal .show-by .select-vars__item {
    padding: 5px 12px !important;
    width: 100% !important;
    display: inline-block !important;
}
