.old-search-form {
    @extend .text-base-12px;

    input[type="text"], input[type="password"], input[type="dirname"] {
        background-color: #fff;
        border: 2px solid #cecece;
        border-radius: 4px;
        box-sizing: border-box !important;
        color: #5b5b5b;
        font-size: 12px !important;
        height: 34px;
        line-height: 34px;
        margin: 0;
        padding: 0 !important;
        text-indent: 10px;
        vertical-align: middle;
        box-shadow: none !important;
    }

    .searchBlockAll {
        background-color: #eff0f1;
        border-radius: 3px;
        color: #41484e;
        padding: 24px;
    }

    .titleTop .searchField {
        display: inline-flex;
    }
    .searchField .autocompleteOrder {
        border: 0 none;
        font-size: 14px;
        height: 36px;
        width: 700px;
    }

    .searchField {
        background-color: #fff;
        border-radius: 5px;
        display: inline-block;
        width: 100%;
        vertical-align: middle;
        position: relative;
    }

    .searchBlockAll.zakupkiSearch {
        max-width: 1150px;
        padding-right: 24px;
        padding-left: 24px;
        margin: 24px auto;
    }
    .searchField .autocompleteOrder {
        font-size: 14px;
        height: 36px;
        width: 95%;
        border-width: 0px;
        border-style: initial;
        border-color: initial;
        border-image: initial;
        color: #909EBB !important;
    }

    .searchField > input[type="button"] {
        margin-top: 6px;
    }

    .select {
        background-color: #fff;
        border: 2px solid #cecece;
        border-radius: 4px;
        color: #5b5b5b;
        cursor: pointer;
        display: inline-block;
        height: 28px;
        left: 0;
        line-height: 24px;
        overflow: hidden;
        padding: 0 25px 0 10px;
        position: absolute;
        top: 0;
        white-space: nowrap;
    }

    .customCheckbox {
        input[type="checkbox"]:checked + label:after {
            left: 0.2rem;
        }
    }
    #publishDateTag, #updateDateTag, #actualPeriodTag, #validPeriodTag{
        input{
            width: 160px;
        }
    }
    #contractInputNameDefenseOrderNumber {
        width: 311px;
    }
    .selectChose {
        display: none;
        padding: 0 0;
    }
    #searchOptionsEditContainer {
        color: #5b5b5b;
    }

    .expandRow .saveExtendedSearch .drugGroup{
        @extend .row;
        > label{
            @extend .col-4;
            padding-right: 16px !important;
        }
        > input{
            width: 60% !important;
        }
    }
    .listClassifiers{
        .deleteClassifiers {
            @extend .iconClose
        }
    }

    #contractContractPrice input, #rightPriceRurContractPrice input{
        width: 160px;
    }

    .listOrg{
        .d-flex{
            display: inline-flex !important;
            > div{
                order: 2;
            }
            span{
                order: 1;
                margin-top: 3px;
            }
        }
    }
    .listKtru {
        .removeDrugEl {
            float: left !important;
        }
        .removeCustomEl {
            float: left !important;
        }
        .deleteClassifiers {
            float: left !important;
        }
    }
}

.old-search-form, .parametrs, .mainBox {
    @extend .container;
}

.filters{
    .clearAll{
        background: none;
        color: $prime-color;
        padding-left: 0px
    }
}
.formValidation.search{
    .saveExtendedSearch label {
        display: inherit !important;
        vertical-align: inherit !important;
        width: inherit !important;
    }
}





