$padding-side: 22px;
$padding-vertical: 20px;
$padding-modal: 21px 24px 16px 25px;
$padding-footer: 23px 23px 23px 25px;

.modal-open {
    display: block !important;
}

.show-hide-info {
    cursor: pointer;
    user-select: none;
    color: #0065dd;
    margin: 5px 0 10px;
    font-size: 13px;
}

.overflowDetail {
    display: none;
    overflow: auto;
    height: 20vh;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1000;
    background: linear-gradient(0deg, rgba(51, 64, 89, 0.4), rgba(51, 64, 89, 0.4));
    .modal-content {
        position: relative;
        top: 20%;
        margin: 0 auto;
        display: block;
        background: #fff;
        border-radius: 3px;
        &-search {
            width: 736px;
        }
        &-region {
            width: 352px;
        }
        &-account {
            width: 544px;
            max-height: 70%;
            overflow-y: auto;
        }
        &-subjectControl {
            top: 10%;
            width: 736px;
            max-height: 85%;
            overflow-y: auto;
        }
        &-chooseDosageModal{
            width: 736px;
            top: 10%;
            max-height: 80%;
            overflow-y: auto;
        }
        &-choose-organization{
            width: 928px;
            top: 10%;
            max-height: 80%;
            overflow-y: auto;
        }
        &-saveSearchSettingsPopup{
            width: 650px;
        }
        &-org{
            top: 5%;
            width: 736px;
            max-height: 80%;
            overflow-y: auto;
            overflow-x: hidden;
        }
        &-classifiers{
            width: 900px;
            max-height: 95%;
            overflow-y: auto;
            top: 5%;
        }
        &-attr-dialog{
            width: 736px;
            max-height: 80%;
            overflow-y: auto;
            overflow-x: hidden;
        }
        &-edsContainer{
            width: 46rem;
            top: 10%;
            max-height: 85%;
            overflow-y: auto;
            .popUpWrapper{
                padding: 0 !important;
            }
        }
        &-quiz-parcipation{
            max-height: 70%;
            overflow-y: auto;
            width: 900px;
        }
        &-event-subject{
            max-height: 70%;
            overflow-y: auto;
            width: 900px;
            top: 10%;
        }
        &-ppo{
            max-height: 75%;
            overflow-y: auto;
            width: 736px;
            top: 10%;
        }
        &-agency{
            max-height: 95%;
            overflow-y: auto;
            width: 736px;
            top: 5%;
            .btnLine{
                display: flex;
                background: #dbe1e6;
                justify-content: flex-end;
                padding-right: 15px;
            }
            .createDocBtn{
                padding-bottom: 0px;
            }
            .btn {
                line-height: 20px;
            }
        }
        &-chooseFarmModal{
            max-height: 85%;
            overflow-y: auto;
            top: 5%;
            width: 736px;
            min-height: 68%;
        }
        &-new-comment {
            top: 10%;
            width: 820px;
            max-height: 90%;
            overflow-y: auto;
        }
        &-ktruHint{
            max-height: 70%;
            overflow-y: auto;
        }
        &-ikzHint{
            max-height: 70%;
            overflow-y: auto;
        }
        &-purchase223{
            width: 736px;
            top: 10%;
            max-height: 80%;
            overflow-y: auto;

            .paginatorContainer{
                padding: 0 25px;
            }
        }
        &-quiz{
            .contentTabsWrapper .currentTab{
                pointer-events: none;
            }
        }
        &-okdp{
            &-okved{
                width: 736px;
                height: auto;
                max-height: 70%;
                overflow-y: auto;
            }
        }
        &-okdpgroup{
            width: 736px;
            height: auto;
            max-height: 70%;
            overflow-y: auto;
        }
        &-multiSel{
            width: 736px;
            height: auto;
            max-height: 70%;
            overflow-y: auto;
        }
        .modal-auto-region{
            padding:5px 22px 22px 22px !important;
        }
        &-loadProperties{
            width: 532px;
            max-height: 80%;
            overflow-y: auto;
            top: 7%;
        }
        &-load-properties{
            width: 532px;
            max-height: 95%;
            overflow-y: auto;
            position: absolute;
            top: 50% !important;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        &-update-user{
            width: 736px;
            height: auto;
            max-height: 80%;
            overflow-y: auto;
            &--two-column{
                width: 764px;
            }
            &--one-column{
                width: 391px;
            }
            &.success {
                .modal-text-block {
                    height: 600px;
                    color: $success;
                }
            }
        }
        &-not-hierarchical-list{
            width: 730px;
            height: auto;
            max-height: 80%;
            overflow-y: auto;
            top: 10%;
        }
        &-update-password {
            width: 736px;
            height: auto;
            max-height: 80%;
            overflow-y: auto;
        }
        &-info {
            width: 992px;
            top: 7%;
            height: auto;
            max-height: 80%;
            overflow-y: auto;
        }
        &-type-work {
            width: 736px;
            max-height: 95%;
            overflow-y: auto;
            position: absolute;
            top: 50% !important;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        &-contract-details{
            width: 736px;
            top: 5% !important;
            max-height: 90%;
            overflow-y: hidden;
            @extend .modal-content;

            section {
                max-height: 55vh;
            }
        }
        .modal-auto {
            padding: $padding-modal;
            @extend .text-base-micro;
            color: $prime-color;
            &_account {
                padding: 0;
                .modal-auto__btn {
                    color: $grey-main !important;
                    margin: 0 auto;
                    //color: #0065dd !important;
                }
                .modal-auto__text {
                    color: $grey-main;
                    // color: $prime-color;
                }
            }
        }
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            .search > input{
                padding: 7px 40px 7px 7px !important;
            }
        }
    }
    .modal-btn {
        width: 144px;
    }
    .modal-header {
        padding: $padding-modal;
        .title {
            @extend .heading-h3;
            word-wrap: break-word !important;
            color: $base-dark;
        }
        .sub-title {
            @extend .text-base-min;
            color: $grey-main-light;
            white-space: nowrap;
            margin-left: 1rem;
        }
        .btn-close {
            padding-left: 1rem;
            opacity: 0.75;
            &:hover {
                opacity: 1;
                cursor: pointer;
            }
        }
    }

    .modal-footer {
        border-radius: 0 0 3px 3px;
        padding: $padding-footer;
        border: 1px solid $border-color;
        .btn {
            @extend .text-base-button;
            width: auto !important;
            max-width: none !important;
            min-width: inherit !important;
            outline: none;
            &-default, &-default--no-border {
                margin-right: rem-local(20);
                padding: rem-local(12) rem-local(23);
            }
            &-primary {
                padding: rem-local(12) rem-local(24);
            }
            &-left {
                &__img {
                    margin-right: rem-local(15);
                }
                &__text {
                    color: $prime-color;
                }

            }
            .w-100{
                width: 100% !important;
            }
        }
        .sub-text {
            @extend .text-base-min;
            color: $grey-main-light;
            margin-right: rem-local(40);
        }
    }
    .modal-select {
        padding: 7px $padding-side;
    }

    .modal-text-block {
        padding: $padding-modal;
        border-top: 1px solid #EFF0F2;
        .text {
            margin-bottom: 23px;
            @extend .text-base-min;
            color: $base-dark;
        }
        .input-group_account {
            max-width: 128px;
        }
        .help-title {
            @extend .text-base-min;
            color: $base-dark;
            font-weight: bold;
            margin-bottom: 10px;
        }
        .form-input-modal {
            position: relative;
            .btn {
                @extend .text-base-button;
                width: auto !important;
                max-width: none !important;
                min-width: inherit !important;
                outline: none;
                pointer-events: none;
                &-default, &-default--no-border {
                    padding: rem-local(12) rem-local(23);
                }
                &-primary {
                    padding: rem-local(12) rem-local(24);
                }
                &-left {
                    &__img {
                        margin-right: rem-local(15);
                    }
                    &__text {
                        color: $prime-color;
                    }

                }
            }
            .search-input-modal{
                width: 100% !important;
                &.valid {
                    ~ .resetBtn {
                        display: block !important;
                    }
                    ~ .btn {
                        pointer-events: inherit !important;
                    }
                }
            }
            .resetBtn{
                @extend .align-middle-absolute;
                @include trans();
                opacity: .75;
                &:hover{
                    opacity: 1;
                    cursor: pointer;
                }
            }
        }
    }
}


@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .popUp .modal .modal-content-chooseDosageModal, .popUp .modal .modal-content-chooseFarmModal{
        max-height: 87%;
        min-height: 87%;
    }
}

.color-gray{
    color: $grey-text !important;
}
