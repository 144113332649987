#custom-tooltip {
    position: absolute;
    display: inline-block;
}

#custom-tooltip .custom-tooltiptext {
    font-family: 'Roboto';
    @extend .text-base-min;
    background-color: $dim-gray;
    color: #fff;
    text-align: left;
    padding: 5px 0;
    border-radius: 7px;
    position: fixed;
    padding: 0.5rem;
    max-width: 500px;
    @include trans();
    opacity: 0;
    z-index: 999;
}

#custom-tooltip .custom-tooltiptext.no-max-width {
    max-width: none;
}

.custom-tooltip-contract {
    display: inline-block;
    .tooltiptext-contract{
        visibility: hidden;
        @extend .text-base-min;
        font-family: 'Roboto';
        background-color: $dim-gray;
        color: #fff;
        text-align: left;
        padding: 8px;
        border-radius: 6px;
        position: absolute;
        z-index: 50;
        max-width: 512px;
        margin-top: 16px;
    }
}
