.m-spinner {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    svg {
        margin: 0 auto;
        display: block;
    }
}

//
// Rotating border
//

@keyframes spinner-border {
    to { transform: rotate(360deg); }
}
.spinner-block {
    @extend .m-spinner;
    justify-content: center;
    align-items: center;
    background: linear-gradient(0deg, rgba(51, 64, 89, 0.4), rgba(51, 64, 89, 0.4));
}
.spinner-border {
    display: inline-block;
    width: 50px;
    height: 50px;
    vertical-align: text-bottom;
    border: 5px solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: spinner-border .75s linear infinite;
    &--small{
        width: 25px;
        height: 25px;
    }
    &--large{
        width: 100px;
        height: 100px;
    }
}
