.ikzInputs {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 5px;

    &__year {
        width: 5%;
    }

    &__costumerId {
        width: 25%;
    }

    &__main {
        width: 15%;
    }

    &__label {
        color: $grey-main-light;
        font-size: 13px;
        margin-bottom: 5px;
    }

    &__input {
        width: 100%!important;
        padding-right: 15px !important;
        border-radius: 3px!important;
        border: 1px solid $grey-extra-light!important;
    }
}
