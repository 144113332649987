.filter {
    position: relative;

    &__active {
        font-size: 13px;
        color: $prime-color;

        &:after {
            margin-left: 8px;
            vertical-align: top;
            content: url("/epz/static/img/icons/icon_arrow_region.svg");
        }
    }

    &__variables {
        background-color: #fff;
        left: auto!important;
        top: 25px!important;
        right: 0;
        border-radius: 3px!important;
        display: none;
        position: absolute;
        text-align: center;
        white-space: nowrap;
        box-shadow: 0 4px 12px rgba(51,64,89,.2)!important;
        z-index: 100;
        font-size: 13px;
    }
}

.variables {
    &__variable {
        padding: 8px 16px;

        &:hover, &_active {
            color: $prime-color;
        }

        &:hover {
            background: #eff0f2!important;
        }
    }
}
