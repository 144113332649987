.modal{
    color: $base-dark;
    &-content{
        &-organization{
            width: 736px;
        }
        &-agency{
            .modal{
                &-text-block{
                    .parentCompanyChoice{
                        @extend .text-base-micro;
                    }
                    #tree{
                        max-height: 310px !important;
                        overflow: hidden !important;
                    }
                }
            }
        }

        &-choose-organization {
            .rowOrganization{
                &__name-organization {
                    padding-left: rem-local(26);
                }
            }
        }
    }
    &-text {
        &-block {
            &-org{
                border-bottom: 1px solid #eff0f2;
                padding: 20px 22px;
                .dynatree {
                    &-checkbox {
                        max-width: 16px;
                        max-height: 16px;
                        background: url(/epz/static/img/icons/icon_checkbox_modal.svg) no-repeat;
                        display: inline-block;
                        top: 1px;
                    }
                }
            }
            &.org-col-name{
                padding: 12px 9px;
            }
        }
    }
    .organizationChoose{
        padding: 24px;
    }
    .wrapperTableHeight {
        height: 24em;
        overflow-y: hidden;
        overflow-x: hidden;
    }
    #chooseOrganizationDialogDataContainer {
        .paginator.greyBox {
            margin: 0;
        }
        .mCSB_inside > .mCSB_container {
            margin-right: 0;
        }
        .choiceTableRow{
            border-bottom: none;
            cursor: pointer;
            &:first-child{
                border-top: none;
            }
            .fz{
                display: block;
            }
        }
    }
    #chooseOrganizationDialog_searchText:not(.colorValue) + span.resetBtn{
        background: url(/epz/static/img/icons/icon_cross.svg) no-repeat;
        position: absolute;
        margin-left: -32px;
        margin-top: 13px;
        height: 16px;
        width: 16px;
    }
}
.table-head-text {
    @extend .text-cap-micro;
    color: #909EBB;
}
