.header {
    background-color: #ffffff;
    &-top {
        position: relative;
        max-width: 1280px;
        width: 100%;
        margin: 0 auto;
        .logo {
            color: $grey-main-light;
        }
    }
    &-mid {
        height: 96px;
        padding: 22px 0;
        border-top: 1px solid #E3EAFD;
        border-bottom: 1px solid #E3EAFD;
    }
    &-bottom {
        border-bottom: 1px solid #E3EAFD;
    }
    &-logo {
        position: absolute;
        height: 96px;
        top: -56px;
        left: -1px;
        background: #fff;
        padding: 2px 15px;
        .logo-bg {
            display: inline-block;
            position: relative;
            left: -25px;
            top: -32px;
            background-color: #fff;
            width: 142px;
            height: 15px;
        }
    }
    &-title {
        color: $base-dark;
    }

    $header-padding-second: 22px;
    &-offset {
        padding-left: $header-padding-second;
        color: #334059;
    }

}


$header-padding-second: 22px;

.phone {
    &__text {
        text-decoration: none!important;
        color: $prime-color;
        &:hover{
            color: $prime-color;
        }
        span{
            cursor: default;
        }
    }
    &__desc {
        margin-left: 5px;
        padding-bottom: 1px;
        display: inline-block;
        vertical-align: middle;
        color: $grey-main-light;
    }
}

$padding: 16px;
.account {
    position: relative;
    width: 240px;
    z-index: 888;
    background-color: $prime-color;
    color: #ffffff;
    @extend .text-base-micro;

    &:hover {
        background-color: $prime-color-dark;
        .account-vars {
            background-color: $prime-color-dark;
        }
        .button-open {
            display: inline-block;
            transform: rotate(-180deg);
        }
    }

    &-block {
        padding: $padding;
        cursor: pointer;
        border-bottom: 1px solid rgba(255, 255, 255, .3);

    }
    &-vars {
        position: absolute;
        width: 100%;
        background: $prime-color;
        z-index: 999;
        // display: block;
        display: none;
        padding: 0;
        margin: 0;
    }
    &__var {
        padding: 12px $padding;
        list-style: none;
        cursor: pointer;
        &:hover {
            background-color: rgba(255, 255, 255, .1);
            text-decoration: none;
        }
    }
    &__link {
        display: flex;
        color: #ffffff;
        &:hover {
            color: #ffffff;
            text-decoration: none;
        }
        .icon {
            margin-right: 7px;
        }
        .text {
            margin-right: 24px;
            &_bottom {
                margin-right: 5px;
            }
        }
        .subtext {
            color: #62ADE9;
        }

    }
}

.region {
    padding-right: 22px;
    text-decoration: none;
    .region-city {
        cursor: pointer;
        &__text {
            &_prime {
                color: $prime-color;
            }
            &_base {
                color: $grey-main-light;
            }
        }
        #popUpUserRegionName:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }
}

a#chooseRegion:hover {
    text-decoration: none;
}

.header-link {
    display: inline-block;
    @extend .text-base-micro;
    &__text {
        display: inline-block;
        color: $grey-main-light;
        vertical-align: text-top;
        &_prime {
            color: $prime-color;
        }
        &:hover {
            color: $prime-color-dark;
            text-decoration: underline;
        }
    }
    &__icon {
        display: inline-block;
    }
    &_top {
        position: relative;
        left: 15px;
    }
    .glass-icon {
        width: 34px;
        height: 34px;
        position: relative;
        left: 3px;
    }
}
.header-link_icon {
    &_left {
        .header-link__text {
            margin-left: 5px;
        }
    }
}
.divider {
    display: inline-block;
    width: 100%;

}

.header-links-group {
    border-left: 1px solid $grey-main-extra-light;
    .wrapper-link {
        padding-left: 16px;
    }
}

#header-title-site{
    cursor: pointer;
}

#header-title-site:hover {
    background: #ffffff;
    text-decoration: none;
}
#header-title-site span {
    font-size: 12px;
    font-weight: lighter;
    display: none;
    padding: 2px 3px;
    margin-left: 8px;
    width: 600px;
    z-index: 1;
}
#header-title-site:hover span {
    display: inline;
    position: absolute;
    background: #ffffff;
    border: 1px solid #cccccc;
    color: #6c6c6c;
}

#popUpUserRegionName+.region-city__icon {
    vertical-align: middle;
}

.offset-block {
    margin-left: 23px;
}

@media (max-width: 1120px) {
    .account {
        width: 230px;
    }
}

@media (max-width: 1140px) {
    .offset-block {
        margin-left: 0;
    }
}
@media (max-width: 1140px) {
    .offset-block {
        margin-left: 0;
    }
}

