@mixin  padding-left($num){
  @for $i from 1 through $num {
    .pl-#{$i} {
      padding-left: ($i*2);
    }
  }
}
@mixin  padding-right($num){
  @for $i from 1 through $num {
    .pl-#{$i} {
      padding-right: ($i*2);
    }
  }
}

@include padding-left(10);
@include padding-right(10);