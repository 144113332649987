.footer {
    position: relative;
    background-color: #EFF0F2;
    padding-top: 32px;
}
.footer-nav {
  padding: 30px 0;
  height: 88px;
  border-top: 1px solid #DDE0E5;
  border-bottom: 1px solid #DDE0E5;
  &__item {
    display: inline-block;
    padding-right: 18px;
    list-style: none;
  }
  &__link {
    color: $grey-main;
    @extend .text-base-micro;
  }
}

.footer-nav-list {
  padding: 0;
  margin: 0;
}
.partners {
    padding-top: 48px;
    &-wrap {
        position: relative;
        margin-bottom: 14px;
    }
    &-slider {
        &__btn {
            position: absolute;
            cursor: pointer;
            &_prev {
                top: 32%;
                img {
                    transform: rotate(180deg);
                }
            }
            &_next {
                top: 32%;
                right: 0;
            }
        }
    }
}
.partner {
    width: 100%;
    display: block;
    text-align: center;
    &__link {
        color: $prime-color;
        @extend .text-cap-base;
    }
    &__desc {
        // padding: 0 25px;
        color: $grey-main-light;
        padding-bottom: 4px;
        @extend .text-base-micro;
    }
    &__logo {
        max-width: 64px;
        display: inline-block!important;
    }
}

.footer-links {
    position: relative;
    padding-top: 40px;
    padding-bottom: 55px;
}

.usefull-links {
    border-left: 1px solid $grey-main-extra-light;
    padding-left: 25px;
    padding-right: 25px;
    height: 100%;
}

.list {
    padding: 0;
    margin: 0;
    margin-top: 25px;
    &-title {
        color: $grey-main;
        @extend .text-cap-base;
    }
    &-item {
        margin-bottom: 6px;
        list-style: none;
        &__link {
            display: inline-block;
            color: $grey-main;
            @extend .text-base-micro;
        }
    }
}

.logo {
    &__img {
        max-width: 55px;
        max-height: 60px;
    }
    &__name {
        margin-top: 16px;
        color: $grey-main;
        @extend .text-cap-base;
    }
}

.copyright {
    position: absolute;
    bottom: 7px;
    display: flex;
    flex-direction: column;
    &__text {
        color: $grey-main-light;
        @extend .text-base-micro;
    }
}

.btn-on-top {
    position: relative;
    top: -181px;
    right: -23.5vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: rgba(51,64,89,.3);
    width: 56px;
    height: 56px;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    &:hover {
        background-color: $prime-color-dark;
    }
}
.figure-footer {
    position: relative;
    z-index: 5;
}
.poll-banner {
    position: relative;
    background-color: #ffffff;
    padding: 20px 26px;
    border-radius: 3px;
    .poll-button {
        position: relative;
        z-index: 5;
        max-width: 196px;
    }
    .poll__logo {
        position: absolute;
        top: 0;
        right: 26%;
        z-index: 3;
    }
    .separator {
        position: absolute;
        display: inline-block;
        top: -33px;
        left: -35px;
        width: 1px;
        height: 100px;
        background-color: #eff0f2;
    }
}

@media (max-width: 1070px) {
    .poll-banner {
        .separator {
            left: -20px;
        }
    }
}
@media (max-width: 999px) {
    .poll-banner {
        .poll-title {
            font-size: 16px;
        }
    }
}
