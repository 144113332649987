.zakupkiSearch .titleField {
    line-height: 28px;
    padding-top: 5px;
}
.titleField {
    display: inline-block;
    font-size: 23px;
    font-weight: 400;
    padding-right: 30px;
    vertical-align: middle;
}
.titleTop .accounting {
    margin-top: 5px;
    padding: 0;
}

.filterParametrs {
    margin-top: 28px;
}

.zakupkiSearch .accounting {
    margin-top: 15px;
    padding: 0 2px;
}

.searchField input[type="button"] {
    background: #fff url(../images/newDesign/main_zoom.png) no-repeat 2px 0;
    border: 0 none;
    height: 20px;
    vertical-align: middle;
    width: 22px;
}

.saveExtendedSearch label {
    display: inline-block;
    vertical-align: top;
    width: 240px;
}

.ktruRow .searchField .autocompleteOrder{
    width: 93% !important;
}

.clearButton {
    cursor: pointer;
    display: inline-block;
    height: 10px;
    margin-right: 10px;
    vertical-align: middle;
    width: 11px;
    margin-top: 16px;
    background: url(../images/newDesign/clear.png) 0px 0px no-repeat;
}
.customCheck {
    display: inline-block;
    height: 23px;
    vertical-align: middle;
    width: 20px;
    background: url(../images/newDesign/custom_check.png) 0px -100px no-repeat;
    margin: 0px 5px 0px 0px;
    padding: 0px;
}
.check {
    background-position: 0px 0px;
}
.mainPageStrictEqual {
    margin-left: 15px;
}
.customCheck input[type="checkbox"] {
    cursor: pointer;
    height: 20px;
    margin: 0 ;
    opacity: 0;
    vertical-align: top;
    width: 20px;
}

.extendedSearchLink {
    margin-top: 6px;
}

.filterBlock .greyBox {
    border-radius: 5px 5px 0px 0px;
}
.filterBlock {
    font-size: 12px;
}
.padLeft15{
    padding: 0 0 0 15px;
}

.height410pxWithScrollYAuto {
    max-height: 410px;
    overflow-y: auto;
}

/*
//Styles for dropDownYearRange
*/

.dropContainer {
    position: absolute;
    width: 160px;
    font-size: 12px;
    display: block;
    z-index: 100;
}

.inactiveTrigger {
    color: #777 !important;
    padding: 5px 5px 5px 10px;
    font-size: 12px;
    background: #FFFFFF url(../images/newDesign/sort_arrow.png) no-repeat 95% -120px;
    display: block;
    height: 28px;
    width: 160px;
    cursor: pointer;
    border: 2px solid #cecece;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.inactiveTrigger a:hover {
    color: black;
    text-decoration: none !important;
}

.inactiveTrigger:hover {
    background: #FFFFFF url(../images/newDesign/sort_arrow.png) no-repeat 95% -120px;
    z-index: 100;
}

.activeTrigger {
    color: #777;
    padding: 5px 5px 5px 10px;
    font-size: 12px;
    background: #FFFFFF url(../images/newDesign/sort_arrow.png) no-repeat 95% -185px;
    display: inline-block;
    border: 2px solid #cecece;
    width: 160px;
    height: 28px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.activeTrigger:hover {
    background: #f5f5f5 url(../images/newDesign/sort_arrow.png) no-repeat 95% -185px;
    color: #777;
}

.activeTrigger:active {
    background: #f5f5f5 url(../images/newDesign/sort_arrow.png) no-repeat 95% -185px;
    color: #777;
}

.dropContainer ul {
    font-size: 12px;
    border: 1px solid #ccc;
    border-top: none;
    background: #fff;
    list-style-type: none;
    margin: 0;
    z-index: 100;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.dropContainer ul li {
    color: black;
    padding: 5px 0px 5px 10px;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.dropContainer ul li a {
    color: black;
    text-decoration: none !important;
}

.dropContainer ul li:hover {
    background: #f5f5f5;
    outline: none;
}

.dropContainer ul li:first-child {
    display: none !important;
}

.dropContainer ul li:last-child {
    border-bottom: none;
}

.dropdownHidden {
    display: none;
    opacity: 0;
}

.activeTrigger:hover {
    text-decoration: none !important;
}

.inactiveTrigger:hover {
    text-decoration: none !important;
}

.dropdownVisible {
    height: auto;
    display: block;
    opacity: 1;
}

.minWidth180 {
    min-width: 160px;
}

.padding2px{
    padding: 2px;
}

.drop-list .disabled {
    pointer-events: none;
    opacity: 0.6;
}
