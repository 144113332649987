.content-support {
    padding-top: 43px;
    padding-bottom: 75px;
}
.file {
    border: 1px solid #E4E7F2;
    border-radius: 3px;
    text-align: center;
    padding: 26px 10px 55px 10px;
    .text {
        margin-top: 16px;
        margin-bottom: 7px;
        color: $grey-text;
        @extend .text-base-min;
        &_grey {
            color: #909EBB !important;
        }
    }
    &-link {
        text-decoration: none!important;
        label {
            margin-bottom: 0;
            color: $grey-main-light;
            @extend .text-base-micro;
            cursor: pointer;
        }
    }
}

.text-align-left
{
    text-align: left;
    padding: 26px 26px 55px 26px;
}

.support {
    ._h2 {
        color: $base-dark;
        @extend .heading-h2;
    }
    ._h3 {
        color: $base-dark;
        @extend .heading-h3;
    }
    ._h4 {
        color: $base-dark;
        @extend .heading-h4;
    }
    .label {
        color: $grey-main-light;
        @extend .text-base-min;
    }
    .border_bottom {
        border-bottom: 1px solid #E4E7F2;
    }
    .block-padding {
        padding: 60px 0;
        &_hotline {
            padding: 71px 0;
        }
        &_interactive {
            padding-top: 71px;
            padding-bottom: 85px;
        }
        &_tech {
            padding-top: 70px;
            padding-bottom: 30px;
        }
        &_small {
            padding-top: 27px;
            padding-bottom: 34px;
        }
    }
    &-subtext {
        margin-top: 60px;
        &__title {
            @extend ._h3;
            &__username {
                font-size: 16px !important;
            }
        }
        &__text {
            margin-top: 28px;
            margin-bottom: 70px;
            @extend ._h4;
        }
    }
    &-heading {
        margin-bottom: 80px;
    }
    &-href {
        font-size: 14px !important;
    }
    &-block {

        &__title {
            margin-bottom: 36px;
            @extend ._h2;
        }
        &__text {
            color: $base-dark;
            @extend .heading-h4;
            &_top {
                padding-bottom: 24px;
            }
        }
    }
    &-data {
        display: inline-block;
        position: relative;
        padding-top: 30px;
        .text {
            text-decoration: none!important;
            @extend ._h4;
            &_highlight {
                color:  $prime-color!important;
            }
            &_small {
                @extend .text-base-min;
            }
            .icon {
                vertical-align: sub;
                margin-left: 5px;
            }
        }
        label {
            position: absolute;
            top: 12px;
            left: 0;
        }
    }
    &-partner {
        &__text {
            margin-left: 23px;
        }
        &__img {
            img {
                border: 1px solid #E4E7F2;
                border-radius: 50%;
            }
        }
    }
    .block-side {
        height: 100%;
        &_cut {
            padding-right: 75px;
        }
        &_border {
            border-left: 1px solid #DDE0E5;
            padding-left: 24px;
        }
        &__line {
            &:first-child {
                margin-bottom: 16px;
            }
            &:nth-child(2) {
                margin-bottom: 5px;
            }
            &:last-child {
                margin-top: 12px;
            }
        }
        .phone {
            display: inline-block;
            &__link {
                text-decoration: none!important;
                @extend ._h2;
            }
        }
    }
}

@media (max-width: 1060px) {
    .support {
        .block-side {
            .phone__link {
                font-size: 22px;
            }
        }
    }
}
@media (max-width: 1160px) {
    .support {
        &-partner {
            &__text {
                .col-3 {
                    flex: 0 0 35% !important;
                    max-width: 30% !important;

                    position: relative;
                    width: 100%;
                    min-height: 1px;
                    padding-right: 15px;
                    padding-left: 15px;
                }
            }
        }
    }
}
