.btn {
    position: relative;
    display: inline-block;
    width: 100%;
    background: transparent;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    text-decoration: none;
    line-height: 35px;
    @extend .text-cap-base;
    border-radius: 4px;
    text-align: center;
    &:hover {
        text-decoration: none;
    }

    .btn-text {
        vertical-align: baseline;
        &_thin {
            font-weight: 300;
        }
    }
}

.btn-primary {
    background-color: $prime-color;
    color: #ffffff;
    &:hover {
        color: #ffffff;
    }
}

.btn-default {
    color: $prime-color;
    border: 1px solid $prime-color;
    &_white {
        width: 99px;
        color: #ffffff;
        border: 1px solid #ffffff;
        &:hover {
            color: #ffffff;
        }
    }
    &_grey {
        background: #EFF0F2;
        border: none;
        &:hover {
            background-color: $prime-color-dark;
            color:#ffffff;
        }
    }
}
.btn-default--no-border {
    color: $prime-color;
    outline: none !important;
    &:hover {
        color: $prime-color-dark;
    }
}


.btn-polls {
    width: 197px;
}


.btn-icon {
    position: relative;
    top: -3px;
    padding: 0;
    border: 0;
    background: transparent;
    cursor: pointer;
    line-height: 29px;
    &__text {
        @extend .text-base-min;
        color: $prime-color;
        &:visited {
            color: $prime-color;
        }
    }
    &__icon {
        margin-left: 5px
    }
}
