.gp-select {
    padding: 9px 12px;
    width: 100%;
    border: 1px solid #DAE4EE;
    border-radius: 3px;
    cursor: pointer;
    outline: none;
    &-group {
        position: relative;
        cursor: pointer;
        .gp-select-icon {
            background: img-url('icons/icon_arrow_region--gray.svg') no-repeat;
            width: 8px;
            height: 4px;
            right: 18px;
            @extend .align-middle-absolute;
            &.arrow-select-vars_open{
                background: img-url('icons/icon_arrow_region.svg') no-repeat !important;
                transform: scale(1, -1);
            }
        }
    }
    &-wrap, &-wrap-modal {
        width: 100%;
        .sub-text {
            @extend .text-base-min;
            color: $grey-main;
        }
    }
    &-vars {
        position: absolute;
        display: none;
        top: -1px;
        width: 100%;
        z-index: 990;
        border-bottom: 1px solid #DAE4EE;
        border-right: 1px solid #DAE4EE;
        border-left: 1px solid #DAE4EE;
        border-bottom-right-radius: 3px;
        &__item {
            padding: 7px 12px;
            background-color: #FFFFFF;
            cursor: pointer;
            &:hover {
                background: $base-hover-item-list;
                color: $prime-color-dark;
            }
            &--selected {
                color: $prime-color;
            }
        }
    }

    &-text {
        @extend .text-base-min;
    }
}
