input[type="text"]:not(.calendar-input__text),
input[type="password"]:not(.calendar-input__text),
input[type="number"]:not(.calendar-input__text) {
    @extend .text-base-min;
    border: 1px solid $grey-extra-light;
    box-sizing: border-box;
    box-shadow: inset 0px 2px 1px $grey-super-light;
    border-radius: 3px;
    padding: 12px 16px;
    &:disabled {
       color: $base-disabled;
    }
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    input[type="text"]:not(.calendar-input__text),
    input[type="password"]:not(.calendar-input__text),
    input[type="number"]:not(.calendar-input__text) {
        padding: 9px 38px 14px 15px;
    }
}
