.radio-search {
    position: absolute; // take it out of document flow
    opacity: 0; // hide it

    & + label {
        position: relative;
        cursor: pointer;
        // padding: 0;
        padding-left: 26px;
        @extend .text-base-min;
    }

    // Box.
    & + label:before {
        content: '';
        margin-right: 10px;
        display: block;
        vertical-align: text-top;
        width: 16px;
        height: 16px;
        background: #fff;
        border-radius: 50%;
        border: 1px solid $grey-main-extra-light;
        position: absolute;
        top: 2px;
        left: 0px;
    }

    // Box hover
    &:hover + label:before {
        // background: $prime-color;
    }

    // Box focus
    &:focus + label:before {
        // box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }

    // Box checked
    &:checked + label:before {
        background: $prime-color;
        border: none;
    }

    // Disabled state label.
    &:disabled + label {
        color: #b8b8b8;
        cursor: auto;
    }

    // Disabled box.
    &:disabled + label:before {
        box-shadow: none;
        background: #ddd;
    }

    // Checkmark. Could be replaced with an image
    &:checked + label:after {
        content: '';
        position: absolute;
        left: 4px;
        top: 6px;
        background: #fff;
        width: 8px;
        height: 8px;
        border-radius: 50%;
    }
}
