.modal{
    &-content{
        &-subjectControl{
            .text {
                &__title {
                    @extend .text-base-min, .pt-2;
                    color: $grey-main-light;
                }

                &__value {
                    @extend .heading-h5, .pb-2;
                    color: $base-dark;
                }
            }
        }
    }
}
