.table {
    &.c-mt{
        margin-top: rem-local(32);
    }

    @extend .text-base-mid;
    color: $base-dark;

    thead th, tbody th {
        @extend .text-cap-micro;
        color: $grey-main-light;
        vertical-align: baseline;
        border-bottom: $table-border-width solid $table-border-color;
    }
    th,
    td {
        padding: 1rem 0.5rem 1rem 1rem;
    }
    .grey-color{
        color: $grey-main-light;
    }
    .dark-color{
        color: $base-dark;
    }
    .size-12 {
        font-size: 12px !important;
    }
    .total{
        &__title{
            @extend .text-cap-micro;
            color: $grey-main-light;
        }
        &__value{
            @extend .heading-h3;
            color: $base-dark;
        }

    }
    &-chevron {
        background: url("../img/icons/chevron.svg") 50% no-repeat;
        cursor: pointer;
        display: inline-block;
        height: 24px;
        vertical-align: middle;
        width: 24px;
        min-height: 24px;
        min-width: 24px;
        &--down{
            transform: rotate(90deg);
        }
    }
    .hidden-tr > {
        td:first-child, th:first-child {
            border: none!important;
        }
    }
    &-layout-fixed{
        table-layout: fixed;
    }
}
