body div.msgBox{
    padding: 0 !important;
    width: 24rem;
    color: $base-dark;
    &Title{
        padding: 22px 23px 20px 25px !important;
        @extend .heading-h3;
        word-wrap: break-word;
        display: flex;
        text-transform: none;
        margin: 0;
        justify-content: space-between;
        align-items: baseline;
        text-align: left;
        .closePopUp{
            background: url(/epz/static/img/icons/icon_cross.svg) no-repeat !important;
            position: relative;
            right: inherit;
            width: 16px;
            height: 16px;
            top: inherit;
        }
    }
    &Container{
        padding: 22px 23px 20px 25px !important;
        @extend .text-base-min;
        border: 1px solid $border-color;
        border-radius: 0;
        background: none;
        .browserIco{
            background: url(/epz/static/img/icons/icon_message_rss.svg) no-repeat !important;
            padding: 0 0 0 65px;
            height: 50px;
        }
        .eMailIco{
            background: url(/epz/static/img/icons/icon_envelope.svg) no-repeat !important;
            padding: 0 0 0 65px;
            height: 70px;
        }
    }
    &Buttons{
        padding: 22px 23px 20px 25px !important;
        margin: 0;
        display: flex;
        justify-content: space-between;
        .msgButton{
           @extend .text-base-button;
            min-width: 160px;
            height: 40px;
            border-radius: 5px;
            border: 1px solid $prime-color;
        }
        input[type='button']{
            background: $prime-color !important;
            color: #fff;
        }
        input[type='button']:first-child{
            background: #fff !important;
            color: $prime-color;
        }
        input[type='button']:only-child{
            background: $prime-color !important;
            color: #fff;
            margin: 0 auto;
        }
    }
}
