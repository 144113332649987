.modal {
    &-ktru {
        .padding0 {
            padding: 0 !important;
        }

        .button-normal {
            line-height: normal !important;
            float: left;
            padding: 20px 0;
        }

        .button-normal-right {
            float: right;
            padding: 20px 0;
        }

        .modal-text-block {
            border-bottom: none !important;
        }

        #classifiersTable #groupsId, .rubricatorModal, #innerChooseTableKtru, #innerTableCharacteristicsKtru, #classifiersModalTable #groupsId {
            @extend .text-base-min;
        }

        width: 736px;
        top: 5% !important;
        max-height: 93vh;

        .okdpSearch {
            padding: 20px 0 0 0;

            input[type=text] {
                width: 82%;
                padding: 7px;
                border: 1px solid #DAE4EE;
                box-sizing: border-box;
                box-shadow: inset 0px 2px 1px #EFF0F2;
                border-radius: 3px;
            }
        }

        //Рубрикатор
        #innerTableRubricator {

            .msgError{
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
            }

            #rubricatorInput {
                &:not(.colorValue) {
                    & + .btn {
                        & + .resetBtn {
                            display: block !important;
                        }
                    }
                }

                & + .btn {
                    & + .resetBtn {
                        display: none;
                        right: 8em;
                        position: absolute;
                        top: 5.0em;
                        width: 10px;
                        height: 10px;
                        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                            top: 4.3em;
                        }

                        input {
                            width: 16px;
                            height: 16px;
                            border-radius: 0;
                            border: 0;
                            background: url(/epz/static/img/icons/icon_cross.svg) no-repeat;
                            color: transparent;
                        }
                    }
                }
            }


            .rubricatorModal {
                padding: 20px 0;

                #tree {
                    overflow: hidden;

                    .dynatree-container {
                        background-color: #fff !important;

                        > li > .dynatree-node {
                            padding-left: 0 !important;
                        }

                        li {
                            .dynatree-selected {
                                background-color: #EFF0F2;
                                color: #0065dd;
                            }

                            padding: 0;
                            cursor: pointer;
                            max-width: 98%;

                            .dynatree-node {
                                padding: 5px 0;
                                display: block;

                                .dynatree-expander + .dynatree-title {
                                    padding-left: 7px;
                                }

                                .dynatree-connector + .dynatree-title {
                                    padding-left: 18px
                                }
                            }
                        }
                    }
                }
            }
        }

        //Классификатор
        .classifire-block {
            .treetableCustom {
                #tree {
                    .mCSB_inside {
                        margin-right: 23px;
                    }
                }
            }
            .resetBtn {
                display: none;
            }
            #goodssearchKTRU {
                &:not(.colorValue) {
                    & + .btn {
                        & + .resetBtn {
                            display: block !important;
                        }
                    }
                }

                & + .btn {
                    & + .resetBtn {
                        display: none;
                        right: 15em;
                        position: absolute;
                        top: 18em;
                        width: 10px;
                        height: 10px;
                        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                            top: 17.7em;
                        }

                        input {
                            width: 10px;
                            height: 10px;
                            border-radius: 0;
                            border: 0;
                            background: url(/epz/static/img/icons/icon_cross.svg) no-repeat;
                            color: transparent;
                        }

                        &:not(.colorValue) {
                            display: none;
                        }
                    }
                }
            }

            input[type='radio'] {
                margin-top: 0px;
                position: relative;
                top: 1px;
            }

            input[type=radio]:not(:checked).customRadioButton + span.customRadioButtonSpan {
                background: url("/epz/static/img/icons/radio_uncheck.svg");
                width: 16px;
                height: 16px;
                display: inline-block;
                vertical-align: middle;
                cursor: pointer;
            }

            input[type=radio]:checked.customRadioButton + span.customRadioButtonSpan {
                background: url("/epz/static/img/icons/radio_check.svg");
                width: 16px;
                height: 16px;
                display: inline-block;
                vertical-align: middle;
                cursor: pointer;
            }

            input[type=radio] {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
            }

            padding: 0;

            .classifierStartMessage {
               @extend.text-base-min;
            }

            .gray-background{
                background-color: #eff0f1!important;
            }

            .ktruModalH2 {
                padding: 22px 23px 15px 25px;
            }

            #classifiersTable, #classifiersModalTable {
                .treetable {
                    padding: 0 0 20px 20px;
                }

                .okpdSearch.createDocBtn {
                    padding: 20px 20px 0;
                }

                .okdpSearch.noneTdBorder {
                    padding: 0;
                }

                .okdpSearch {
                    padding: 15px 20px 5px 20px;
                }

                .msgError {
                    padding: 10px 0px 25px 25px;
                }

                #groupsId {
                    //background-color: #eff0f1 !important;
                    width: 100%;

                    #classifierExp {
                        padding-left: 40px !important;
                        padding-bottom: 15px;
                        color: $prime-color;
                    }

                    .width20 {
                        width: 0 !important;
                    }

                    tr {
                        td:first-child {
                            padding-left: 35px !important;
                        }

                        td.width-25-pr.padBtm5 {
                            padding-bottom: 20px;
                            vertical-align: top;
                            max-width: 160px;
                        }

                        td {
                            tr {
                                td.width20 {
                                    vertical-align: top;
                                    padding-left: 0px !important;
                                }

                                td.width20 + td {
                                    padding-left: 0 !important;

                                    label {
                                        min-height: 40px;
                                        max-width: 160px;
                                    }
                                }
                            }
                        }
                    }
                }

                #goodssearchKTRU + .btn + .resetBtn {
                    top: 0;

                    input {
                        margin-top: 92px;
                    }
                }
            }
        }

        //УкрПозиции
        #innerChooseTableKtru {
            padding: 20px 0 20px 0;

            .resetBtn {
                display: none;
            }

            #chooseOrganizationDialogDataHeader, #chooseOrganizationDialogDataBody {
                width: 98%;

                td.width_30_pr div, tr th.width_30_pr {
                    padding-left: 20px;
                }

                tr th:last-child, tr td:last-child div {
                    padding-left: 85px;
                }

                td:last-child label {
                    max-width: 95%;
                }

                tr .width_30_pr {
                    width: 30%;
                }

                .choiceTableSelectedRow {
                    background-color: #EFF0F2;
                    color: #0065dd;
                }

                label {
                    line-height: 34px;
                    display: block;
                }
            }
        }

        //Характеристики
        .ktru-characteristic-block {
            padding: 0;

            #innerTableCharacteristicsKtru {
                .ktruModalH2 {
                    padding: 20px;
                    color: #334059;
                }

                #ktruCT {
                    color: #909ebb;

                    .col-4 {
                        padding-top: 13px;

                        label {
                            float: right !important;
                            width: 200px !important;
                        }
                    }

                    .col-8 {
                        br {
                            display: none;
                        }

                        input[type=text] {
                            width: 64px;
                            height: 40px;
                            border: 1px solid #dae4ee;
                            -webkit-box-shadow: inset 0 2px 1px #eff0f2;
                            box-shadow: inset 0 2px 1px #eff0f2;
                            border-radius: 3px;
                            -webkit-box-sizing: border-box;
                            box-sizing: border-box;
                            padding: 5px;
                        }

                        .KTRUinline-block {
                            padding-top: 4px;
                            padding-left: 10px;

                            color: $prime-color-dark !important;
                        }

                        .toggleGrey {
                            color: $prime-color-dark !important;
                        }

                        .expandArrow {
                            background: url(/epz/static/img/icons/icon_arrow_region.svg) no-repeat !important;
                            width: 10px;
                            height: 10px;
                            margin-left: 5px;
                            margin-top: 15px;
                        }

                        .ui-widget {
                            width: 40% !important;
                            margin-top: 20px !important;

                            .ui-state-focus, .ui-state-active {
                                border: 2px solid #0065dd !important;
                            }

                            .ui-state-focus + .ui-slider-range, .ui-state-active + .ui-slider-range {
                                background-color: $prime-color-dark !important;
                            }

                            .ui-state-focus ~ .ui-slider-range, .ui-state-active ~ .ui-slider-range {
                                background-color: $prime-color-dark !important;
                            }

                            .ui-state-default:not(.ui-state-focus) {
                                top: -0.2em;
                                border: 2px solid $grey-main !important;
                            }

                            .ui-slider-range + .ui-state-default:not(.ui-state-focus) {
                                background-color: $grey-main;
                            }
                        }

                        .toggleSlider:not(.toggleEnabled) {
                            background: #eff0f2 !important;
                            color: #0065dd !important;
                            display: inline-block;
                            text-align: center;
                            border-radius: 3px;
                            padding: 10px;
                            margin: 10px;
                        }

                        .toggleEnabled {
                            background: #0065dd !important;
                            color: #eff0f2 !important;
                            display: inline-block;
                            text-align: center;
                            border-radius: 3px;
                            padding: 10px;
                            margin: 10px;
                        }

                        .width450 {
                            min-width: 100px !important;
                            width: auto !important;

                        }

                        div.toggleGrey.quantityWithoutRange {
                            div.margLeft5 {
                                margin-top: 19px;
                            }
                        }
                    }
                }
            }
        }

        .mCSB_inside > .mCSB_container {
            margin: 0;
        }

        &-Choose {
            padding: 24px;
        }

        &-Clear {
            width: 16px;
            height: 16px;
            background: url(/epz/static/img/icons/icon_cross_small.svg) no-repeat;
            top: 2px;
        }

        .btn {
            input[type=button] {
                padding: 12px 30px;
                background: #0065dd;
                border-radius: 3px;
                border: 0;
                color: #fff;
                line-height: normal !important;
                width: auto;
            }
        }

        input[type=text] {
            height: 40px;
            //padding: 20px 0;
        }

        .ktruModalH2 {
            .dynatree-expander, .dynatree-expander-uncover {
                top: 9px !important;
                margin-left: 20px;
            }
        }

        .cArrowKtru {
            transform: rotate(0deg) !important;
        }

        .hidden {
            display: none;
        }

        .msgError {
            font-size: 13px;
            line-height: 20px;
            font-style: normal;
            font-weight: 400;
            padding: 5px 0 0 2px;
            color: red;
        }

        .preloader {
            background: rgba(255, 255, 255, 255);
            display: none;
            height: 100%;
            position: absolute;
            width: 100%;
            z-index: 9999;
            top: 0;

            .preloader-container {
                margin-left: 45%;
                margin-top: 20%;
                position: absolute;
                width: 15%;
                height: 15%;

                img {
                    margin: auto;
                    display: block;
                }

                div {
                    text-align: center;
                    padding-top: 15%;
                    color: $grey-main-light;
                    @extend .text-base-min;
                }
            }
        }
    }
}

