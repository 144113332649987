.rubricator-list{
    &__item{
        border: 1px solid $prime-color;
        border-radius: 3px;
        padding-bottom: 3rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        height: 100%;
        &:hover {
            cursor: pointer;
        }
        &-img{
            height: 64px;
            width: 64px;
            display: block;
            margin: 3rem auto 1rem;
        }
        &-title{
            @extend .text-cap-base;
            color: $base-dark;
            text-align: center;
            overflow: hidden;
            width: 100%;
        }
        &-value{
            @extend .text-base-micro;
            color: $grey-main-light;
            text-align: center;
        }
        &-href{
            @extend .text-base-min;
            color: $prime-color;
            font-weight: bold;
            text-align: center;
            overflow: hidden;
            width: 100%;
        }
        &-col{
            @extend .custom-fadein;
        }
    }

}
