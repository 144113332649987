@import "extended-search-param";
@import "quick-search-param";
@import "search-results";
@import "old";
@import "ui-autocomplete";


.listOrg{
    @extend .text-base-min;
    color: $grey-main;
    span:first-child, &__remove-item {
        @extend .iconClose;
    }
    .itemCode{
        color: $base-dark;
    }
}
.listKtru {
    @extend .text-base-min;
    color: $grey-main;
    .removeDrugEl,.deleteClassifiers {
        @extend .iconClose;
    }
    .removeCustomEl,.deleteClassifiers {
        @extend .iconClose;
    }
    &__title, .itemCode{
        color: $base-dark;
    }
}
.iconClose{
    background: url(/epz/static/img/icons/icon_cross_small_dark.svg) no-repeat 0px 0px !important;
    height: 10px !important;
    width: 10px !important;
    display: inline-flex;
    cursor: pointer;
    padding: 5px;
    margin-top: 6px;
    margin-left: 6px;
    float: right;
    vertical-align: middle;
}
.clear-all{
    @extend .text-base-min;
    cursor: pointer;
    background: url(/epz/static/img/icons/icon_cross_small.svg) no-repeat 0px 50% !important;
    color: $prime-color;
    min-width: 10px;
    min-height: 10px;
    padding-left: 15px;
}
