.display-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
}

.display-none {
    display: none;
}

.d-none-required{
    display: none !important;
}

.tab-pane {
    display: block;
}
.tab-pane_hide {
    display: none;
}
