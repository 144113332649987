.registerBox {
    border: 1px solid #dae4ee;
    border-radius: 5px;
    padding: 0;
    position: relative;
}

.registerBox .boxIcons {
    position: absolute;
    top: -10px;
    right: 10px;
}

.registerBox .boxIcons a {
    position: relative;
    margin-left: 3px;
    top: auto;
    right: auto;
}

.cryptoSignLink {
    background: url(../images/newDesign/cryptoSign-icon.png) no-repeat 0 0;
    display: inline-block;
    height: 40px;
    width: 41px;
    float: left;
}

.cryptoSignLinkImage {
    width:18px!important;
    height:18px !important;
    background: url('/epz/static/img/icons/icon_key.svg') !important;
}

.reportBox li:not(:last-child):after {
    content: '/';
}

.reportBox div:last-child:after {
    content: '';
}

.printLink {
    background: url(/epz/static/images/newDesign/print_ico.png) no-repeat 0 0;
    display: inline-block;
    float: left;
    height: 40px;
    width: 41px;
}

.controlLink {
    background: url("../images/shield_white.png") no-repeat 0 0;
    float: right;
    margin-left: 3px;
    height: 40px;
    width: 41px;
}

.formValidation > a {
    color: #018ccd;
    text-decoration: underline;
    font-weight: 400;
}

.formValidation > a, .formValidation > a:focus, img, button {
    border: none;
    outline: none;
    font-weight: 400;
}

.reportBox {
    background-color: #eff0f1;
    border-top: 1px solid #dae4ee;
    padding: 1px 20px;
    font-size: 12px;
}

.width380 {
    width: 380px;
}

.floatRight {
    float: right;
}
.alignRight {
    text-align: right;
}

.reportBox li {
    color: #909EBB;
    display: inline;
    padding: 0 3px 0 0;
}

.reportBox li a {
    padding-right: 5px;
}

.registerBox > table {
    margin: 15px 15px;
    width: 100%;
    font-size: 12px;
}

#exceedSphinxPageSizeDiv > table {
    border-collapse: collapse;
    border-spacing: 0;
    color: #5b5b5b;
    padding: 0;
    width: 100%;
}

.registerBox td.tenderTd {
    width: 315px !important;
}

.registerBox td {
    color: #41484e;
    padding-left: 0px;
    vertical-align: top;
}

td.tenderTd dl dt {
    width: 230px;
}

.tenderTd dt {
    font-size: 12px;
    padding-bottom: 7px;
}
.orange {
    color: #E8523D !important;
}

.orangeSmall {
    color: #E8523D !important;
    font-size: 11px !important;
}

.tenderTd dd {
    padding-bottom: 2px;
    margin-bottom: 0px;
}

.tenderTd dd strong {
    color: #334059;
    font-size: 22.5px;
    font-weight: normal;
}

.tenderTd span.currency {
    color: #909EBB;
    font-size: 12px;
    position: relative;
    top: -5px;
}

.tenderTd dd:last-child {
    padding-left: 20px;
}

.registerBoxBank .tenderTd dd:last-child {
    padding-left: 0;
}

.registerBoxBank .descriptTenderTd dl {
    margin-bottom: 15px;
}

.statusOk {
    background: url(../images/newDesign/status-icons.png) no-repeat 0 -39px;
}

.checkOk {
    background: url("../images/newDesign/checkOk.png") no-repeat;
    padding: 0 20px 0 20px;
}

.warning {
    background: url("../images/newDesign/warning_ico.png") no-repeat;
    padding: 0 20px 0 20px;
}

.statusWarn {
    background: url(../images/newDesign/status-icons.png) no-repeat 0 -120px;
}

.errorMessage {
    background: url(../images/newDesign/warning_ico.png) no-repeat 8px 6px;
}
.statusTerm {
    background: url("../images/newDesign/status-icons.png") no-repeat 0 0px;
}


.statusPause {
    background: url("../images/newDesign/warning_ico.png") no-repeat 0 0;
}

.statusCanc {
    background: url("../images/status-icons.png") no-repeat 0 -79px;
}

.statusAct {
    background: url("../images/status-icons.png") no-repeat 0 -333px;
}

.stopIco {
    background: url("../images/newDesign/stop_ico.png") no-repeat 8px 6px;
}

.stopIco010 {
    background: url("../images/newDesign/stop_ico.png") no-repeat 0px 10px;
}

.descriptTenderTd dl {
    margin-top: -3px;
    margin-bottom: 0;
}

.descriptTenderTd dt {
    color: #41484e;
    font-size: 12px;
    font-weight: bold;
    padding-bottom: 6px;
}

.descriptTenderTd dt a:first-child {
    display: inline;
    height: 20px;
}
.descriptTenderTd dt a {
    /*color: #41484e;*/
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
}

.tenderTd dd:last-child strong span, .tenderTd dd strong span.fractionNumber {
    color: #334059;
    font-size: 14.5px;
    position: static;
}

.registerBox td td:first-child {
    width: 50%;
}

.registerBox td td {
    padding-left: 0;
    padding-top: 10px;
    padding-right: 10px;
}

.registerBox td.amountTenderTd {
    width: 200px;
}
.registerBox td.cardDateTd {
    width: 200px;
}

.amountTenderTd ul {
    padding-top: 20px;
}

.amountTenderTd ul li {
    padding-bottom: 1px;
}

.amountTenderTd ul label {
    color: #909EBB;
    display: inline-block;
    width: 95px;
}
.cardDateTd ul {
    padding-top: 20px;
}

.cardDateTd ul li {
    padding-bottom: 1px;
}
.cardDateTd ul label {
    color: #909EBB;
    display: inline-block;
    width: 110px;
}

.tenderTd dt {
    font-weight: normal;
}

td.descriptTenderTd a {
    font-weight: normal;
}

.greyText {
    color: #909EBB !important;
}

.grayText {
    color: #909EBB;
}

.colorRed {
    color: red;
}

.colorGreen {
    color: #99cc66;
}

.lotsInfo > div {
    display: none;
}

.tenderTd .fzNews, .tenderTd .timeNews, .tenderTd .blockIco, .tenderTd .checked {
    color:  #909EBB;
    padding-right: 3px;
    padding-bottom: 1px;
}

.noWrap {
    white-space: nowrap !important;
    position: relative;
}
.fzNews {
    background: url(../images/newDesign/sprite_vert.png) no-repeat 0 -132px;
    padding-left: 20px;
}

.registerBox .descriptTenderTd .cooperative {
    margin-left: 10px;
    position: static;
    vertical-align: bottom;
}

.registerBox .cooperative {
    background: url(../images/newDesign/public_s.png) no-repeat 0 0;
    float: none;
    display: inline-block;
    height: 25px;
    position: absolute;
    right: 32px;
    top: 4px;
    width: 26px;
}
.cooperative {
    background: url(../images/newDesign/public.png) no-repeat 0 0;
    float: left;
    margin-left: -9px;
    margin-right: 9px;
    height: 35px;
    width: 36px;
}

.lotsInfo p.expandP {
    background: url(../images/newDesign/collapse_arrow_alt.png) no-repeat 2px 4px;
    color: #d0562b;
}

.lotsInfo p {
    background: url(../images/newDesign/expand_arrow.png) no-repeat 2px 5px;
    cursor: pointer;
    font-weight: bold;
    padding: 0 30px 5px 22px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: normal;
}

.lotsInfo table td:first-child {
    padding-right: 10px;
    width: 150px;
}

.lotsInfo td {
    border-top: 1px solid #e1e1e1;
    padding-bottom: 10px;
    padding-top: 15px;
}

.lotsInfo .greyText, .lotsInfo .greyText:last-child {
    color: #909EBB;
}

.lotsInfo table td:last-child {
    padding-right: 10px;
    width: auto;
}

.lotsInfo .descriptTenderTd dt {
    font-size: 12px;
    font-weight: normal;
    padding-bottom: 6px;
}

.descriptTenderTd dd:last-child {
    color: #41484e;
}

.timeNews {
    background: url(../images/newDesign/sprite_vert.png) no-repeat 0 -64px;
    padding: 0 20px;
}

.descriptTenderTd dt {
    font-size: 12px;
}

.lotsInfo table {
    width: 100%;
}

.descriptTenderTd .nameOrganization a, .descriptTenderTd .nameOrganization:last-child {
    color: #909EBB;
    text-decoration: none;
    text-transform: uppercase;
}

.displayNoneUsual {
    display: none !important;
}

.status-icon {
    background: url(../images/newDesign/status-icons.png) no-repeat;
    display: inline-block;
    width: 18px;
    height: 18px;
    position: absolute;
    top: 0;
    left: 0;
}

.status-deployed {
    background-position: 0 -38px;
}

.status-prescription {
    background-position: 0 -118px;
}

.controlPassedLink {
    background: url(../images/newDesign/control_passed.png) no-repeat 0 0;
    display: inline-block;
    height: 40px;
    position: absolute;
    right: -10px;
    top: -10px;
    width: 41px;
    float: right;
}

.status-field {
    position: relative;
    padding-left: 20px !important;
}

.status-invalid {
    background-position: 0 -78px;
}

.baseColor {
    color: #41484e !important;
}

.status-appointed {
    background-position: 0 -159px;
}

.status-project {
    background-position: 0 -334px;
}

.Box {
    border-radius: 5px;
    padding: 13px 15px;
}

.tenderTd .Box {
    border-radius: 3px;
    padding: 0 !important;
}

.oldAttachments, .oldAttachments td, .oldAttachments span, .oldAttachments a {
    color: #999999 !important;
    vertical-align: middle;
}

.alignFromIco {
    vertical-align: top;
    margin-top: 8px;
}

.tenderTd .Box span {
    /* background-color: white; */
    border-radius: 3px;
    color: #41484e;
    display: inline-block;
    padding: 5px 10px 5px 32px;
}

.tenderTd dd.padLeft0:last-child span {
    top: 0;
}

.status-complete {
    background-position: 0 -38px;
}

.blocked span {
    background: #eff0f1 url(../images/newDesign/warning_ico.png) no-repeat 8px 6px;
    border-radius: 3px;
    display: inline-block;
    padding: 5px 10px 5px 32px;
    width: 140px;
}

.tenderTd .whiteBox span {
    background-color: transparent;
    border-radius: 3px;
    color: #41484e;
    display: inline-block;
    padding: 5px 10px 5px 32px;
}

.whiteBox {
    background-color: #ffffff;
    border-radius: 5px;
    padding: 13px 15px;
}

.tenderTd .whiteBox {
    border-radius: 3px;
    padding: 0 !important;
    margin-left: -9px;
    margin-top: -8px;
}

.statusIcons {
    background-image: url(../images/newDesign/status-icons.png);
}

.statusIcons {
    background-repeat: no-repeat;
}

.statusIcons.status-denied {
    background-position: 8px 6px;
}

.statusIcons.status-prescription {
    background-position: 8px -113px;
}

.statusIcons.status-deployed {
    background-position: 8px -32px;
}

.statusIcons.greyWarning {
    background-position: 8px -526px;
}

.statusIcons.greenWarning {
    background-position: 8px -420px;
}

.displayTable {
    display: table;
}

.verticalMiddle {
    vertical-align: middle !important;
}

.displayTableCell {
    display: table-cell;
}

.descriptTenderTd label.greyText {
    width: 400px;
}

.status-document {
    background-position: 0 -264px;
}

.descriptTenderTdDt{
    font-size: 20px !important;
    color: #0065dd !important;
    font-weight: 400 !important;
}

.mainBox.margBtm50 {
    margin: auto;
    width: 1150px;
}

.inline-block.marginTop8px.paddingLeft180px.width-180-px-select {
    padding-left: 250px;
}

div#block1chart1 {
    padding-top: 36px;
}

.inline-block.marginTop8px.paddingLeft100px.width-180-px-select {
    margin-left: 160px;
}

.inline-block.paddingLeft100px.width-180-px-select {
    margin-left: 160px;
}

.bannersMainBlock {
    padding: 20px 0;
}

.bannersMainBlock svg {
    position: absolute;
    //width: 1150px;
    height: 77px;
}

.bannersMainBlock div.currentTab {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background: none;
    //width: 268px;
    color: #018ccd;
    padding: 7px 0 7px 18px;
    border-bottom: none;
    //height: 47px;
    //position: relative;
    border: 1px solid #018cdd;
}

.childBanner {
    background-color: #018ccd;
    border-radius: 4px;
    cursor: pointer;
    padding: 8px 0 8px 20px;
    //display: inline-table;
    //margin: 5px 5px;
    //padding: 8px 20px;
    color: #FFF;
    width: 267px;
}

.childBanner img {
    height: 44px;
}

.oldAttachments, .oldAttachments td, .oldAttachments span, .oldAttachments a {
    color: #999999 !important;
    vertical-align: middle;
}
.oldAttachments, .oldAttachments td, .oldAttachments span, .oldAttachments a {
    color: #999999 !important;
    vertical-align: middle;
}

.childBanner span {
    display: inline-block;
    margin-left: 20px;
    vertical-align: middle;
}

.greenCheckIco {
    background: url(../images/newDesign/antivirus-check.png) no-repeat 8px 6px;
}

//Для подписи
.edsContainer {
    width: 850px;
}

.signsListPopup .popUpWrapper {
    padding: 40px 40px 20px;
}

.signsListPopup .padTop30 {
    padding-top: 30px !important;
}
.signsListPopup .padBtm40 {
    padding-bottom: 40px !important;
}

.edsContainer h1 {
    font-size: 30px;
    text-transform: uppercase;
}

.edsContainer .margBtm30 {
    margin-bottom: 30px !important;
}

.edsContainer .font23 {
    font-size: 23px;
}

.edsContainer .modal h1 {
    text-align: center;
    margin-bottom: 35px;
}

.edsContainer .modal h1 {
    font-size: 23px;
    padding: 0 20px;
}

.edsContainer .greyBox {
    background-color: #eff0f1;
    border-radius: 5px;
    padding: 13px 15px;
}

.edsContainer .elSignName {
    border-bottom: 1px solid #d3d7db;
}

.edsContainer .brdRadiusBtm0 {
    border-radius: 5px 5px 0 0;
}
.edsContainer .edsContent {
    font-size: 14px;
    padding-top: 10px;
}

.edsContainer .elSignName .ok, .greenCheck {
    background: url(../images/newDesign/sign_ok_ico.png) no-repeat 0 0;
    font-weight: bold;
    padding: 4px 0 4px 47px;
    position: relative;
    text-transform: uppercase;
}

.edsContainer .elSignName .bad {
    background: url(../images/newDesign/sign_bad_ico.png) no-repeat 0 0;
    font-weight: bold;
    padding: 4px 0 4px 47px;
    position: relative;
    text-transform: uppercase;
    min-height: 24px;
}

.edsContainer .elSignName h3 {
    font-weight: bold;
    text-transform: uppercase;
}

.edsContainer h3 {
    color: #5b5b5b;
    font-size: 18px;
    font-weight: normal;
}

.edsContainer h3 {
    color: #5b5b5b;
    font-size: 20px;
    font-weight: normal;
}

.elSignDescr {
    padding-left: 67px;
}

.edsContainer .brdRadiusTop0 {
    border-radius: 0 0 5px 5px;
}

.edsContainer table {
    border-collapse: collapse;
    border-spacing: 0;
    color: #5b5b5b;
    padding: 0;
    width: 100%;
}

.elSignDescr td:first-child {
    width: 125px;
}
.elSignDescr td {
    padding-bottom: 5px;
    vertical-align: top;
}

.edsContainer .greyText {
    color: #909EBB !important;
}

.addingTbl {
    padding: 5px 17px 17px 17px;
    table-layout: fixed;
}

.elSignBlock {
    margin: 10px 0;
    padding: 0 !important;
}

.addingTbl table {
    table-layout: fixed;
}

.noticeTabBox .addingTbl td, .addingTbl td {
    background-color: #eff0f1;
    border-top: none;
    color: #5f6a74;
    word-wrap: break-word;
}

.noticeTabBox .addingTbl td:first-child, .addingTbl td:first-child {
    border-radius: 0;
}

.addingTbl.elSignBlock.expandTbl td {
    padding: 9px 10px;
}

.addingTbl th:first-child, .addingTbl tr:first-child td:first-child {
    border-radius: 0;
}

.addingTbl.elSignBlock .rowExpand td:first-child {
    background-color: #dbe1e6;
    padding: 9px 10px;
    text-align: center;
    vertical-align: middle;
    width: 40px;
}

.expandTr {
    background: url(../images/newDesign/expand_arrow.png) no-repeat 0 0px;
    cursor: pointer;
    display: inline-block;
    height: 8px;
    left: 2px;
    position: absolute;
    top: 2px;
    width: 12px;
}

.expandTr-right {
    @extend .expandTr;
}

.rl-90 {
    transform: rotate(-90deg);
}

.expandRow {
    border: 1px solid #d3d7db;
    border-top: 0;
    border-radius: 0 0 4px 4px;
    display: none;
    margin: -4px 0 0 0;
    padding: 20px 20px 0 20px;
}

.toggleTr.expandRow {
    border: none 0;
}

.noticeTabBox .addingTbl td:last-child, .addingTbl td:last-child {
    border-radius: 0 !important;
}

.addingTbl th:last-child, .addingTbl td:last-child {
    border-radius: 0;
}

.addingTbl.elSignBlock.expandTbl td {
    padding: 9px 10px;
}

.addingTbl.elSignBlock.expandTbl .expandRow td {
    border-top: 2px solid #fff;
}

.elSignBox {
    margin: 5px 10px 10px;
    max-height: 170px;
    overflow-y: auto;
    width: auto;
    word-wrap: break-word;
}

.expandTr.collapseTr {
    background: url(../images/newDesign/collapce_arrow.png) no-repeat 0 0px;
}

.expandTr-right.collapseTr {
    background: url(../images/newDesign/expand_arrow.png) no-repeat 0 0px;
}

.rowExpand .expandTr {
    position: static;
}

.checked {
    background: url(../images/newDesign/check_ico.png) no-repeat 0 0;
    padding: 2px 20px;
}

.descriptTenderTd {
    padding-top: 0px;
    word-break: break-word;
    padding-right: 10px;
}

.paginator label.customCheckbox.floatLeft {
    margin-left: 17px;
    margin-top: 10px;
}

.reportBox ul:first-child {
    margin: 0;
    padding-bottom: 10px;
    padding-top: 10px;
}

.registerBox .greyText {
    color: #41484E ;
}
.registerBox .baseColor {
    color: #909EBB;
}
.registerBox a.uppercaseNone.grayText:hover {
    color: #0056b3;
}
.registerBox a.uppercaseNone.grayText {
    color: #0065dd;
}
.registerBox .registerBox td {
    color: #909EBB;
}

.registerBox .amountTenderTd {
    color: #41484E;
}
.dischargeLink {
    background: url("../images/newDesign/discharge-icon.png") no-repeat 0px 0px;
    display: inline-block;
    height: 40px;
    width: 41px;
}
.block-icon-organization {
    background: url("../images/newDesign/block-ico-grey.png") no-repeat;
    display: inline-block;
    width: 18px;
    height: 18px;
    position: absolute;
    top: 0;
    left: 0;
}
#block1chart1 > div#legend {
    font-size: 12px !important;
    top: 310px !important;
}

.padLeft20 {
    padding-left: 20px !important;
}
.worktypeContainer{
    width: 850px;
}
.worktypeContainer h1{
    margin-bottom: 0;
    padding: 0 0 0 50px !important;
}

#JB2149 {
    #conditionOfAdmissionAndRestriction{
        display: inline;
    }
    label[for='conditionOfAdmissionAndRestriction'] {
        display: inline;
    }
    .verticalAlignTextTop td {
        position: relative;
        .fontBoldTextTd {
            position: absolute;
            top: 17px;
        }
    }
}

#typeOfRequirementCheck {
    label[for="embargo"], label[for="restriction"], label[for="conditionOfAdmission"] {
        margin-right: 15px;
    }
}

#subjectDrugTag label.defaultLineHeight{
    width: 239px;
}

.registerSmallBox .noWrap{
    display: inline-block;
    margin-right: 30px;
}

.multiIkz span.multiIkz-arrow.collapse{
    background: url(../images/newDesign/collapse_arrow_alt.png) no-repeat 5px 3px;
    display: inline;
}

.multiIkz span.multiIkz-arrow {
    background: url("../images/newDesign/expand_arrow.png") no-repeat 5px 3px;
    cursor: pointer;
    font-weight: bold;
    padding: 0 0 15px 22px;
    text-transform: uppercase;
}

.multiIkz > div {
    display: none;
}

#placementRangeTag,#executionRangeTag{
    select{
        width: 95px;
    }
}

.customerRemoveDate{
    width: 200px;
    color: #41484e!important;
    float: right;
}

.worktypeContainer{
    width: 850px;
}
.worktypeContainer h1{
    margin-bottom: 0;
    padding: 0 0 0 50px !important;
}

#ktruChar .expandArrow{
    float: none !important;
    display: inline-block !important;
    margin-left: 5px;
}

.editLineBtn, .editLineField {
    display: none;
}
.editLineBtn{
    .red{
        vertical-align: middle;
        margin-top: 5px;
        color: red;
        display: block;
    }
}

.editIconPen {
    background: url("../images/newDesign/edit_ico.png") no-repeat 0 -31px;
    cursor: pointer;
    width: 16px;
    height: 16px;
    display: inline-block;
    top: -1px;
    margin-left: 6px;
}

.acceptIcon {
    background: url(../images/newDesign/checkOk.png);
    background-size: 16px 16px;
    cursor: pointer;
    width: 16px;
    height: 16px;
    display: inline-block;
    margin-left: 6px;
    vertical-align: middle;
}

.cancelIcon {
    background: url(../images/newDesign/stop_ico.png);
    background-size: 16px 16px;
    cursor: pointer;
    width: 16px;
    height: 16px;
    display: inline-block;
    margin-left: 6px;
    vertical-align: middle;
}

#selectedSettingValue{
    line-height: 10px;
    background-position-y: 10px;
}
.greyBg {
    background-color: #eff0f1 !important;
}
