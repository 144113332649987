.modal {
    .errorIcon {
        width: 24px;
        height: 24px;
        display: inline-block;
        background: url(/epz/static/img/icons/status/icon-alert.svg) no-repeat;

        &--small{
            width: 18px;
            height: 18px;
        }
    }

    .msgErr {
        color: $base-error;
        margin: 20px 0 0 0;
    }
    .btn {
        display: inline;
        max-width: 144px;
        min-width: 144px;
        &-close, input{
            cursor: pointer;
        }
    }
    .btn input {
        width: 144px;
        height: 40px;
        border-radius: 5px;
        border: 1px solid #0065dd;
    }
    .btn input[type=reset] {
        background-color: #fff;
        color: $prime-color;
    }
    .btn input[type=button] {
        background-color: $prime-color;
        color: #fff;
    }
    .grayText {
        color: $grey-main-light;
    }
    .blueText {
        color: $prime-color;
    }
    .clear__btn {
        position: absolute;
        top: 7px;
        right: 140px;
        background: transparent;
        border: 0;
        cursor: pointer;
    }
    .ui-helper-hidden-accessible {
        display: none;
    }
    &-content {
        &-oktmo {
            width: 736px;
        }
    }
    &-text {
        &-block {
            .search, .other-search {
                input[type=text] {
                    position: relative;
                    width: 81%;
                    height: 40px;
                    border: 1px solid #DAE4EE;
                    box-sizing: border-box;
                    box-shadow: inset 0px 2px 1px #EFF0F2;
                    border-radius: 3px;
                    padding: 7px 40px 7px 7px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }
                &-input-modal{
                    &:not(.colorValue){
                        &+ span.resetBtn{
                            background: url(/epz/static/img/icons/icon_cross.svg) no-repeat;
                            position: absolute;
                            margin-left: -32px;
                            margin-top: 13px;
                            height: 16px;
                            width: 16px;
                        }
                    }

                }
            }

            .Btn input[type=button] {
                padding: 12px 30px;
                background: $prime-color;
                border-radius: 3px;
                border: 0;
                color: #FFFFFF;
                cursor: pointer;
            }
            .Btn {
                margin-left: 13px;
            }
            //border-bottom: 1px solid #eff0f2;
            //padding: 23px 22px;
            .disabled {
                background: url(/epz/static/img/icons/icon_checkbox_disabled.svg) no-repeat;
            }
            .checked {
                background: url(/epz/static/img/icons/icon_checkbox_modal_checked.svg) no-repeat;
            }
            #goodssearch, #ktruInputDialog {
                &:not(.colorValue) + span.resetBtn {
                    background: url(/epz/static/img/icons/icon_cross.svg) no-repeat;
                    position: absolute;
                    margin-left: -32px;
                    margin-top: 13px;
                    height: 16px;
                    width: 16px;
                }
            }
            &__title{
                @extend .heading-h4;
                margin: 1rem 0;
            }
            .paginator{
                margin: 0;
                padding-left: 0;
                padding-right: 0;
                .paginator-button{
                    margin: 0;
                }
            }
        }
    }
    .modal-list-body{
        max-height: 320px;
    }
}

.okpdChoose {
    padding: 24px;
}



